import React from 'react';
import { Modal } from 'antd';
import PageHeader from '../headers/PageHeader';
import TablaEventosPrincipal from '../modales/eventos/TablaEventosPrincipal';

export default function ModalMarkersEvents({ open, setOpen, item }) {
  return (
    <Modal
      title=""
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={'85%'}
      footer={false}
    >
      <PageHeader title="Registro de Eventos" head="Eventos"></PageHeader>

      <TablaEventosPrincipal eventTable={item} />
    </Modal>
  );
}
