import React, { useMemo, useState } from 'react';
import { Tabs, Space } from 'antd';

import TabPrincipal from './TabPrincipal';
import TabMapa from './TabMapa';
import Tabprotocolos from './Tabprotocolos';
import image from '../../../assets/images/Logo_(Metro_Medellín).png';
import NameDropdown from '../../../components/layouts/components/NameDropdown';
import ModalEventosClendario from './ModalEventosClendario';

const items = [
  {
    key: '1',
    label: 'Principal',
    children: <TabPrincipal />,
  },
  {
    key: '2',
    label: 'Mapa',
    children: <TabMapa />,
  },
  // {
  //   key: '3',
  //   label: 'Monitores',
  //   children: <Tabprotocolos />,
  // },
];

const OperationsSlot = {
  left: (
    <Space
      style={{ marginRight: '50px' }}
      align="center"
      className="select-none antd-hidden-xs" // Añade la clase antd-hidden-xs
    >
      <img src={image} alt="logo" className="w-8 pb-1" />
      <span className="textAjust">Plataforma de Gestión CISE</span>
    </Space>
  ),
  right: (
    <Space size="large">
      <ModalEventosClendario />
      <NameDropdown />
    </Space>
  ),
};

export default function PrincipalGrafana({ datosSeleccionados }) {
  const [position] = useState(['left', 'right']);
  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
  }, [position]);

  // El arreglo vacío asegura que el efecto se ejecute solo una vez al montar el componente

  const onChange = (key) => {
    // console.log(key);
  };
  return (
    <Tabs
      style={{
        background: 'white',
        padding: '10px',
      }}
      defaultActiveKey={datosSeleccionados}
      tabBarExtraContent={slot}
      items={items}
      onChange={onChange}
    />
  );
}
