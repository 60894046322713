export default function Header() {
  return (
    <div
      id="header"
      className="max-w-md text-center"
      style={{ marginTop: '10px' }}
    >
      <h1 className="text-base">
        ¡Bienvenido a la Plataforma de Gestión CISE!
      </h1>
    </div>
  );
}
