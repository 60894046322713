import React, { useState, useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';
import userService from '../../../services/UserService';
import {
  Form,
  Input,
  Select,
  Button,
  Modal,
  Tooltip,
  message,
  Spin,
} from 'antd';
import useAuth from '../../../hooks/useAuth';

const { Option } = Select;

export default function ModalEditarRole({ item, refresh }) {
  const { session } = useAuth();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [roles] = useState([
    { label: 'Inspector', value: 'CISEInspector' },
    { label: 'Analista', value: 'CISEAnalyts' },
    { label: 'Administrador', value: 'CISEAdministrador' },
    { label: 'Coordinador', value: 'Coordinator' },
  ]);

  const handleCancel = () => {
    setShow(false);
  };

  const consultaUsuario = async () => {
    try {
      const response = await userService.consultarUsuarioEspecifico(item.email);
      let data = response;

      setUserInfo(data?.response?.Items[0] || {});
      form.setFieldsValue({
        email: data?.response?.Items[0].email,
        roles: data?.response?.Items[0].roles,
        name: data?.response?.Items[0].name,
        lastName: data?.response?.Items[0].lastName,
        phone: data?.response?.Items[0].phone,
      });
      setShow(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const editarUsuario = async (registro) => {
    setLoading(true);
    try {
      await userService.EditarUsusairo(item.id, registro);

      refresh();
      message.success('Usuario Editado');
      setShow(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logró editar el usuario');
    }
    setLoading(false);
  };

  const onFinish = (values) => {
    let registro = {
      alias: `${values.lastName}-${values.name}`,
      document: userInfo.document,
      email: values.email,
      lastName: values.lastName,
      name: values.name,
      outsideId: userInfo.outsideId,
      phone: values.phone.startsWith('+57')
        ? values.phone
        : `+57${values.phone}`,
      preferences: userInfo.preferences,
      roles: values.roles,
    };

    editarUsuario(registro);
  };

  const validateName = (rule, value) => {
    const regex = /^[a-zA-Z\s]+$/;
    if (value && !regex.test(value)) {
      return Promise.reject('Ingrese solo letras sin números');
    }
    return Promise.resolve();
  };

  // Función de validación para teléfono
  const validatePhone = (rule, value) => {
    const regex = /^[+\d]+$/;
    if (value && !regex.test(value)) {
      return Promise.reject(
        'Ingrese solo números y el caracter + en el teléfono'
      );
    }
    return Promise.resolve();
  };

  const [form] = Form.useForm(); // Nuevo estado para el formulario

  const openModal = () => {
    // Limpiar el formulario antes de la consulta
    setUserInfo(null);
    form.resetFields();
    consultaUsuario();
  };

  useEffect(() => {
    // Establecer los valores iniciales del formulario
    // setLoadingForm(true);
    // if (show) {
    //   form.setFieldsValue({
    //     email: userInfo.email,
    //     roles: userInfo.roles,
    //     name: userInfo.name,
    //     lastName: userInfo.lastName,
    //     phone: userInfo.phone,
    //   });
    //   setLoadingForm(false);
    // }
  }, [show, form, userInfo]);
  return (
    <>
      <Tooltip title="Editar usuario">
        <Button type="text" onClick={() => openModal()}>
          <EditOutlined />
        </Button>
      </Tooltip>
      <Modal
        visible={show}
        onCancel={handleCancel}
        onOk={handleCancel}
        footer={false}
        title="Editar Usuario"
      >
        {userInfo ? (
          <Form
            name="your_form"
            layout="vertical"
            initialValues={userInfo}
            onFinish={onFinish}
          >
            <Form.Item
              label="Correo"
              name="email"
              rules={[
                { required: true, message: 'Por favor, ingrese su correo.' },
              ]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Roles"
              name="roles"
              rules={[{ required: true, message: 'Seleccione un Rol' }]}
            >
              <Select disabled={!session.roles.users.isAllowedtoUpdate}>
                {roles.map((role) => (
                  <Option key={role.value} value={role.value}>
                    {role.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Nombre"
              name="name"
              rules={[
                { required: true, message: 'Por favor ingrese su apellido' },
                { validator: validateName },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Apellidos"
              name="lastName"
              rules={[
                { required: true, message: 'Por favor ingrese su apellido' },
                { validator: validateName },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Número"
              name="phone"
              rules={[
                { required: true, message: 'Por favor ingrese su teléfono' },
                { validator: validatePhone },
              ]}
            >
              <Input />
            </Form.Item>

            <Button type="primary" htmlType="submit" block loading={loading}>
              Guardar Cambios
            </Button>
          </Form>
        ) : (
          <Spin />
        )}
      </Modal>
    </>
  );
}
