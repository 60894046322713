import axios from 'axios';

const api = 'https://lo7qws37f6.execute-api.us-east-1.amazonaws.com/prod';

const EnviarNotificacion = async (registro) => {
  const path = `/notifications`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.post(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    return response.data; // Devolver los datos obtenidos from Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const EnviarNotificacionWP = async (registro) => {
  const path = `/notifications/whatsapp`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.post(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    return response.data; // Devolver los datos obtenidos from Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};
const notificacionesService = {
  EnviarNotificacionWP,
  EnviarNotificacion,
};

export default notificacionesService;
