import React, { useEffect, useState } from 'react';
import { Modal, Collapse, message, Spin } from 'antd';
import activitiesService from '../../../../services/activitiesService';
import SeguimientodeActividades from '../../../Listas/SeguimientodeActividades';
// import CostModal from '../../components/CostModal';

const { Panel } = Collapse;

export default function ModalActividadesEventos({ open, setOpen, item }) {
  const [activities, setActivities] = useState(null);

  const consultaEventos = async () => {
    try {
      const response = await activitiesService.consultarActividadEspecifica(
        item.id
      );

      ajusteArray(response.Items);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logro hacer la consulta');
    }
  };

  const ajusteArray = (array) => {
    if (array.length !== 0) {
      const resultado = filtrarObjetos(item.protocols, array);

      setActivities(resultado);
    }
  };

  const filtrarObjetos = (protocolos, objetos) => {
    const resultado = protocolos.map((protocolo) => {
      const subActividades = objetos
        .filter((objeto) =>
          objeto.protocol_activity_subactivity.includes(`${protocolo}_`)
        )
        .sort((a, b) => {
          const orderA = parseFloat(a.order);
          const orderB = parseFloat(b.order);

          // Comparar los valores como números
          return orderA - orderB;
        });

      return {
        name: protocolo,
        items: subActividades,
      };
    });

    return resultado;
  };

  useEffect(() => {
    setActivities(null);
    // Este código se ejecutará solo cuando `open` sea `true`
    // setLoadingButton(true);
    if (open) consultaEventos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      title="Actividades"
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={'60%'}
      footer={false}
      style={{ paddingTop: '20px' }}
    >
      {activities ? (
        <Collapse>
          {activities.map((event, index) => (
            <Panel
              header={event.name}
              key={index} /* Usamos `index + 1` como clave única */
              // extra={<CostModal pantalla="eventos" />}
            >
              <SeguimientodeActividades
                item={event.items || []}
                refreshEventos={consultaEventos}
                dateIni={
                  item.state !== 'Started'
                    ? event.items.length
                    : parseFloat(item?.token?.activity)
                }
                // consultaProtocolos={consultaProtocolos}
              />
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Spin />
      )}
    </Modal>
  );
}
