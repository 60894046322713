import { Button, DatePicker } from "antd";

import { liveInitState } from "./LiveBar";
import useVmsCameraVideo from "../../../../hooks/useVmsCameraVideo";
import useVmsAudio from "../../../../hooks/useVmsAudio";

import styles from "./jsonStyles.json";
import {
  LiveIcon,
  PauseIcon,
  SoundIcon,
  PlayBackwardsIcon,
  PlayForwardsIcon,
  SoundMute
} from "./icons";

const playbackInitState = {
  isPlayback: false,
  playing: false,
  speed: 0,
  timeStamp: null,
  timeStampUserChange: false,
  audio: false,
  lowFrames: false
};

export default function PlaybackBar({ cameraInfo, live, setLive, playback, setPlayback, vmsSession }) {
  const { loadingVideo, videoController } = useVmsCameraVideo({ cameraInfo, vmsSession, live, setLive, playback, setPlayback });
  const { loadingAudio } = useVmsAudio({ cameraInfo, vmsSession, live, setLive, playback, setPlayback, videoController });

  const btnSwitchToLiveHandler = () => {
    setPlayback(playbackInitState);
    setLive({ ...liveInitState, isLive: true, playing: true });
  }

  const btnPlayBackwardsHandler = () => {
    const playbackParams = playback.speed < 0 ? { speed: 0, playing: false } : { speed: -1, playing: true }
    setPlayback(prevState => { return { ...prevState, ...playbackParams, audio: false } })
  }

  const btnPlayForwardsHandler = () => {
    const playbackParams = playback.speed > 0 ? { speed: 0, playing: false } : { speed: 1, playing: true }
    setPlayback(prevState => { return { ...prevState, ...playbackParams, audio: false } })
  }

  const dateTimeStampOpenHandler = () => {
    setPlayback(prevState => { return { ...prevState, playing: false, speed: 0, audio: false } });
  }

  const dateTimeStampOkHandler = (value) => {
    setPlayback(prevState => { return { ...prevState, playing: false, speed: 0, timeStamp: value, timeStampUserChange: true, audio: false } });
  }

  const btnPlaybackAudioHandler = () => {
    setPlayback(prevState => { return { ...prevState, audio: !prevState.audio } })
  }

  return (
    <>
      <div style={styles.bottomBar}>
        <Button icon={<LiveIcon />} style={styles.bottomBarButton} onClick={btnSwitchToLiveHandler} />

        <div style={styles.bottomBarCenter}>

          <Button disabled={loadingVideo} name="btnPlayBackwards" icon={playback.playing && playback.speed < 0
            ? <PauseIcon /> : <PlayBackwardsIcon />} onClick={btnPlayBackwardsHandler} style={styles.bottomBarButton} />

          <DatePicker name="dateTimeStamp" showTime style={{ "margin-left": "10px" }} 
          value={playback.timeStamp} size="small" onOpenChange={dateTimeStampOpenHandler} onOk={dateTimeStampOkHandler} />

          <Button disabled={loadingVideo} name="btnPlayForwards" 
            icon={playback.playing && playback.speed > 0 ? <PauseIcon /> : <PlayForwardsIcon />} 
            onClick={btnPlayForwardsHandler} style={styles.bottomBarButton} />

          { cameraInfo.hasSpeaker && 
            <Button disabled={loadingAudio} icon={playback.audio ? <SoundIcon /> : <SoundMute />} 
              onClick={btnPlaybackAudioHandler} style={styles.bottomBarButton}/>}

        </div>
      </div>
      {(loadingVideo || loadingAudio) && <h4>Cargando...</h4>}
      {(playback.lowFrames) && <h4>El servidor está tardando en responder...</h4>}
    </>

  );
}

export { playbackInitState }