import React, { createContext, useEffect, useState } from 'react';

import Loading from '../components/layouts/Loading';
import userService from '../services/UserService';
import useVmsAuth from '../hooks/useVmsAuth';
import useAlarms from '../hooks/useAlarms';
import { useNavigate } from 'react-router-dom';
//import useNotification from '../hooks/useNotification';

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import axios from 'axios';

export const AuthContext = createContext();

const poolData = {
  UserPoolId: 'us-east-1_H54j9YEXQ',
  ClientId: '43k5sm5mhn0m6rokqfrrgttmur',
};


const Userpool = new CognitoUserPool(poolData);

export default function AuthProvider({ children }) {
  const [session, setSession] = useState({
    user: null,
    empresa: null,
    loading: true,
    error: null,
    configuracionesEmpresa: null,
    accessToken: null,
    tokenArgis: null,
    roles: null,
  });

  const navigate = useNavigate();
  const { vmsSession } = useVmsAuth(session.user);
  const { alarmsSession } = useAlarms(session.user);
  //const { Notificaciones } = useNotification(session.user);
  const [eventos, setEventos] = useState([]);
  const [Estaciones, setEstaciones] = useState([]);
  const [locations, setLocations] = useState([]);

  const [UsuariosList, setUsuariosList] = useState([]);


  const pswEsri = process.env.REACT_APP_KEY_pswEsri || '51x3_4p11c4x10#';

  const fetchFun = async () => {
    var formdata = new FormData();
    formdata.append('username', 'cise_app');
    formdata.append('password', pswEsri);
    formdata.append('client', 'referer');
    formdata.append('expiration', '60');
    formdata.append('f', 'pjson');
    formdata.append(
      'referer',
      'https://www.medellin.gov.co/mapas/rest/services/ServiciosCiudad/CartografiaBase/MapServer'
    );

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    try {
      const response = await fetch(
        'https://metrogis.metrodemedellin.gov.co/portal/sharing/rest/generateToken',
        requestOptions
      );
      const data = await response.json();
      const token = data.token; // Obtén el token de la respuesta

      // Luego usa el token para obtener los datos de usuario

      consultarEstaciones(token);

      return token;
    } catch (error) {
      console.error('Error al obtener el token:', error);
    }
  };

  const consultarEstaciones = async () => {
    const headers = {};

    const url =
      'https://services3.arcgis.com/ARUXxWVTK25ENUfB/ArcGIS/rest/services/Sistema_Metro2/FeatureServer/1/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&relationParam=&returnGeodetic=false&outFields=*&returnGeometry=true&returnCentroid=false&returnEnvelope=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=';

    try {
      const response = await axios.get(url, { headers });
      const data = response.data.features;
      //console.log(data);
      setEstaciones(data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const consultarEventos = async () => {
    const myHeaders = {
      Accept: 'application/json',
      'x-api-key': 'A0mDYHhECYauV96AWjuuMHknT25VRnJ84KUjaO6c',
    };

    try {
      const response = await axios.get(
        'https://lcqea68lvi.execute-api.us-east-1.amazonaws.com/prod/events',
        {
          headers: myHeaders,
        }
      );

      const data = response.data;

      if (!Array.isArray(data.Items)) {
        throw new Error('Los datos no son un array válido.');
      }

      const arrayEvents = data.Items.filter(
        (item) =>
          item.id &&
          typeof item.id === 'string' &&
          item.id.match(/^CISE-\d{5}-\d{4}$/)
      ).sort((a, b) => {
        const idA = parseInt(a.id.split('-')[1]); // Obtener el número del campo 'id' para el objeto 'a'
        const idB = parseInt(b.id.split('-')[1]); // Obtener el número del campo 'id' para el objeto 'b'
        return idB - idA; // Ordenar de mayor a menor
      });

      const eventosFiltrados = arrayEvents.filter(
        (evento) => evento.state === 'InProgress' || evento.state === 'Open'
      );
      setEventos(eventosFiltrados);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const consultarUbicaciones = async () => {
    const myHeaders = {
      Accept: 'application/json',
    };

    try {
      const response = await axios.get(
        'https://f2r8ymqcse.execute-api.us-east-1.amazonaws.com/prod/locations',
        {
          headers: myHeaders,
        }
      );

      const data = response.data;

      setLocations(data.locations);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      setSession({
        ...session,

        loading: false,
      });
    } else {
      fetchFun();
      consultarEventos();
      consultarUbicaciones();
      getSession();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getSession() {
    try {
      //const current = await Auth.currentSession();

      navigate("/inicio")
      let correo = localStorage.getItem('email');

      const { user, empresa, configuracionesEmpresa, roles } =
        await userService.getUserInfo(correo);

      const tokenArgis = await fetchFun();
      setSession({
        ...session,
        user,
        empresa,
        tokenArgis,
        configuracionesEmpresa,
        roles,
        loading: false,
      });
    } catch (error) {
      setSession({ ...session, error: error.message, loading: false });
    }
  }

  async function login(values) {
    try {
      const authenticationData = {
        Username: values.username,
        Password: values.password,
      };

      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );

      const userData = {
        Username: values.username,
        Pool: Userpool,
      };

      const cognitoUser = new CognitoUser(userData);

      const sessionNow = await new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (session) => {
            resolve(session);
          },
          onFailure: (err) => {
            console.error('Error en el inicio de sesión', err);
            reject(err);
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            // El usuario debe cambiar la contraseña
            // Redirigir al usuario a la página de cambio de contraseña
            resolve({
              newPasswordRequired: true,
              userAttributes,
              requiredAttributes,
            });
          },
        });
      });

      const { roles } = await userService.getUserInfo(values.username);

      const tokenArgis = await fetchFun();

      let user = sessionNow.idToken.payload;
      let accessToken = sessionNow.idToken.jwtToken;
      localStorage.setItem('token', sessionNow.idToken.jwtToken);
      localStorage.setItem('email', user.email);
      setSession({
        ...session,
        user,
        accessToken,
        roles,
        loading: false,
        tokenArgis,
        error: null,
      });
      fetchFun();
      consultarEventos();
      consultarUbicaciones();
      navigate("/inicio")
    } catch (error) {
      setSession({ ...session, error: error.message });
      // Manejar errores de inicio de sesión
      // Auth.signOut();
      throw error;
    }
  }

  // async function signUp(values) {
  //   try {
  //     // Registro del usuario
  //     Userpool.signUp(
  //       values.username,
  //       values.password,
  //       [],
  //       null,
  //       async (err, data) => {
  //         if (err) {
  //           console.error(err);
  //         } else {
  //           // Confirmación del usuario
  //           // const userData = {
  //           //   Username: values.username,
  //           //   Pool: Userpool,
  //           // };
  //           // const cognitoUser = new CognitoUser(userData);
  //           // cognitoUser.confirmRegistration(
  //           //   values.confirmationCode,
  //           //   true,
  //           //   (err, result) => {
  //           //     if (err) {
  //           //       console.error(err);
  //           //     } else {
  //           //       console.log('Usuario confirmado:', result);
  //           //     }
  //           //   }
  //           // );
  //         }
  //       }
  //     );

  //     // Puedes hacer lo que necesites con user, accessToken y roles aquí
  //     // Por ejemplo, almacenarlos en el estado de tu componente React

  //     return true;
  //   } catch (error) {
  //     // Manejar errores de inicio de sesión
  //     // Auth.signOut();
  //     throw error;
  //   }
  // }

  async function signUp(values) {
    try {
      // Registro del usuario
      const signUpPromise = () => {
        return new Promise((resolve, reject) => {
          Userpool.signUp(
            values.username,
            values.password,
            [], // Atributos adicionales, si es necesario
            null,
            (err, data) => {
              if (err) {
                console.error(err);
                reject(err);
              } else {
                resolve(data);
              }
            }
          );
        });
      };

      await signUpPromise();

      // Confirmación del usuario
      // const confirmPromise = () => {
      //   return new Promise((resolve, reject) => {
      //     const userData = {
      //       Username: values.username,
      //       Pool: Userpool,
      //     };
      //     const cognitoUser = new CognitoUser(userData);

      //     cognitoUser.confirmRegistration(
      //       values.confirmationCode,
      //       true,
      //       (err, result) => {
      //         if (err) {
      //           console.error(err);
      //           reject(err);
      //         } else {
      //           console.log('Usuario confirmado:', result);
      //           resolve(result);
      //         }
      //       }
      //     );
      //   });
      // };

      // await confirmPromise();

      // Puedes hacer lo que necesites con user, accessToken y roles aquí
      // Por ejemplo, almacenarlos en el estado de tu componente React

      return true;
    } catch (error) {
      // Manejar errores de inicio de sesión
      // Auth.signOut();
      throw error;
    }
  }

  async function logout() {
    try {
      setSession({
        ...session,
        user: null,
        empresa: null,
        loading: false,
        error: null,
        roles: null,
        configuracionesEmpresa: null,
        tokenArgis: null,
        accessToken: null,
      });
      localStorage.removeItem('token');
      localStorage.removeItem('email');
    } catch (error) {
      setSession({ ...session, error: error.message });
    }
  }

  const contextValue = {
    session,
    signUp,
    login,
    logout,
    eventos,
    setEventos,
    Estaciones,
    vmsSession,
    alarmsSession,
    consultarEventos,
    locations,
    UsuariosList,
    setUsuariosList,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {session.loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
}
