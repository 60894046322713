import { Modal, Form, Input, message, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import useAuth from '../../../../hooks/useAuth';
import PageHeader from '../../../headers/PageHeader';

export default function ModalCrearUsuario({ open, setOpen, fetchData }) {
  const [submit, setSubmit] = useState(false);
  const [form] = Form.useForm();
  const { signUp } = useAuth();

  async function handleSignUp(values) {
    let registro = {
      password: values.password,
      username: values.username.toLowerCase(),
    };
    try {
      setSubmit(true);
      await signUp(registro);
      message.success('Usuario Creado');
      fetchData();
      onCancel();
    } catch (error) {
      console.error(error);
      message.error('Error en solicitud');
    } finally {
      setSubmit(false);
    }
  }
  const onCancel = () => {
    setOpen(false);
    fetchData();
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      visible={open}
      onCancel={onCancel}
      onOk={onCancel}
      footer={false}
      width={'40%'}
    >
      <PageHeader title="Crear Usuario"></PageHeader>
      <Form size="large" onFinish={handleSignUp}>
        <Form.Item
          name={'username'}
          rules={[
            {
              required: true,
              message: 'Debes introducir tu correo electrónico',
            },
            {
              type: 'email',
              message: 'Debes introducir un correo electrónico válido',
            },
          ]}
        >
          <Input placeholder="Correo electrónico" />
        </Form.Item>
        {/* <Form.Item
          name={'password'}
          rules={[
            { required: true, message: 'Debes introducir tu contraseña' },
          ]}
        >
          <Input.Password placeholder="Contraseña" />
        </Form.Item> */}

        <Form.Item
          name={'password'}
          rules={[
            { required: true, message: 'Debes introducir tu contraseña' },
          ]}
        >
          <Input.Password placeholder="Contraseña" />
        </Form.Item>
        <div>
          <p>La contraseña debe cumplir con las siguientes condiciones:</p>
          <ul>
            <li>Tener al menos una mayúscula</li>
            <li>Usar al menos un número</li>
            <li>Usar al menos un carácter especial</li>
          </ul>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={submit}>
            Crear Usuario
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
