import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Input, message } from 'antd';

import activitiesService from '../../../services/activitiesService';
import useAuth from '../../../hooks/useAuth';

export default function ModalComentario({ label, item, consultaEventos }) {
  const { session } = useAuth();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const validateMessages = {
    required: 'Es requerido',
    types: {
      email: 'Correo no valido',
      number: 'Numero no valido',
    },
  };

  const FalsaAlarma = (comment) => {
    const registro = {
      eventId: item.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      complete: true,
      order: item.order,
      falseAlarm: true,
      comment,
    };

    ejecutarActividad(registro, 'falsa alarma');
  };

  const Completar = (comment) => {
    let registro = {
      eventId: item.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      complete: true,
      order: item.order,
      comment,
    };

    const contienePalabras =
      item.protocol_activity_subactivity.includes('falsa') &&
      item.protocol_activity_subactivity.includes('alarma');

    if (contienePalabras) {
      registro.falseAlarm = false;
    }
    ejecutarActividad(registro);
  };
  const Saltar = (comment) => {
    const registro = {
      eventId: item.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      skip: true,
      order: item.order,
      comment: comment,
    };
    ejecutarActividad(registro);
  };

  const ejecutarActividad = async (registro, activityaccion = 'normal') => {
    setLoading(true);
    try {
      await activitiesService.funcionActividad(registro);

      message.success('Actividad Ejecutada');

      consultaEventos(activityaccion);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logro hacer la consulta');
    }
    setOpen(false);
    setLoading(false);
  };

  const onFinish = (values) => {
    const { comment } = values;
    if (label === 'Saltar') {
      Saltar(comment);
    } else if (
      label === 'Completar' ||
      label === 'No es falsa Alarma' ||
      label === 'Cerrar'
    ) {
      Completar(comment);
    } else if (label === 'Falsa Alarma') {
      FalsaAlarma(comment);
    }
  };

  useEffect(() => {
    form.resetFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const tipoBotones = {
    Completar: 'primary',
    Cerrar: 'primary',
  };

  return (
    <div>
      <Button
        type={tipoBotones[label] || 'default'}
        onClick={() => setOpen(true)}
        style={{ marginBottom: '10px', width: '200px' }}
      >
        {label}
      </Button>
      <Modal
        title={'Justificar Acción'}
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="comment"
            label="Comentario"
            rules={[
              {
                required: true,
                message: 'Debes de completar este campo.',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Enviar
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
