import React from 'react';
import { Table, Space } from 'antd';
import DeleteDocumentButton from '../../modales/eventos/components/editarEventoComponents/DeleteDocumentButton';

export default function TablaDocumentosActividades({
  dataProtocol,
  setRefresh,
  refresh,
}) {
  // Columnas para la tabla de "agresion"
  const agresionColumns = [
    {
      title: 'Nombre de Actividad',
      dataIndex: 'protocol_activity_subactivity_id',
      key: 'protocol_activity_subactivity_id',
    },
    {
      title: 'Nombre del archivo',
      dataIndex: 'url',
      key: 'url',
      render: (text) => <>{text.split('/').pop().split('?')[0]}</>,
    },

    {
      title: 'Acciones',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <Space>
          <DeleteDocumentButton
            item={record}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Space>
      ),
    },
  ];
  return (
    <>
      {dataProtocol.length !== 0 && (
        <Table dataSource={dataProtocol} columns={agresionColumns} />
      )}
    </>
  );
}
