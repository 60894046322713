/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

export default function useVmsCameraPtz({
  vmsSession,
  cameraInfo,
  live,
  setLive,
  videoController,
}) {
  const [loading, setLoading] = useState(false);
  const ptzInfo = useRef({ videoConnection: null });

  const ptzTrigger = (command) => {
    setLoading(true);
    ptzInfo.current.videoConnection.cameraId = cameraInfo.Id;
    vmsSession.XPMobileSDK.ptzMove(ptzInfo.current.videoConnection, command);
    setLoading(false);
  };

  useEffect(() => {
    if (!cameraInfo.hasPTZ) return;
    if (!!live.ptzCommand) {
      ptzTrigger(live.ptzCommand);
      setLive((prevState) => {
        return { ...prevState, ptzCommand: null };
      });
    }
  }, [live.ptzCommand]);

  useEffect(() => {
    if (cameraInfo.hasPTZ) {
      init();
    }
  }, [cameraInfo, videoController]);

  const init = () => {
    ptzInfo.current = {
      videoConnection: videoController,
    };
  };

  return {
    loadingPtz: loading,
  };
}
