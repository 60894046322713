import React from 'react';
import { Table, Space } from 'antd';
import { WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './TablaSubActividades.css'; // Asegúrate de importar tu archivo de estilos
import ModalEditarComentario from '../../modales/generales/ModalEditarComentario';
import ModalIniciarSubA from './ModalIniciarSubA';

function obtenerDatoMasReciente(arrayDeObjetos) {
  if (arrayDeObjetos.length === 0) {
    return { comment: 'sin registro' };
  }

  const arrayOrdenado = arrayDeObjetos
    .slice()
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  return arrayOrdenado[0];
}

const TablaSubActividades = ({ data, consultaEventos }) => {
  const rowClassName = (record) => {
    return record.state === 'Created'
      ? 'fila-amarilla'
      : record.state === 'Started'
      ? 'fila-Azul'
      : record.state !== 'Complete'
      ? 'fila-verde'
      : '';
  };

  const columns = [
    {
      title: 'Sub Actividad',
      dataIndex: 'protocol_activity_subactivity',
      key: 'protocol_activity_subactivity',
      render: (text, record) => (
        <Space>
          {record.state === 'Created' && (
            <WarningOutlined style={{ color: 'yellow' }} />
          )}
          {record.state === 'Started' && (
            <CheckCircleOutlined style={{ color: 'blue' }} />
          )}
          {record.state === 'Completes' && (
            <CheckCircleOutlined style={{ color: 'green' }} />
          )}
          {text.split('_')[2]}
        </Space>
      ),
    },

    {
      title: 'Comentario',
      dataIndex: 'comments',
      key: 'comments',
      render: (text, record) => (
        <Space>
          {obtenerDatoMasReciente(text).comment || 'Sin descripción'}
        </Space>
      ),
    },

    {
      title: 'Acciones',
      dataIndex: 'subactividades',
      key: 'subactividades',
      render: (text, record) => (
        <Space>
          {record.state === 'Created' && (
            <>
              <ModalIniciarSubA
                item={record}
                consultaActividades={consultaEventos}
                typeModal="completar"
              />

              {!record.isMandatory && (
                <ModalIniciarSubA
                  item={record}
                  consultaActividades={consultaEventos}
                  typeModal="saltar"
                />
              )}
            </>
          )}
          {record.state === 'Started' && (
            <>
              <ModalIniciarSubA
                item={record}
                consultaActividades={consultaEventos}
                typeModal="completarfull"
              />

              {!record.isMandatory && (
                <ModalIniciarSubA
                  item={record}
                  consultaActividades={consultaEventos}
                  typeModal="saltar"
                />
              )}
            </>
          )}
          {record.state !== 'Created' && (
            <ModalEditarComentario
              item={record}
              textactual={
                obtenerDatoMasReciente(record.comments).comment ||
                'Sin descripción'
              }
              consultaActividades={consultaEventos}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      {data[0].state === 'Completed' && (
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          rowClassName={rowClassName}
        />
      )}
    </>
  );
};

export default TablaSubActividades;
