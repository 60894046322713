/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

const audioInitState = {
  Id: null,
  XPMobileSDK: null,
  wrapper: null,
  audioStreamId: null,
  url: null,
  sourceBuffer: null,
  abortController: null,
  signal: null,
  audioSourceUrl: null,
  mediaSource: null,
  audioPlayer: null,
  audioActive: null,
  lastSignalType: null,
  stoppedByButtonClick: null,
  isPlaying: null,
  videoController: null,
  stopping: false,
};

export default function useVmsAudio({
  cameraInfo,
  vmsSession,
  live,
  setLive,
  playback,
  setPlayback,
  videoController,
}) {
  const [loadingAudio, setLoadingAudio] = useState(false);

  const audioInfo = useRef(audioInitState);

  const audioStreamSuccessCallback = (audioConnection) => {
    audioInfo.current.audioStreamId =
      audioConnection.response.outputParameters.StreamId;
    audioInfo.current.url =
      audioInfo.current.audioSourceUrl + audioInfo.current.audioStreamId;
    audioInfo.current.audioPlayer.src = URL.createObjectURL(
      audioInfo.current.mediaSource
    );

    setLoadingAudio(false);

    audioInfo.current.audioActive = true;
  };

  const fetchWithTimeout = (ms, promise, toSetTimeout) => {
    let didTimeOut = false;
    let fetchTimeout = false;

    const promiseCallback = function (resolve, reject) {
      if (toSetTimeout) {
        fetchTimeout = setTimeout(() => {
          didTimeOut = true;
          reject(new Error('fetch timed out'));
        }, ms);
      }

      const catchFunc = function (err) {
        if (didTimeOut) return;
        reject(err);
      };

      const thenFunc = function (response) {
        if (fetchTimeout) {
          clearTimeout(fetchTimeout);
        }

        if (!didTimeOut) {
          resolve(response);
        }
      };

      promise.then(thenFunc).catch(catchFunc);
    };

    return new Promise(promiseCallback);
  };

  const onCanPlay = (event) => {
    const playPromise = audioInfo.current.audioPlayer.play();

    if (playPromise !== undefined) {
      playPromise.catch(audioInfo.current.onPlayError);
    }
  };

  const onPlayError = (error) => {
    if (!audioInfo.current.audioActive) {
      return;
    }
    stopAudioStream();

    if (
      error.name !== 'AbortError' &&
      error.name !== 'InvalidStateError' &&
      !audioInfo.current.stoppedByButtonClick
    ) {
      audioStreamErrorCallback(error);
    }

    if (error.name === 'AbortError') {
      audioInfo.current.stoppedByButtonClick = true;
    } else {
      audioInfo.current.stoppedByButtonClick = false;
    }
  };

  const stopAudioStream = () => {
    if (!audioInfo.current.audioActive) {
      return;
    }
    audioInfo.current.audioActive = false;

    const sourceBufferedLength = audioInfo.current.sourceBuffer
      ? audioInfo.current.sourceBuffer.buffered.length
      : 0;
    audioInfo.current.abortController &&
      audioInfo.current.abortController.signal.aborted &&
      audioInfo.current.abortController.abort();
    if (audioInfo.current.mediaSource.readyState === 'open') {
      audioInfo.current.sourceBuffer.abort();
      audioInfo.current.mediaSource.removeSourceBuffer(
        audioInfo.current.sourceBuffer
      );

      if (sourceBufferedLength) {
        audioInfo.current.mediaSource.endOfStream();
      }
    }

    if (audioInfo.current.resBodyReader) {
      audioInfo.current.resBodyReader.cancel().catch(function () {});
      audioInfo.current.resBodyReader.releaseLock();
      audioInfo.current.resBody.cancel().catch(function () {});

      audioInfo.current.resBodyReader = null;
      audioInfo.current.resBody = null;
    }

    tryStopStreaming(audioInfo.current.audioStreamId);
    audioInfo.current.audioStreamId = null;
    setLoadingAudio(false);
  };

  const signalEndOfStream = (error) => {
    if (error && error.message && error.message === 'Failed to read.') {
      onPlayError(error);
    }

    if (audioInfo.current.mediaSource.readyState === 'open') {
      audioInfo.current.mediaSource.endOfStream();
    }

    return audioInfo.current.mediaSource.readyState;
  };

  const sourceOpen = (event) => {
    audioInfo.current.sourceBuffer =
      audioInfo.current.mediaSource.addSourceBuffer('audio/mpeg');
    audioInfo.current.sourceBuffer.mode = 'sequence';

    audioInfo.current.abortController = new AbortController();

    fetchWithTimeout(
      3000,
      fetch(audioInfo.current.url, {
        signal: audioInfo.current.abortController.signal,
      }),
      audioInfo.current.isLive
    )
      .then(getReader)
      .then(playStream)
      .catch(onPlayError);
  };

  const getReader = (response) => {
    if (response.status !== 200) {
      throw new TypeError();
    }

    audioInfo.current.resBody = response.body;
    audioInfo.current.resBodyReader = response.body.getReader();

    return audioInfo.current.resBodyReader;
  };

  const playStream = (reader) => {
    const processStream = (data) => {
      if (data.done) {
        return;
      }

      if (audioInfo.current.mediaSource.readyState === 'open') {
        audioInfo.current.sourceBuffer.appendBuffer(data.value);
      }
    };

    audioInfo.current.sourceBuffer.addEventListener('updateend', () => {
      reader
        .read()
        .then(processStream)
        .catch(audioInfo.current.signalEndOfStream);
    });

    reader.read().then(processStream);

    if (reader.closed) {
      return reader.closed.then(audioInfo.current.signalEndOfStream);
    }
  };

  const tryStopStreaming = (audioStreamId) => {
    if (audioStreamId === null) {
      return audioStreamId;
    }

    try {
      audioInfo.current.audioPlayer.pause();

      // audioInfo.current prevents thread leaks and crashes as removing all references to the audio tag
      // is not enough for the garbage collection of the audio stream to take place
      //audioInfo.current.player.src = '';
      audioInfo.current.audioPlayer.removeAttribute('src');
      audioInfo.current.audioPlayer.load();
    } catch (e) {}

    audioInfo.current.XPMobileSDK.closeStream(audioStreamId);
  };

  const requestAudioStream = () => {
    const signalTypeId =
      audioInfo.current.XPMobileSDK.interfaces.VideoConnectionSignal[
        audioInfo.current.videoController.request.parameters.SignalType.toLowerCase()
      ];
    const isSignalTypePlayback =
      signalTypeId ===
      audioInfo.current.XPMobileSDK.interfaces.VideoConnectionSignal.playback;

    if (
      !(
        audioInfo.current.lastSignalType === 'Playback' && !isSignalTypePlayback
      ) &&
      audioInfo.current.lastSignalType === 'Live' &&
      isSignalTypePlayback
    ) {
      audioInfo.current.lastSignalType =
        audioInfo.current.videoController.request.parameters.SignalType;
      return;
    }
    audioInfo.current.audioActive = true;
    audioInfo.current.lastSignalType =
      audioInfo.current.videoController.request.parameters.SignalType;

    audioInfo.current.XPMobileSDK.requestAudioStream(
      cameraInfo.Items[0].Id,
      {
        signal: signalTypeId,
        playbackControllerId: isSignalTypePlayback
          ? audioInfo.current.videoController.videoId
          : null,
      },
      audioStreamSuccessCallback,
      audioStreamErrorCallback
    );
  };

  const audioStreamErrorCallback = (error) => {
    // console.log('Audio stream error: ' + error.code + '|' + error.message);
    setLive((prevState) => {
      return { ...prevState, audio: false };
    });
  };

  const init = () => {
    audioInfo.current = {
      ...audioInitState,
      Id: cameraInfo.Id,
      XPMobileSDK: vmsSession.XPMobileSDK,
      wrapper: document.querySelector('#container' + cameraInfo.Id),
      audioSourceUrl: `${vmsSession.XPMobileSDKSettings.MobileServerURL}${vmsSession.XPMobileSDKSettings.audioChannel}/`,
      mediaSource: new MediaSource(),
      audioActive: false,
      stoppedByButtonClick: false,
      isPlaying: false,
      videoController: videoController,
    };

    audioInfo.current.audioPlayer = document.querySelector(
      '#container' + cameraInfo.Id + ' audio'
    );
    audioInfo.current.audioPlayer.type = 'audio/mpeg';

    audioInfo.current.wrapper.appendChild(audioInfo.current.audioPlayer);
    audioInfo.current.mediaSource.addEventListener('sourceopen', sourceOpen);
    audioInfo.current.mediaSource.addEventListener(
      'sourceended',
      signalEndOfStream
    );
    audioInfo.current.audioPlayer.addEventListener('pause', () => {
      stopAudioStream();
    });
    audioInfo.current.audioPlayer.addEventListener('stop', () => {
      stopAudioStream();
    });
    audioInfo.current.audioPlayer.addEventListener('canplay', onCanPlay, false);
    audioInfo.current.audioPlayer.addEventListener(
      'play',
      () => {
        audioInfo.current.audioActive = true;
      },
      false
    );
  };

  useEffect(() => {
    if (!!videoController && cameraInfo.hasMicro) {
      init();
    }
  }, [cameraInfo, videoController]);

  useEffect(() => {
    if (!cameraInfo.hasMicro) return;

    if ((live.audio || playback.audio) && !audioInfo.current.audioActive) {
      setLoadingAudio(true);
      requestAudioStream();
    }

    return () => {
      if (audioInfo.current.audioActive) {
        stopAudioStream();
      }
    };
  }, [live.audio, playback.audio]);

  return {
    loadingAudio: loadingAudio,
  };
}
