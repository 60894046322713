import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import TablaActividades from '../../Tablas/TablaActividades';
import PageHeader from '../../headers/PageHeader';
import protocolosService from '../../../services/protocolosService';
import DateRangeFilter from '../eventos/components/DateRangeFilter';
import CardProtocolos from './components/CardProtocolos';

export default function ModalActividades({ open, setOpen }) {
  const [loadingButton, setLoadingButton] = useState(true);
  const [protocolsItems, setProtocolsItems] = useState([]);
  const [, setEventFilter] = useState([]);
  const [filteredItemCount, setFilteredItemCount] = useState(0);
  const consultaProtocolos = async () => {
    setLoadingButton(true);
    try {
      const response = await protocolosService.consultarProtocolo();
      let data = response;

      const arrayEvents = data.Items.filter(
        (item) =>
          item.eventId &&
          typeof item.eventId === 'string' &&
          item.eventId.match(/^CISE-\d{5}-\d{4}$/)
      ).sort((a, b) => {
        const idA = parseInt(a.eventId.split('-')[1]); // Obtener el número del campo 'id' para el objeto 'a'
        const idB = parseInt(b.eventId.split('-')[1]); // Obtener el número del campo 'id' para el objeto 'b'
        return idB - idA; // Ordenar de mayor a menor
      });

      AjusteArray(arrayEvents || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logro hacer la consulta');
    }
    setLoadingButton(false);
  };

  const AjusteArray = (array) => {
    array.forEach((objeto) => {
      objeto.idKey = objeto.eventId + objeto.protocol;
      objeto.causesProtocol = obtenerProtocolCauses(objeto.causes);
    });
    setProtocolsItems(array);
    setEventFilter(array);
    setFilteredItemCount(array.length || 0);
  };

  function obtenerProtocolCauses(arrayDeObjetos = []) {
    // Verifica si el array es vacío
    if (arrayDeObjetos.length === 0) {
      return [];
    }

    const protocolCauses = arrayDeObjetos.map(
      (objeto) => objeto.protocol_cause
    );

    return protocolCauses;
  }

  useEffect(() => {
    // Este código se ejecutará solo cuando `open` sea `true`

    if (open) consultaProtocolos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      title=""
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={'90%'}
      footer={false}
    >
      <PageHeader title="Listado de Protocolos" head="Actividades"></PageHeader>

      <div style={{ marginBottom: '10px' }}>
        <DateRangeFilter
          type="protocolos"
          filteredItemCount={filteredItemCount}
          loadingButton={loadingButton}
          setLoadingButton={setLoadingButton}
          AjusteArray={AjusteArray}
        />
      </div>
      <div className="componentWeb">
        <CardProtocolos
          dataSource={protocolsItems}
          fetchData={consultaProtocolos}
        />
      </div>

      <div className="textAjust">
        <TablaActividades
          loading={loadingButton}
          itemsData={protocolsItems}
          consultaProtocolos={consultaProtocolos}
          setFilteredItemCount={setFilteredItemCount}
        />
      </div>
    </Modal>
  );
}
