/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

const vmsSessionInitState = {
  XPMobileSDK: null,
  XPMobileSDKSettings: null,
  loggingIn: false,
  loading: true,
  loadingData: false,
  isLogged: false,
  isConnected: false,
  connecting: false,
  error: null,
  cameraList: {},
  attempts: 10,
};

export default function useVmsAuth(user) {
  const [vmsSession, setVmsSession] = useState(vmsSessionInitState);

  const connectionFunctions = {
    connectionDidConnect: () =>
      setVmsSession((prevState) => {
        return {
          ...prevState,
          connecting: false,
          isConnected: true,
          loggingIn: true,
          isLogged: false,
          error: null,
        };
      }),
    connectionFailedToConnect: () =>
      setVmsSession((prevState) => {
        return {
          ...prevState,
          error: 'Failed To Connect with Mobile Server',
          attempts: prevState.attempts - 1,
        };
      }),
    connectionDidLogIn: () =>
      setVmsSession((prevState) => {
        return {
          ...prevState,
          loggingIn: false,
          isLogged: true,
          loadingData: true,
          error: null,
        };
      }),
    connectionFailedToLogIn: () =>
      setVmsSession((prevState) => {
        return {
          ...prevState,
          error: 'Failed To Login into Mobile Server',
          attempts: prevState.attempts - 1,
        };
      }),
    connectionLostConnection: () =>
      setVmsSession({
        ...vmsSessionInitState,
        XPMobileSDK: window.XPMobileSDK,
        XPMobileSDKSettings: window.XPMobileSDKSettings,
        loading: false,
        connecting: true,
        isConnected: false,
        error: 'Disconnected from the server',
      }),
  };

  useEffect(() => {
    const loadMobileSdk = window.LoadMobileSdk;
    loadMobileSdk(onLoadSdk);
  }, []);

  useEffect(() => {
    if (vmsSession.attempts === 0) return;
    if (!vmsSession.loading && vmsSession.connecting) {
      connectToServer(connectionFunctions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vmsSession.loading,
    vmsSession.connecting,
    vmsSession.error,
    vmsSession.attempts,
  ]);

  useEffect(() => {
    if (vmsSession.attempts === 0) return;
    if (user && vmsSession.loggingIn) {
      const credentials = {
        user: 'cise3_nua',
        pass: 'Metro.123',
        type: 'ActiveDirectory',
      };
      loginCommand(credentials);
    }
    if (!user && vmsSession.isLogged) {
      vmsSession.XPMobileSDK.disconnect();
      setVmsSession({
        ...vmsSessionInitState,
        XPMobileSDK: window.XPMobileSDK,
        XPMobileSDKSettings: window.XPMobileSDKSettings,
        loading: false,
        connecting: true,
        isConnected: false,
      });
    }
  }, [user, vmsSession.loggingIn, vmsSession.error, vmsSession.attempts]);

  useEffect(() => {
    if (vmsSession.attempts === 0) return;
    if (vmsSession.isLogged && vmsSession.loadingData) {
      loadCamerasInfo();
    }
  }, [
    vmsSession.isLogged,
    vmsSession.loadingData,
    vmsSession.error,
    vmsSession.attempts,
  ]);

  const onLoadSdk = () => {
    window.XPMobileSDKSettings.MobileServerURL =
      'https://vms.metrodemedellin.gov.co:8082';
    window.XPMobileSDKSettings.EnableConsoleLog = false;
    window.logger.init();
    setVmsSession((prevState) => {
      return {
        ...prevState,
        XPMobileSDK: window.XPMobileSDK,
        XPMobileSDKSettings: window.XPMobileSDKSettings,
        loading: false,
        connecting: true,
        isConnected: false,
      };
    });
  };

  const connectToServer = (connectionFunctions) => {
    // Connect to the desired server (defaults to the current URL)
    const url =
      vmsSession.XPMobileSDKSettings.MobileServerURL || window.location.origin;

    let lastObserver;
    if (lastObserver) {
      vmsSession.XPMobileSDK.removeObserver(lastObserver);
    }

    lastObserver = connectionFunctions;
    vmsSession.XPMobileSDK.addObserver(lastObserver);
    vmsSession.XPMobileSDK.connect(url);
  };

  const loginCommand = (credentials) => {
    vmsSession.XPMobileSDK.login(
      credentials.user,
      credentials.pass,
      credentials.type,
      {
        SupportsAudioIn: 'Yes',
        SupportsAudioOut: 'Yes',
      }
    );
  };

  const loadCamerasInfo = () => {
    vmsSession.XPMobileSDK.getAllViews((items) => {
      if (!items) {
        setVmsSession((prevState) => {
          return {
            ...prevState,
            error: 'Failed to get views capabilities',
            attempts: prevState.attempts - 1,
          };
        });
        return;
      }
      let cameraList = {};
      for (let i = 0; i < items[0].Items[0].Items[0].Items.length; i++) {
        const camera = items[0].Items[0].Items[0].Items[i];
        const hasPTZ = camera.PTZ === 'Yes';
        const hasMicro = camera.Items.reduce(
          (acc, curr) => acc || curr.Type === 'Microphone',
          false
        );
        const hasSpeaker = camera.Items.reduce(
          (acc, curr) => acc || curr.Type === 'Speaker',
          false
        );
        cameraList[camera.Id] = { ...camera, hasPTZ, hasMicro, hasSpeaker };
      }
      setVmsSession((prevState) => {
        return {
          ...prevState,
          loadingData: false,
          cameraList: cameraList,
          attempts: 10,
          error: null,
        };
      });
    });
  };

  return {
    vmsSession,
  };
}
