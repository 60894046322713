import React, { useState } from 'react';
import { Button } from 'antd';
import {
  PlusSquareOutlined,
  BarChartOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import ModalActividades from '../../modales/Actividades/ModalActividades';
import ModalEventos from '../../modales/eventos/ModalEventos';
import ModalReportes from '../../modales/Reportes/ModalReportes';

export default function FooterPage({ setDatosSeleccionados }) {
  const [show, setShow] = useState(false);
  const [showActividades, setShowActividades] = useState(false);
  const [showReportes, setShowReportes] = useState(false);

  const options = [
    {
      value: 'Eventos',
      label: 'Eventos',
      icon: <PlusSquareOutlined />,
    },
    {
      value: 'Protocolos',
      label: 'Protocolos',
      icon: <FileTextOutlined />,
    },
    {
      value: 'Reportes',
      label: 'Dashboards',
      icon: <BarChartOutlined />,
    },
  ];
  const [selectedOption] = useState('');

  const handleOptionClick = (value) => {
    setShow(false);
    setShowActividades(false);
    setShowReportes(false);

    if (value === 'Eventos') {
      setShow(true);
      setDatosSeleccionados('2');
    } else if (value === 'Protocolos') {
      setShowActividades(true);
      setDatosSeleccionados('1');
    } else if (value === 'Reportes') {
      setShowReportes(true);
      setDatosSeleccionados('4');
    }
    // Puedes realizar otras acciones aquí al hacer clic en una opción
  };

  return (
    <>
      <ModalEventos setOpen={setShow} open={show} />
      <ModalActividades setOpen={setShowActividades} open={showActividades} />
      <ModalReportes setOpen={setShowReportes} open={showReportes} />

      <div>
        {options.map((option) => (
          <Button
            key={option.value}
            type={selectedOption === option.value ? 'primary' : 'default'}
            onClick={() => handleOptionClick(option.value)}
            icon={option.icon}
            style={{
              width: '33.33%',
              height: '40px',
              backgroundColor: '#22252B',
              color: 'white',
            }}
          >
            {option.label}
          </Button>
        ))}
      </div>

      {/* <Header
        style={{
          position: 'fixed',
          width: '100%',
          bottom: 0,
          zIndex: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fffff', // Fondo oscuro, puedes ajustar el color según tus necesidades
        }}
      > */}

      {/* </Header> */}
    </>
  );
}
