import './App.css';
import { ConfigProvider } from 'antd';
import Router from './router';
import es_ES from 'antd/es/locale/es_ES';

function App() {
  return (
    <ConfigProvider
      locale={es_ES}
      theme={{
        token: {
          colorPrimary: '#7AC142',
          borderRadius: 2,
          // Alias Token
          // colorBgContainer: '#f6ffed',
        },
      }}
    >
      <div className="App">
        <Router />
      </div>
    </ConfigProvider>
  );
}

export default App;
