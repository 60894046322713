import React, { useState } from 'react';
import { Button, Tooltip, message, Spin } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import protocolosService from '../../../services/protocolosService';
import useAuth from '../../../hooks/useAuth';

export default function BotonMultiproposito({
  record,
  type,
  typeButton = 'text',
  fun,
}) {
  const { session } = useAuth();
  const [loading, setLoading] = useState(false);
  const funcionBoton = () => {
    setLoading(true);
    let registro = {
      eventId: record.eventId,
      protocol: record.protocol,
      start: true,
      userId: session?.user?.id || session?.user?.sub,
    };
    editarProtocolo(registro);
  };

  const editarProtocolo = async (registro) => {
    try {
      await protocolosService.EditarProtocolo(record.eventId, registro);

      let data = `El Protocolo del evento ${record.eventId} Se ha Iniciado`;
      fun(record);
      message.success(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logro hacer la consulta');
    }
    setLoading(false);
  };
  return (
    <>
      {type === 'iniciar' && session.roles.protocols.isAllowedtoUpdate && (
        <>
          {loading ? (
            <Spin />
          ) : (
            <Tooltip title="Iniciar Protocolo">
              <Button
                type={typeButton}
                onClick={() => funcionBoton()}
                loading={loading}
              >
                {typeButton === 'text' ? (
                  <PlayCircleOutlined />
                ) : (
                  <span>Iniciar</span>
                )}
              </Button>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
}
