import React, { useState } from 'react';
import { Tooltip, Button, Spin, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import userService from '../../../../services/UserService';
import ModalConfirmacion from '../../generales/ModalConfirmacion';

export default function BotonDelete({ item, refesh }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const eliminarUsuario = async (registro) => {
    setLoading(true);
    try {
      await userService.EliminarUsuario(item.id, {});

      message.success('Usuario Eliminado');
      refesh();
      setShow(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logró eliminar el usuario');
    }
    setLoading(false);
  };

  const eliminar = () => {
    eliminarUsuario(item);
  };
  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <Tooltip title="Eliminar Usuario">
          <Button onClick={() => setShow(true)} type="text">
            <DeleteOutlined />
          </Button>
          <ModalConfirmacion
            funtion={eliminar}
            setOpen={setShow}
            open={show}
            title="¿Está seguro que desea eliminar el usuario?"
            message="Una vez que el usuario ha sido eliminado, debería cerrarse la sesión en todos los dispositivos donde esté conectado"
          />
        </Tooltip>
      )}
    </>
  );
}
