import dayjs from "dayjs";
import { useRef } from "react";
import { Button } from "antd";

import useVmsCameraVideo from "../../../../hooks/useVmsCameraVideo";
import useVmsCameraPtz from "../../../../hooks/useVmsCameraPtz";
import useVmsAudio from "../../../../hooks/useVmsAudio";
import useVmsPTT from "../../../../hooks/useVmsPTT";

import styles from "./jsonStyles.json";
import {
  PlaybackIcon,
  PauseIcon,
  PlayIcon,
  PtzIcon,
  SoundMute,
  SoundIcon,
  MicroMuteIcon,
  MicroActiveIcon
} from "./icons";

const liveInitState = {
  isLive: true,
  playing: true,
  ptz: false,
  audio: false,
  micro: false,
  ptzCommand: null,
  lowFrames: false
};

export default function LiveBar({ cameraInfo, live, setLive, playback, setPlayback, vmsSession}) {
  const btnPTT = useRef(null);
  const { loadingVideo, videoController } = useVmsCameraVideo({ cameraInfo, vmsSession, live, setLive, playback, setPlayback });
  const { loadingPtz } = useVmsCameraPtz({vmsSession, cameraInfo, live, setLive, videoController});
  const { loadingAudio } = useVmsAudio({ cameraInfo, vmsSession, live, setLive, playback, setPlayback, videoController });
  const { loadingPTT } = useVmsPTT({btnPTT, cameraInfo, vmsSession});

  const btnSwitchToPlayBackHandler = () => {
    setLive({ ...liveInitState, isLive: false, playing: false });
    setPlayback(prevState => { return { ...prevState, isPlayback: true, timeStamp: dayjs() } });
  }

  const btnLivePlayingHandler = () => {
    setLive(prevState => { return { ...prevState, playing: !prevState.playing, audio: false, ptz: false } })
  }

  const btnPtzHandler = () => {
    setLive(prevState => {return {...prevState, ptz: !prevState.ptz}})
  }

  const btnLiveAudioHandler = () => {
    setLive(prevState => {return {...prevState, audio: !prevState.audio}})
  }

  const microButtonOnMouseDownUpHandler = () => {
    setLive(prevState => {return {...prevState, micro: !prevState.micro}})
  }

  const microButtonOnMouseLeaveHandler = () => {
    if(live.micro){
      setLive(prevState => {return {...prevState, micro: false}})
    }
  }

  return (
    <>
    <div style={styles.bottomBar}>

        <Button icon={<PlaybackIcon />} style={styles.bottomBarButton} onClick={btnSwitchToPlayBackHandler} />

        <div style={styles.bottomBarCenter}>

          <Button disabled={loadingVideo} icon={ live.playing ? <PauseIcon /> : <PlayIcon />} 
            onClick={btnLivePlayingHandler} style={styles.bottomBarButton} />

          {cameraInfo.hasPTZ && 
            <Button disabled={loadingVideo || loadingPtz || !live.playing} icon={<PtzIcon />} onClick={btnPtzHandler} 
              style={styles.bottomBarButton} />}

          {cameraInfo.hasMicro && 
            <Button disabled={loadingVideo || loadingAudio || !live.playing} icon={live.audio ? <SoundIcon /> : <SoundMute />} 
            onClick={btnLiveAudioHandler} style={styles.bottomBarButton} />}

          {cameraInfo.hasSpeaker && 
            <Button ref={btnPTT} disabled={loadingVideo || !live.playing} icon={live.micro ? <MicroActiveIcon/> :<MicroMuteIcon />} 
              onClick={() => { }} onMouseDown={microButtonOnMouseDownUpHandler} onMouseUp={microButtonOnMouseDownUpHandler}
              onMouseLeave={microButtonOnMouseLeaveHandler} style={styles.bottomBarButton} />}

        </div>
      </div>
      {(loadingVideo || loadingAudio || loadingPtz || loadingPTT) && <h4>Cargando...</h4>}
      {(live.lowFrames) && <h4>El servidor está tardando en responder...</h4>}
    </>
  );
}

export { liveInitState }