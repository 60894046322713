/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

const audioInfoInitState = {
  pttStreamId: null,
  lastSignalType: null,
  stream: null,

  container: null,
  pttButton: null,
  itemIds: [],

  connection: null,
  streamActive: false,
  audioCtx: null,
  audioSourceNode: null,
  recorder: null,
  numberOfPendingRequests: 0,
  numberOfCloseConnectionAttempts: 0,

  resampledInput: null,
  inputPcmData: null,
  output16Bit: null,
  outputByteArray: null,
  resamplerRatio: null,
};

const microphoneSampleRate = 48000;
export default function useVmsPTT({ btnPTT, cameraInfo, vmsSession }) {
  const [loading, setLoading] = useState(false);
  const audioInfo = useRef(audioInfoInitState);

  const getResampleRatio = () => {
    const desiredSampleRate = 8000;
    const rawSampleRatio = microphoneSampleRate / desiredSampleRate;
    const roundedSampleRatio = Math.floor(rawSampleRatio);

    return roundedSampleRatio;
  };

  const getOutputSampleRate = () => {
    const resampleRatio = getResampleRatio();
    const outputSampleRate = microphoneSampleRate / resampleRatio;

    return outputSampleRate;
  };

  const onError = (error) => {
    // console.log('Error occured');
    console.error(error);
    audioInfo.current.streamActive = false;
  };

  const handleErrorCode = (errorCode, ajaxRequest) => {
    if (
      errorCode ===
        vmsSession.XPMobileSDK.library.ConnectionError.InsufficientUserRights ||
      errorCode ===
        vmsSession.XPMobileSDK.library.ConnectionError.ItemNotPlayable ||
      errorCode ===
        vmsSession.XPMobileSDK.library.ConnectionError.SurveillanceServerDown
    ) {
      stopPtt(true);
      audioInfo.current.connection = null;

      onError({
        code: errorCode,
        ajaxRequest: ajaxRequest,
      });
    }
  };

  const tryClosingConnection = (forceStop) => {
    audioInfo.current.numberOfCloseConnectionAttempts++;

    if (
      forceStop ||
      audioInfo.current.numberOfPendingRequests <= 0 ||
      audioInfo.current.numberOfCloseConnectionAttempts > 10
    ) {
      audioInfo.current.numberOfPendingRequests = 0;
      audioInfo.current.numberOfCloseConnectionAttempts = 0;

      audioInfo.current.connection.close();

      return;
    }

    setTimeout(() => {
      tryClosingConnection();
    }, 500);
  };

  const onAudioPushConnection = (ajaxRequest) => {
    audioInfo.current.numberOfPendingRequests--;

    if (!audioInfo.current.streamActive) {
      return;
    }

    if (!!ajaxRequest.response && !!ajaxRequest.response.byteLength) {
      let streamInfo = new vmsSession.XPMobileSDK.library.AudioHeaderParser(
        ajaxRequest.response
      );

      if (!!streamInfo.errorCodes && !!streamInfo.errorCodes.length) {
        for (let a = 0; a < streamInfo.errorCodes.length; a++) {
          handleErrorCode(streamInfo.errorCodes[a]['code'], ajaxRequest);
        }
      }
    }
  };

  const onAudioPushConnectionError = (ajaxRequest) => {
    audioInfo.current.numberOfPendingRequests--;

    stopPtt(true);
    audioInfo.current.connection = null;

    onError(ajaxRequest);
  };

  const connect = () => {
    if (audioInfo.current.connection) return;

    let outputSampleRate = getOutputSampleRate();

    audioInfo.current.connection =
      vmsSession.XPMobileSDK.createAudioPushConnection(
        audioInfo.current.itemIds,
        outputSampleRate,
        onAudioPushConnection,
        onAudioPushConnectionError
      );
  };

  const onConnectionOpenSuccess = () => {
    const constraints = {
      audio: true,
      video: false,
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .catch(gotStreamError);
  };

  const onConnectionOpenError = (error) => {
    onError(error);
  };

  const startPtt = () => {
    if (!audioInfo.current.connection) {
      connect();
    }

    audioInfo.current.connection.open(
      onConnectionOpenSuccess,
      onConnectionOpenError
    );
    audioInfo.current.numberOfPendingRequests = 0;
    audioInfo.current.numberOfCloseConnectionAttempts = 0;

    audioInfo.current.streamActive = true;
  };

  const stopPtt = (forceStop) => {
    audioInfo.current.pttButton.classList.remove('active');
    audioInfo.current.pttButton.classList.add('mute');

    if (!audioInfo.current.streamActive) {
      return;
    }

    if (audioInfo.current.stream) {
      audioInfo.current.stream.getTracks().forEach((track) => {
        track.stop();
      });
      audioInfo.current.stream = null;
    }

    audioInfo.current.streamActive = false;

    if (audioInfo.current.audioSourceNode && audioInfo.current.recorder) {
      audioInfo.current.audioSourceNode.disconnect(audioInfo.current.recorder);
      audioInfo.current.recorder.disconnect(
        audioInfo.current.audioCtx.destination
      );
      audioInfo.current.audioSourceNode = null;
      audioInfo.current.recorder = null;
    }

    if (!!audioInfo.current.audioCtx && !!audioInfo.current.audioCtx.close) {
      audioInfo.current.audioCtx.close().catch(() => {});
    }

    tryClosingConnection(forceStop);
  };

  const gotStream = (newStream) => {
    if (!audioInfo.current.streamActive) {
      return;
    }

    audioInfo.current.stream = newStream;

    audioInfo.current.audioCtx = new AudioContext();

    var bufferSize = 2048; //256, 512, 1024, 2048, 4096, 8192, 16384

    audioInfo.current.audioSourceNode =
      audioInfo.current.audioCtx.createMediaStreamSource(
        audioInfo.current.stream
      );

    audioInfo.current.recorder =
      audioInfo.current.audioCtx.createScriptProcessor(bufferSize, 1, 1);
    audioInfo.current.recorder.onaudioprocess = recorderProcess;

    audioInfo.current.audioSourceNode.connect(audioInfo.current.recorder);
    audioInfo.current.recorder.connect(audioInfo.current.audioCtx.destination);
  };

  const gotStreamError = (error) => {
    stopPtt();

    onError(error);
  };

  const resampleInputData = (inputData) => {
    audioInfo.current.resamplerRatio = getResampleRatio();

    audioInfo.current.resampledInput = new Float32Array(
      inputData.length / audioInfo.current.resamplerRatio
    );

    for (
      var i = 0, r = 0;
      i < inputData.length;
      i++, r += audioInfo.current.resamplerRatio
    ) {
      audioInfo.current.resampledInput[i] = inputData[r];
    }
  };

  const recorderProcess = (event) => {
    if (!audioInfo.current.streamActive) {
      return;
    }

    audioInfo.current.numberOfPendingRequests++;

    audioInfo.current.inputPcmData = event.inputBuffer.getChannelData(0);

    resampleInputData(audioInfo.current.inputPcmData);
    audioInfo.current.output16Bit = convertFloat32ToInt16(
      audioInfo.current.resampledInput
    );
    audioInfo.current.outputByteArray = convertInt16toByteArray(
      audioInfo.current.output16Bit
    );

    audioInfo.current.connection.send(audioInfo.current.outputByteArray);
  };

  const convertInt16toByteArray = (buffer) => {
    let bufferLength = buffer.length;
    let outputBuffer = new Int8Array(bufferLength * 2);

    for (var a = 0, b = 0; a < bufferLength; a++, b += 2) {
      outputBuffer[b] = buffer[a] & 0xff;
      outputBuffer[b + 1] = buffer[a] >> 8;
    }

    return outputBuffer;
  };

  const convertFloat32ToInt16 = (buffer) => {
    let bufferLength = buffer.length;
    let outputBuffer = new Int16Array(bufferLength);

    while (bufferLength--) {
      outputBuffer[bufferLength] = Math.min(1, buffer[bufferLength]) * 0x7fff;
    }

    return outputBuffer;
  };

  const onButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const init = () => {
    setLoading(true);
    const speakers = cameraInfo.Items.filter((item) => {
      return item.Type === 'Speaker';
    });
    audioInfo.current = {
      ...audioInfoInitState,
      pttButton: btnPTT.current,
    };

    audioInfo.current.itemIds.push(speakers[0].Id);

    audioInfo.current.pttButton.addEventListener('mousedown', startPtt);
    audioInfo.current.pttButton.addEventListener('mouseup', stopPtt);
    audioInfo.current.pttButton.addEventListener('mouseleave', stopPtt);
    audioInfo.current.pttButton.addEventListener('click', onButtonClick);
    setLoading(false);
  };

  useEffect(() => {
    if (btnPTT.current && cameraInfo.hasSpeaker) {
      init();
    }
  }, [btnPTT]);
  return {
    loadingPTT: loading,
    initPTT: init,
  };
}
