import React, { useState, useEffect } from 'react';
import {
  Modal,
  Card,
  Typography,
  Button,
  Tabs,
  Descriptions,
  Spin,
} from 'antd';

import PageHeader from '../../headers/PageHeader';

import UploadButton from '../../botones/UploadButton';
import TablaCausas from './components/TablaCausas';
import HistorialCambios from './components/HistorialCambios';
import eventService from '../../../services/eventService';
import activitiesService from '../../../services/activitiesService';
import protocolosService from '../../../services/protocolosService';
import dayjs from 'dayjs';
import Causas from './components/Causas.json';

import InfoPrincipal from './components/editarEventoComponents/InfoPrincipal';
import useAuth from '../../../hooks/useAuth';
import Tabladocumentos from './components/editarEventoComponents/Tabladocumentos';
import { getUser } from '../../../utils/getUser';
import { RedoOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Item } = Descriptions;

export default function ModalEditarEvento({ open, setOpen, item }) {
  const [itemEvento, setItemEvento] = useState(null);
  const [, setSelectedLaction] = useState(null);
  const [ArrayCausas, setArrayCausas] = useState(null);
  const [protocolosAsignados, setProtocolosAsignados] = useState([]);
  const [refresh, setRefresh] = useState(false);
  // const [Causas, setCausas] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const { UsuariosList } = useAuth();

  const consultarEventoEnServidor = async (eventId) => {
    try {
      // Lógica para enviar la solicitud de edición del evento al servidor
      const response = await eventService.consultarEventoParticular(eventId);
      // Manejar la respuesta del servidor, si es necesario

      consultaCausas(response?.Item?.protocols || []);
      setItemEvento(response?.Item || null);
    } catch (error) {
      console.error('Error al editar el evento:', error);
      setOpen(false);
      //message.error('No se logro registrar falsa alarma');
      // Manejar errores, si es necesario
    }
  };

  const consultarprotocolosTipos = async () => {
    try {
      // Lógica para enviar la solicitud de edición del evento al servidor
      const response = await eventService.consultarTiposCausas();
      // Manejar la respuesta del servidor, si es necesario

      const organizedItems = {};

      response.Items.forEach((item) => {
        const causeType = item.cause.split('_')[0]; // Obtener la primera palabra de la clave 'cause'
        if (!organizedItems[causeType]) {
          organizedItems[causeType] = [];
        }
        organizedItems[causeType].push(item.cause);
      });
      //console.log(organizedItems);
      //  setCausas(organizedItems);
    } catch (error) {
      console.error('Error al editar el evento:', error);

      //message.error('No se logro registrar falsa alarma');
      // Manejar errores, si es necesario
    }
  };

  const consultaCausas = async (item) => {
    let nuevoJson = {};

    // Recorremos el array1 y creamos el nuevo JSON
    item.forEach((clave) => {
      if (Causas.hasOwnProperty(clave)) {
        nuevoJson[clave] = Causas[clave];
      }
    });

    setArrayCausas(nuevoJson);
  };

  const consultaProtocolos = async () => {
    try {
      const response = await protocolosService.consultarProtocolo();
      let data = response;

      const arrayEvents = data.Items.filter(
        (i) => i.eventId === item.id && i.state === 'Started'
      );
      setProtocolosAsignados(arrayEvents);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const items = [
    {
      key: '1',
      label: 'Información básica',
      children: (
        <InfoPrincipal
          setRefresh={setRefresh}
          refresh={refresh}
          item={item}
          protocolosSeleccionados={protocolosAsignados}
        />
      ),
    },
    {
      key: '2',
      label: 'Causas',
      children: (
        <TablaCausas itemsCausas={ArrayCausas || []} eventId={item.id} />
      ),
    },
    {
      key: '3',
      label: 'Extras',
      children: <TabExtras item={item} />,
    },
  ];
  const estadoFiltros = {
    InProgress: 'Ejecutando',
    FalseAlarm: 'Falsa alarma',
    Closed: 'Cerrado',
    Open: 'Abierto',
  };

  const onChange = (key) => {};

  useEffect(() => {
    // consultarEventoEnServidor();
    if (open) {
      consultarprotocolosTipos();
      consultarEventoEnServidor(item.id);
      setSelectedLaction(item.location);
      consultaProtocolos();
      //consultaCausas(item.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, refresh]);

  return (
    <Modal
      title=""
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={'90%'}
      footer={false}
      style={{ paddingTop: '20px' }}
    >
      <div style={{ marginTop: '20px', maxHeight: '900vh', overflowY: 'auto' }}>
        <PageHeader title={item.id} head="Evento">
          <Modal
            title="Historial de cambios"
            centered
            open={showHistory}
            onOk={() => setShowHistory(false)}
            onCancel={() => setShowHistory(false)}
            width={'50%'}
            footer={false}
            style={{ paddingTop: '20px' }}
          >
            <HistorialCambios open={showHistory} eventId={item.id} />
          </Modal>
          <Button onClick={() => setShowHistory(true)}>
            {' '}
            Ver historial de modificaciones
          </Button>
        </PageHeader>
        <div className="textAjust">
          <Card
            title="Información general del evento"
            loading={itemEvento === null}
          >
            {itemEvento ? (
              <>
                <div>
                  <Title level={4}>{itemEvento.title}</Title>
                </div>
                <Descriptions
                  title={''}
                  column={2}
                  style={{ marginTop: '2px', marginBottom: '2px' }}
                >
                  <Item label="Estado">
                    {estadoFiltros[itemEvento?.state] || 'Estación'}
                  </Item>
                  <Item label="Fecha de Creación">
                    {dayjs(itemEvento.createdAt).format(
                      ' DD/MMM/YYYY  hh:mm:ss'
                    )}
                  </Item>

                  <Item label="Ubicación">
                    {itemEvento?.location?.name || 'Estación'}
                  </Item>
                  <Item label="Tipo">
                    {itemEvento?.location?.type || 'Estación'}
                  </Item>
                  <Item label="Zona">
                    {itemEvento?.location?.zone || 'Estación'}
                  </Item>
                  <Item label="Medio">
                    {itemEvento?.location?.means || 'Estación'}
                  </Item>
                  <Item label="Protocolos">{item.protocols.join(', ')}</Item>

                  <Item label="Funte Informacion">
                    {itemEvento?.source || ''}
                  </Item>
                  <Item label="Impacto">{itemEvento?.impact || ''}</Item>

                  <Item label="Creado por">
                    {getUser(itemEvento.createdBy, UsuariosList)}
                  </Item>
                </Descriptions>
              </>
            ) : (
              <Spin />
            )}
          </Card>
        </div>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </Modal>
  );
}

const TabExtras = ({ item }) => {
  const [, setKeyDocument] = useState(item?.cost?.report || '');

  const [documentos, setDocumentos] = useState(null);
  const [open, setOpen] = useState(false);
  const [documentosProtocolo, setDocumentosProtocolo] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const consultarDocumentosS3 = async (eventId) => {
    setDocumentos(null);
    setDocumentosProtocolo(null);

    try {
      // Lógica para enviar la solicitud de edición del evento al servidor
      const response = await activitiesService.consultardocumento(eventId);
      // Manejar la respuesta del servidor, si es necesario

      setDocumentos(response?.data?.extras || []);

      const agresionValues = Object.values(response.data[item.protocols[0]]);

      // Unificar los arrays de objetos en uno solo
      const unifiedArray = agresionValues.reduce(
        (accumulator, currentValue) => {
          return accumulator.concat(currentValue);
        },
        []
      );
      setDocumentosProtocolo(unifiedArray || []);
    } catch (error) {
      console.error('Error al editar el evento:', error);
    }
  };

  useEffect(() => {
    // consultarEventoEnServidor();

    consultarDocumentosS3(item.id);

    //consultaCausas(item.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <div>
        <div className="md:flex justify-between items-center">
          <div>
            <Title level={4}>Documentos cargados</Title>
          </div>
          <div>
            <Button
              onClick={() => setRefresh(!refresh)}
              icon={<RedoOutlined />}
            ></Button>
            <Button onClick={() => setOpen(true)}>Añadir Documentos</Button>
          </div>
        </div>
      </div>

      <Modal
        title="Cargar documentos Extra "
        centered
        open={open}
        onCancel={() => setOpen(false)}
        width={'50%'}
        footer={false}
        style={{ paddingTop: '20px' }}
      >
        <UploadButton eventId={item.id} setKey={setKeyDocument} />
      </Modal>

      {documentos ? (
        <Tabladocumentos
          dataExtras={documentos}
          dataProtocol={documentosProtocolo}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ) : (
        <Spin />
      )}
    </>
  );
};
