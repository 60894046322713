import React from 'react';
import MapaMetro from '../../../components/map/MapaMetro';
//import MapaEjemplo from '../../../components/map/MapaEjemplo';

export default function TabMapa() {
  return (
    <div class="map">
      <MapaMetro />
      {/* <MapaEjemplo /> */}
    </div>
  );
}
