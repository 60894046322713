import React, { useState } from 'react';
import { Card, Modal, Row, Col, Image } from 'antd';
import doc from '../../../assets/images/5860.jpg';
// import IHREntryForm from './IHREntryForm'; // Importa el componente del formulario de IHR
// import EarlyAlertForm from './EarlyAlertForm'; // Importa el componente del formulario de Alertas Tempranas
// import IncidentReportForm from './IncidentReportForm'; // Importa el componente del formulario de Reporte de Incidencias
function ModalCrearAlarma({ open, setOpen }) {
  const [isIHROpen, setIsIHROpen] = useState(false);
  const [isEarlyAlertOpen, setIsEarlyAlertOpen] = useState(false);
  const [, setIsIncidentReportOpen] = useState(false);

  const handleCardClick = (formType) => {
    switch (formType) {
      case 'Iniciativa de Acompañamiento':
        setIsIHROpen(true);
        break;
      case 'EarlyAlert':
        setIsEarlyAlertOpen(true);
        break;
      case 'IncidentReport':
        setIsIncidentReportOpen(true);
        break;
      default:
        break;
    }
  };

  const handleCloseModals = () => {
    setIsIHROpen(false);
    setIsEarlyAlertOpen(false);
    setIsIncidentReportOpen(false);
  };
  const CloseModals = () => {
    setOpen(false);
  };

  return (
    <Modal
      title="Formularios"
      visible={open}
      onCancel={CloseModals}
      footer={null}
      width={'50%'}
    >
      <div>
        <Row gutter={10}>
          <Col span={12}>
            <Card
              title=""
              onClick={() => handleCardClick('Iniciativa de Acompañamiento')}
            >
              {/* Contenido opcional de la carta si lo deseas */}
              Iniciativa de Acompañamiento
            </Card>
          </Col>
          <Col span={12}>
            <Card title="" onClick={() => handleCardClick('EarlyAlert')}>
              Alertas Tempranas
              {/* Contenido opcional de la carta si lo deseas */}
            </Card>
          </Col>
        </Row>

        <Modal
          title="Formulario Iniciativa de Acompañamiento"
          visible={isIHROpen}
          onCancel={handleCloseModals}
          footer={null}
        >
          <iframe
            title="contador falssa alarma"
            src="https://forms.office.com/Pages/ResponsePage.aspx?id=B7r2hdyK102WjlJV3KChbHTC-lXMo8dKq7S-rAju4ipUMFFNME1QUlpWS1FFSEQxU0JKSVVIS1dGUC4u"
            width="100%"
            height="800px"
            frameborder="0"
          ></iframe>
          {/* <Image src={doc} preview={false} /> */}
        </Modal>

        <Modal
          title="Formulario de Alertas Tempranas"
          visible={isEarlyAlertOpen}
          onCancel={handleCloseModals}
          footer={null}
        >
          <Image src={doc} preview={false} />
        </Modal>
      </div>
    </Modal>
  );
}

export default ModalCrearAlarma;
