import React, { useState, useEffect } from 'react';
import { Table, Card, Button, message } from 'antd';
import { UserOutlined } from '@ant-design/icons/lib/icons';
import eventService from '../../../../services/eventService';
import ModalCrearImplicados from './ModalCrearImplicados';

function TablaCausas({ itemsCausas, eventId }) {
  return (
    <div>
      {Object.keys(itemsCausas).map((llave) => (
        <CustomTable
          eventId={eventId}
          key={llave}
          title={llave}
          dataSource={itemsCausas[llave].map((elemento, index) => ({
            key: index,
            elemento,
          }))}
        />
      ))}
    </div>
  );
}

const CustomTable = ({ title, dataSource, eventId }) => {
  const [causasImplicadas, setCausasSeleccionadas] = useState([]);

  const [causasEvento, setCausasEvento] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [LoadingAsigna, setLoadingAsignar] = useState(false);
  const [LoadingEliminar, setLoadingEliminar] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [eventosPorAsignar, setEventoPorAsignar] = useState([]);
  const [eventosPorEliminar, setEventoPorEliminar] = useState([]);
  const columns = [
    {
      title: 'Elementos',
      dataIndex: 'elemento',
      key: 'elemento',
    },
    {
      title: 'Numero de implicados',
      dataIndex: 'involved',
      key: 'involved',
      render: (text) => (
        <>
          {text.length || 0} <UserOutlined />
        </>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: '',
      key: '',
      render: (record) => (
        <ModalCrearImplicados eventId={eventId} item={record} />
      ),
    },
  ];

  const columnsA = [
    {
      title: 'Elementos',
      dataIndex: 'elemento',
      key: 'elemento',
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setEventoPorEliminar(selectedRows);
    },
  };
  const rowSelectionImplicados = {
    onChange: (selectedRowKeys, selectedRows) => {
      setEventoPorAsignar(selectedRows);
    },
  };

  const consultaCausas = async (eventId = null) => {
    setLoading(true);
    try {
      // Lógica para enviar la solicitud de edición del evento al servidor
      const response = await eventService.consultarCausas(eventId);
      // console.log(response.Items);

      function filtrarPorProtocolCause(data, palabraClave) {
        return data.filter((item) =>
          item.protocol_cause.startsWith(palabraClave)
        );
      }

      const resultadoFiltrado = filtrarPorProtocolCause(response.Items, title);

      const newArray = resultadoFiltrado
        .filter((obj) => obj.eventId === eventId)
        .map((obj, index) => ({
          key: index,
          elemento: obj.protocol_cause,
          involved: obj.involved,
        }));

      setCausasSeleccionadas(newArray);

      if (newArray.length !== 0) {
        const filteredDataSource = dataSource.filter(
          (item) =>
            !newArray.some((newItem) => newItem.elemento === item.elemento)
        );

        setCausasEvento(filteredDataSource);
      } else {
        setCausasEvento(dataSource);
      }
    } catch (error) {
      console.error('Error al editar el evento:', error);
    }
    setLoading(false);
  };

  const asignarCausasList = async () => {
    setLoadingAsignar(true);
    try {
      // Use map to create an array of promises
      const promises = eventosPorAsignar.map(async (item, index) => {
        const registro = { protocol_cause: item.elemento };
        await eventService.AsignarCausas(eventId, registro);
      });

      // Use Promise.all to wait for all promises to resolve or any to reject
      await Promise.all(promises);

      // All promises have been fulfilled

      message.success(
        `Todas las causas del protocolo ${title} han sido asignadas correctamente`
      );
      setEventoPorAsignar([]);
      setRefresh(!refresh);
    } catch (error) {
      console.error('Error al asignar causas:', error);
      message.error('No se logro hacer la solicitud');
      // Handle error as needed
    }
    setLoadingAsignar(false);
  };

  const eliminarCausasList = async () => {
    setLoadingEliminar(true);
    try {
      // Use map to create an array of promises
      const promises = eventosPorEliminar.map(async (item, index) => {
        const registro = { protocol_cause: item.elemento };
        await eventService.EliminarCausa(eventId, registro);
      });

      // Use Promise.all to wait for all promises to resolve or any to reject
      await Promise.all(promises);

      // All promises have been fulfilled
      message.success(
        `Todas las causas del protocolo ${title} han sido eliminadas correctamente`
      );

      setEventoPorEliminar([]);
      setRefresh(!refresh);
    } catch (error) {
      console.error('Error al asignar causas:', error);
      message.error('No se logro hacer la solicitud');

      // Handle error as needed
    }
    setLoadingEliminar(false);
  };

  useEffect(() => {
    consultaCausas(eventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <div>
      <h2>{title}</h2>
      <div className="contenedortipoA">
        <div className="columna">
          <Card
            title="Causas"
            extra={
              <Button
                type="primary"
                onClick={() => asignarCausasList()}
                disabled={eventosPorAsignar.length === 0}
                loading={LoadingAsigna}
              >
                Asignar causa
              </Button>
            }
          >
            <Table
              dataSource={causasEvento}
              columns={columnsA}
              loading={Loading}
              rowKey={'elemento'}
              rowSelection={{
                type: 'checkbox',
                ...rowSelectionImplicados,
              }}
            />
          </Card>
        </div>
        <div className="columna">
          {' '}
          <Card
            title="Causas Asignadas"
            extra={
              <Button
                type="primary"
                onClick={() => eliminarCausasList()}
                disabled={eventosPorEliminar.length === 0}
                loading={LoadingEliminar}
              >
                Eliminar causa
              </Button>
            }
          >
            <Table
              dataSource={causasImplicadas}
              columns={columns}
              loading={Loading}
              rowKey={'elemento'}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
export default TablaCausas;
