import React, { useState, useEffect } from 'react';
import { Modal, Form, Calendar, ConfigProvider, Button, Skeleton } from 'antd';
import esES from 'antd/es/locale/es_ES'; // Importa el idioma español desde Ant Design
import 'moment/locale/es'; // Importa las configuraciones de idioma español para moment.js
import ModalCalendarioForm from './ModalCalendarioForm';
import PageHeader from '../../headers/PageHeader';
import remindersService from '../../../services/remindersService';
import { PlusCircleOutlined, WarningOutlined } from '@ant-design/icons';
import BotonEliminar from '../../../pages/dashboard/components/BotonEliminar';
import dayjs from 'dayjs';

const ModalCalendario = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [eventosCalendario, setEventosCalendario] = useState(null);

  const [endDate, setEndDate] = useState(
    dayjs().add(30, 'days').format('YYYY-MM-DD')
  ); // Fecha actual en formato "YYYY-MM-DD"
  const [startDate, setDtartDate] = useState(
    dayjs().subtract(30, 'days').format('YYYY-MM-DD')
  );

  const handleOk = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      // Realiza una acción para guardar la configuración de perfil, por ejemplo, enviarla a una API.
      setLoading(false);
      onCancel(); // Cierra el modal después de guardar la configuración.
    } catch (error) {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setRefresh(!refresh);
  };

  useEffect(() => {
    // Función para realizar la consulta al calendario

    // Llamar a la función de consulta inicialmente
    setEventosCalendario(null);
    if (visible) consultaCalendario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, visible]);

  const consultaCalendario = async () => {
    try {
      const response = await remindersService.ObtenerEventosCalendario(
        startDate,
        endDate
      );
      let data = response;

      // console.log(data?.Items || []);
      setEventosCalendario(data?.Items || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <Modal
      title=""
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={loading}
      centered
      footer={false}
      width={'100%'}
    >
      <PageHeader>
        <Button
          onClick={() => setOpen(true)}
          type="primary"
          icon={<PlusCircleOutlined />}
          loading={open}
        >
          Registrar Novedad
        </Button>
      </PageHeader>

      <ModalCalendarioForm visible={open} onClose={closeModal} />
      {eventosCalendario ? (
        <Calendario
          datos={eventosCalendario}
          consultaCalendario={consultaCalendario}
        />
      ) : (
        <Skeleton />
      )}
    </Modal>
  );
};

export default ModalCalendario;

const Calendario = ({ datos, consultaCalendario }) => {
  const eventosFormateados = datos.map((evento) => ({
    title: evento.name,
    date: new Date(evento.date),
  }));
  const eventsData = {};

  datos.forEach((reminder) => {
    const date = new Date(reminder.date).toISOString().split('T')[0]; // Obtenemos solo la parte de la fecha
    if (!eventsData[date]) {
      eventsData[date] = [
        { type: 'event', content: reminder.name, id: reminder.id },
      ];
    } else {
      eventsData[date].push({
        type: 'event',
        content: reminder.name,
        id: reminder.id,
      });
    }
  });

  const dateCellRender = (value) => {
    const dateString = value.format('YYYY-MM-DD');
    const events = eventsData[dateString];

    return (
      <div>
        {events &&
          events.map((event, index) => (
            <div key={index} className={`event-${event.type}`}>
              <Button
                type="text"
                icon={<WarningOutlined style={{ color: 'yellow' }} />}
              >
                {' '}
                {event.content}
              </Button>
              <BotonEliminar
                record={event}
                funtionRefresh={consultaCalendario}
              />
            </div>
          ))}
      </div>
    );
  };

  if (!eventosFormateados.length) {
    return <div>No hay eventos para mostrar</div>;
  }

  return (
    <ConfigProvider locale={esES}>
      <Calendar dateCellRender={dateCellRender} />
    </ConfigProvider>
  );
};
