import { Navigate, Outlet } from 'react-router-dom';
import Application from '../components/layouts/Application';
import useAuth from '../hooks/useAuth';

export default function PrivateProvider() {
  const { session } = useAuth();
  if (!session.user) return <Navigate to="/auth" replace />;
  return (
    <Application>
      <Outlet />
    </Application>
  );
}
