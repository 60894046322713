import React, { useState } from 'react';
import MapaMetro from '../../../components/map/MapaMetro';
import TablaAlarmas from './TablaAlarmas';

import Contadores from '../../../components/Grafana/Contadores';
// import MapaEjemplo from '../../../components/map/MapaEjemplo';
import { Image, Card, Badge, Alert } from 'antd';

import panelImg from '../../../assets/mockUps/imgpr.png';

const token = 'glsa_XFv996aTy5QVHeSB8mohKwn6QsosKcC5_3fc341d6';
const grafanaUrl = 'https://grafana-monitoreo.cisemetrodemedellin.co:3000';

export default function TabPrincipal() {
  const [contador, setContador] = useState(0);
  const [sslActive] = useState(process.env.REACT_APP_SSL || true);

  return (

    <>
    

    <>
      <div className="textAjust">
        <div class="contenedor">
          <div class="columna">
            <Alert
              style={{ marginBottom: '10px' }}
              message="Información importante."
              description="Los datos visualizados corresponden a los últimos 15 días. Para ver información anterior a esta, diríjase a Reportes."
              type="info"
              showIcon
            />
            {/* {sslActive !== 'false' ? (
              <>
                <div class="iframe-container">
                  <iframe
                    title="contador todos"
                    src={`https://grafana-monitoreo.cisemetrodemedellin.co:3000/d-solo/cd9a2390-9613-439e-8d57-6b05bd34c8a4/dashboard?orgId=1&from=1701784738673&to=1701806338674&theme=dark&panelId=11&token=${token}`}
                    width="100%"
                    height="150"
                    frameborder="0"
                  ></iframe>
                </div>

                <div class="iframe-row">
                  <iframe
                    title="contador Abiertos"
                    src={`${grafanaUrl}/d-solo/cd9a2390-9613-439e-8d57-6b05bd34c8a4/dashboard?orgId=1&refresh=1m&from=1699710212934&to=1699731812934&panelId=${7}&token=${token}`}
                    width="25%"
                    height="150"
                    frameborder="0"
                  ></iframe>
                  <iframe
                    title="contador Ejecutando"
                    src={`${grafanaUrl}/d-solo/cd9a2390-9613-439e-8d57-6b05bd34c8a4/dashboard?orgId=1&refresh=30m&from=1699900844429&to=1699922444429&theme=dark&panelId=${12}&token=${token}`}
                    width="25%"
                    height="150"
                    frameborder="0"
                  ></iframe>
                  <iframe
                    title="contador Falsa alarma"
                    src={`${grafanaUrl}/d-solo/cd9a2390-9613-439e-8d57-6b05bd34c8a4/dashboard?orgId=1&from=1701806974661&to=1701828574661&panelId=${10}&token=${token}`}
                    width="25%"
                    height="150"
                    frameborder="0"
                  ></iframe>
                  <iframe
                    title="contador Cerrados"
                    src={`${grafanaUrl}/d-solo/cd9a2390-9613-439e-8d57-6b05bd34c8a4/dashboard?orgId=1&refresh=1m&from=1699710338283&to=1699731938283&panelId=${8}&token=${token}`}
                    width="25%"
                    height="150"
                    frameborder="0"
                  ></iframe>
                </div>
              </>
            ) : (
              <Image
                preview={false}
                src={panelImg}
                width={'100%'}
                height={'400px'}
              ></Image>
            )} */}
            <Contadores/>

            <div>
              <Card
                title={
                  <Badge count={contador} offset={[14, 9]}>
                    Alarmas del sistema
                  </Badge>
                }
              >
                <TablaAlarmas setContador={setContador}/>
              </Card>
            </div>
          </div>

          <div class="columnaP">
            <MapaMetro />
            {/* <MapaEjemplo /> */}
          </div>
        </div>
      </div>
      <div className="componentWeb">
        <div class="columna">
          <Alert
            style={{ marginBottom: '10px' }}
            message="Información importante."
            description="Los datos visualizados corresponden a los últimos 15 días. Para ver información anterior a esta, diríjase a Reportes."
            type="info"
            showIcon
          />
          {/* {sslActive !== 'false' ? (
            <>
              <div class="iframe-container">
                <iframe
                  title="contador todos"
                  src={`https://grafana-monitoreo.cisemetrodemedellin.co:3000/d-solo/cd9a2390-9613-439e-8d57-6b05bd34c8a4/dashboard?orgId=1&from=1701784738673&to=1701806338674&theme=dark&panelId=11&token=${token}`}
                  width="100%"
                  height="150"
                  frameborder="0"
                ></iframe>
              </div>

              <div class="iframe-row">
                <iframe
                  title="contador Abiertos"
                  src={`${grafanaUrl}/d-solo/cd9a2390-9613-439e-8d57-6b05bd34c8a4/dashboard?orgId=1&refresh=1m&from=1699710212934&to=1699731812934&panelId=${7}&token=${token}`}
                  width="25%"
                  height="150"
                  frameborder="0"
                ></iframe>
                <iframe
                  title="contador Ejecutando"
                  src={`${grafanaUrl}/d-solo/cd9a2390-9613-439e-8d57-6b05bd34c8a4/dashboard?orgId=1&refresh=30m&from=1699900844429&to=1699922444429&theme=dark&panelId=${12}&token=${token}`}
                  width="25%"
                  height="150"
                  frameborder="0"
                ></iframe>
                <iframe
                  title="contador Falsa alarma"
                  src={`${grafanaUrl}/d-solo/cd9a2390-9613-439e-8d57-6b05bd34c8a4/dashboard?orgId=1&from=1701806974661&to=1701828574661&panelId=${10}&token=${token}`}
                  width="25%"
                  height="150"
                  frameborder="0"
                ></iframe>
                <iframe
                  title="contador Cerrados"
                  src={`${grafanaUrl}/d-solo/cd9a2390-9613-439e-8d57-6b05bd34c8a4/dashboard?orgId=1&refresh=1m&from=1699710338283&to=1699731938283&panelId=${8}&token=${token}`}
                  width="25%"
                  height="150"
                  frameborder="0"
                ></iframe>
              </div>
            </>
          ) : (
            <Image
              preview={false}
              src={panelImg}
              width={'100%'}
              height={'400px'}
            ></Image>
          )} */}
          <Contadores/>

          <div>
            <Card
              title={
                <Badge count={contador} offset={[14, 9]}>
                  Alarmas del sistema
                </Badge>
              }
            >
              <TablaAlarmas setContador={setContador}/>
            </Card>
          </div>
        </div>
      </div>
    </>
    </>
    
  );
}
