import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

import activitiesService from '../../services/activitiesService';
import useAuth from '../../hooks/useAuth';
import AWS from 'aws-sdk';

const { Dragger } = Upload;

const s3 = new AWS.S3({
  // Configura las credenciales y la región de AWS
  accessKeyId: process.env.REACT_APP_KEY || 'no hay nada',
  secretAccessKey: process.env.REACT_APP_KEYS || 'no hay nada',
  region: 'us-east-1',
});

const UploadButton = ({
  eventId,
  setKey,
  tipo = 'normal',
  extras = true,
  protocol = '',
  activity = '',
}) => {
  const { session } = useAuth();

  const props = {
    name: 'file',
    multiple: true,
    customRequest: async ({ onSuccess, onError, file }) => {
      try {
        // Configura los parámetros para la carga en S3

        const partes = file.name.split('.');
        const extension = partes[partes.length - 1];

        let keyDocument = '';

        if (extras) {
          if (tipo !== 'saldo') {
            keyDocument = 'eventdocuments/' + eventId + '/extras/' + file.name;
          } else {
            keyDocument = `eventdocuments/${eventId}/extras/soporteCosto.${extension}`;
          }
        } else {
          keyDocument = `eventdocuments/${eventId}/${protocol}/${activity}/${file.name}`;
        }

        const params = {
          Bucket: 'documents-event-metro',
          Key: keyDocument,
          Body: file,
        };

        //Realiza la carga en S3
        const data = await s3.upload(params).promise();
        setKey(data?.key || null);

        let registro = {
          eventId: eventId,
          protocol_activity_subactivity: extras ? 'extras' : activity,
          userId: session?.user?.id || session?.user?.sub,
          path: data?.key,
        };
        asignarUrls(registro);

        onSuccess();
      } catch (error) {
        onError(error);
        message.error(`${file.name} fallo al subir el archivo.`);
      }
    },
    beforeUpload: (file) => {
      // Validar el tipo de archivo (solo imágenes y PDF)

      const isImageOrPdf =
        file.type.startsWith('image/') ||
        file.type === 'application/pdf' ||
        file.type === 'application/xlsl' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isImageOrPdf) {
        message.error(
          'Solo se permiten archivos de tipo imagen (JPG, PNG), xlxs o PDF.'
        );
        return Upload.LIST_IGNORE;
      }

      // Validar el tamaño del archivo (menos de 50 MB)
      const isSizeValid = file.size / (1024 * 1024) < 50; // Cambio aquí
      if (!isSizeValid) {
        message.error('El tamaño del archivo no puede superar los 50 MB.'); // Cambio aquí
        return Upload.LIST_IGNORE;
      }

      // Si pasa ambas validaciones, devuelve true para permitir la carga del archivo
      return true;
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} archivo subido con éxito.`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} fallo al subir el archivo.`);
      }
    },
  };

  const asignarUrls = async (registro) => {
    try {
      await activitiesService.funcionCargarS3(registro);
      message.success('Documento registrado');
    } catch (error) {
      console.error(error);
      message.error('No se logro hacer la consulta');
    }
  };

  return (
    <Dragger {...props} style={{ marginTop: '20px' }} showUploadList={false}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Haz Click para Adjuntar Archivos</p>

      <p className="ant-upload-hint">
        "El archivo no debe superar los 50 MB y solo puede ser de tipo imagen,
        PDF o Excel."
      </p>
    </Dragger>
  );
};

export default UploadButton;
