import React, { useRef, useEffect, useState } from 'react';
import { loadModules } from 'esri-loader';
import ModalCamaras from '../modales/camaras/ModalCamaras';
import ModalMarkersEvents from './ModalMarkersEvents';
import extraService from '../../services/extrasService';
import useAuth from '../../hooks/useAuth';
import { Card, Checkbox, Select } from 'antd';

export default function MapaMetro() {
  const screenWidth = window.innerWidth;
  const mapContainer = useRef(null);
  const [apiData, setApiData] = useState([]);
  const [EventosMarkers, setEventosMarkers] = useState([]);
  const [showModalEventos, setShowModalEventos] = useState(false);
  const [showCameras] = useState(false);
  const [showEventos, setShowEventos] = useState(true);
  const [showZonas, setShowZonas] = useState(false);
  const [showBandas, setShowBandas] = useState(false);
  const [showLineas, setShowLineas] = useState(false);
  const [camaraModal, setCamaraModal] = useState([]);
  const [datosModal, setDatosModales] = useState({});
  const { eventos, Estaciones, session } = useAuth();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [totalEvents, setTotalEvents] = useState(eventos || []);
  const consultar = async () => {
    setApiData([]);
    try {
      const response = await extraService.consultarCamaras();
      // Lógica para ajustar el array
      setApiData(response?.camaras || []);
      setShowLineas(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const encontrarPorObjectId = (objectId) => {
    return Estaciones.find(
      (estacion) => estacion.attributes.objectid === objectId
    );
  };

  const bucarObjetos = (items) => {
    if (items) {
      if (items.total_combos) {
      } else {
        if (items.objectid) {
          consultar();
          let objDatos = encontrarPorObjectId(items.objectid);

          setDatosModales(objDatos);

          const estacionesFiltradas = apiData.filter(
            (estacion) =>
              estacion.attributes.estacion ===
              objDatos.attributes.alias_estacion
          );
          setCamaraModal(estacionesFiltradas);
          setSelectedMarker(true);
        } else {
          const eventosFiltrados = eventos.filter(
            (evento) => evento.location.name === items.location.name
          );

          setEventosMarkers(eventosFiltrados || []);
          setShowModalEventos(true);
        }
      }
    }
  };

  useEffect(() => {
    //authenticateArcGIS();
    consultar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //setTotalEvents(eventos);
    loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/Graphic',
      'esri/layers/FeatureLayer',
      'esri/identity/IdentityManager',
      'esri/PopupTemplate',
    ]).then(([Map, MapView, Graphic, FeatureLayer, esriId, PopupTemplate]) => {
      // Crear un nuevo mapa y vista
      const map = new Map({
        basemap: 'streets',
      });

      const username = 'tu_nombre_de_usuario';

      // Agregar credenciales al administrador de identidad de ArcGIS
      esriId.registerToken({
        server: 'https://metrogis.metrodemedellin.gov.co/server/rest/services',
        token: session.tokenArgis, // Puedes obtener un token mediante el servicio de autenticación de ArcGIS
        userId: username,
      });

      // Crear una capa de entidad
      const featureLayer4 = new FeatureLayer({
        url: 'https://metrogis.metrodemedellin.gov.co/server/rest/services/Cise/ZonasSeguridadFisica/MapServer/2',
      });

      const featureLayer5 = new FeatureLayer({
        url: 'https://metrogis.metrodemedellin.gov.co/server/rest/services/Cise/ZonasSeguridadFisica/MapServer/3',
      });

      const view = new MapView({
        container: mapContainer.current,
        map: map,
        center: [-75.56359, 6.25184], // Coordenadas del centro del mapa
        zoom: 12, // Nivel de zoom inicial
      });

      if (showZonas) {
        map.add(featureLayer4);
      }
      if (showBandas) {
        map.add(featureLayer5);
      }
      if (showEventos && totalEvents.length > 0) {
        totalEvents.forEach((item) => {
          // const point = {
          //   type: 'point',
          //   x: item?.location?.geolocation?.lat || 75.26516,
          //   y: item?.location?.geolocation?.lon || 6.304512,
          //   spatialReference: { wkid: 102768 },
          // };

          const point = {
            type: 'point',
            latitude: item?.location?.geolocation?.lat || 75.26516,
            longitude: item?.location?.geolocation?.lon || 6.304512,
          };

          const markerSymbol = {
            type: 'simple-marker',
            color: [255, 255, 255], // Blanco
            size: 15,
          };

          const graphic = new Graphic({
            geometry: point,
            symbol: markerSymbol,
            attributes: item,
          });
          view.graphics.add(graphic);
        });
      }

      if (showLineas) {
        const featureLayer2 = new FeatureLayer({
          url: 'https://metrogis.metrodemedellin.gov.co/server/rest/services/Cise/Sistema_Metro/MapServer/2',
          // url: 'https://metrogis.metrodemedellin.gov.co/server/rest/services/Cise/Sistema_Metro/MapServer/2',
        });

        map.add(featureLayer2);

        const popupTemplate1 = new PopupTemplate({
          title: '{Nombre}', // Reemplaza 'Nombre' con el nombre del campo que deseas mostrar como título
          content: '{Descripcion}', // Reemplaza 'Descripcion' con el nombre del campo que deseas mostrar como contenido
        });

        const featureLayer1 = new FeatureLayer({
          url: 'https://metrogis.metrodemedellin.gov.co/server/rest/services/Cise/Sistema_Metro/MapServer/1',
          outFields: ['Nombre', 'Descripcion'], // Especifica los campos que deseas mostrar en el popup
          popupTemplate: popupTemplate1,
        });
        const featureLayer0 = new FeatureLayer({
          url: 'https://metrogis.metrodemedellin.gov.co/server/rest/services/Cise/Sistema_Metro/MapServer/0',
        });

        map.add(featureLayer0);

        map.add(featureLayer1);

        view.when(() => {
          // Asegúrate de que el FeatureLayer1 esté cargado antes de agregar el evento mouse-over
          view.whenLayerView(featureLayer1).then((layerView) => {
            // Agrega el evento mouse-over al FeatureLayer1
            view.on('pointer-move', (event) => {
              view.hitTest(event).then((response) => {
                const graphic = response.results[0]?.graphic;
                if (graphic && graphic.layer === featureLayer1) {
                  // Muestra el Popup cuando el ratón pasa sobre el FeatureLayer1
                  view.popup.open({
                    features: [graphic],
                    location: event.mapPoint,
                  });
                } else {
                  // Cierra el Popup cuando el ratón no está sobre el FeatureLayer1
                  view.popup.close();
                }
              });
            });
          });

          // ... (otro código)
        });
      }

      view.when(() => {
        view.on('click', (event) => {
          view.hitTest(event).then((response) => {
            if (response.results && response.results.length > 0) {
              const graphic = response.results[0].graphic;
              if (graphic) {
                bucarObjetos(graphic.attributes);
              } else {
                bucarObjetos(null);
              }
            } else {
              bucarObjetos(null);
            }
          });
        });
      });
    });
    // Cargar los módulos necesarios de ArcGIS

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalEvents,
    eventos,
    showCameras,
    showEventos,
    showLineas,
    showZonas,
    showBandas,
  ]);

  const closeModal = () => {
    setSelectedMarker(null);
  };

  const handleChange = (value) => {
    if (value === 0) {
      setTotalEvents(eventos);
    } else {
      const objetosFiltrados = eventos.filter(
        (objeto) => objeto.location.zone === value
      );

      setTotalEvents(objetosFiltrados || []);
    }
  };
  return (
    <>
      {apiData && (
        <div style={{ height: '100%', width: '100%' }} ref={mapContainer}>
          {selectedMarker && (
            <ModalCamaras
              items={datosModal}
              camaras={camaraModal}
              close={closeModal}
            />
          )}

          <ModalMarkersEvents
            item={EventosMarkers}
            open={showModalEventos}
            setOpen={setShowModalEventos}
          />

          <Select
            style={{
              position: 'absolute',
              top: '20px',
              left: `${screenWidth - screenWidth / 2}px`,
              padding: '5px',
              textAlign: 'left', // Alinea el texto a la izquierda dentro del Card
              width: 120,
            }}
            defaultValue="Todos"
            onChange={handleChange}
            options={[
              { value: 0, label: 'Todos' },
              { value: 1, label: 'Zona 1' },
              { value: 2, label: 'Zona 2' },
              { value: 3, label: 'Zona 3' },
              { value: 4, label: 'Zona 4' },
              { value: 5, label: 'Zona 5' },
              { value: 6, label: 'Zona 6' },
            ]}
          />

          <Card
            style={{
              position: 'absolute',
              top: '20px',
              left: `${screenWidth - 250}px`,
              padding: '5px',
              textAlign: 'left', // Alinea el texto a la izquierda dentro del Card
            }}
            bodyStyle={{ padding: '5px 10px' }} // Ajusta el padding del Card Body
          >
            <Checkbox
              checked={showEventos}
              onChange={() => setShowEventos(!showEventos)}
            >
              Mostrar Eventos Abiertos
            </Checkbox>
            <Checkbox
              checked={showLineas}
              onChange={() => setShowLineas(!showLineas)}
            >
              Mostrar Lineas
            </Checkbox>
            <Checkbox
              checked={showBandas}
              onChange={() => setShowBandas(!showBandas)}
            >
              Comunas
            </Checkbox>
            <Checkbox
              checked={showZonas}
              onChange={() => setShowZonas(!showZonas)}
            >
              Mostrar Zonas RPCC
            </Checkbox>
          </Card>
          {/* {showZonas && (
            <Card
              style={{
                position: 'absolute',
                top: '300px',
                left: `${screenWidth - 250}px`,
                padding: '5px',
                textAlign: 'left', // Alinea el texto a la izquierda dentro del Card
              }}
            >
              <ul>
                {colorData.map((color, index) => (
                  <li key={index}>
                    <span
                      className="color"
                      style={{ backgroundColor: color.hex }}
                    ></span>
                    {color.name}
                  </li>
                ))}
              </ul>
            </Card>
          )} */}
        </div>
      )}
    </>
  );
}
