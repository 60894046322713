import { useState } from 'react';
// import { Storage } from 'aws-amplify';
import useAuth from '../../../hooks/useAuth';
import { Avatar, Button, Dropdown, Space } from 'antd';
import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import ModalPerfil from '../../modales/Perfil/ModalPerfil';
import ModalRoles from '../../modales/Perfil/ModalRoles';
// import ModalCalendario from '../../modales/calendario/ModalCalendario';

export default function NameDropdown() {
  const { logout, session } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);

  const [modalRole, setModalRole] = useState(false);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const items = [
    {
      key: '1',
      label: 'Mi Perfil',
      icon: <UserOutlined />,

      onClick: () => handleOpenModal(),
      disabled: false,
    },

    session.roles.users.isAllowedtoSee
      ? {
          key: '2',
          label: 'Ajustes de Usuarios',
          icon: <TeamOutlined />,
          onClick: () => setModalRole(true),
        }
      : null,

    // {
    //   key: '3',
    //   label: 'Calendario',
    //   icon: <CalendarOutlined />,
    //   onClick: () => setModalCalendar(true),
    // },

    {
      key: '4',
      label: 'Cerrar sesión',
      icon: <LogoutOutlined />,
      onClick: () => logout(),
    },
  ];

  return (
    <>
      <ModalPerfil
        visible={modalVisible}
        setVisible={setModalVisible}
        onCancel={handleCloseModal}
      />
      <ModalRoles open={modalRole} setOpen={setModalRole} />

      <Dropdown menu={{ items }}>
        <Button
          style={{
            height: '45px',
            padding: '5px',
          }}
          type="ghost"
        >
          <Space>
            <Avatar icon={<UserOutlined />} />
            <span className="hidden md:inline-block">
              {session?.user?.email || 'Nombre genérico'}
            </span>
            <DownOutlined className="opacity-50" />
          </Space>
        </Button>
      </Dropdown>
    </>
  );
}
