import { useEffect, useState } from 'react';

import useAuth from '../../../../hooks/useAuth';

import styles from './jsonStyles.json';

import LiveBar, { liveInitState } from './LiveBar';
import PlaybackBar, { playbackInitState } from './PlaybackBar';
import PtzControls from './PtzControls';

export default function Camera({ width, height, deviceId }) {
  const { vmsSession } = useAuth();
  const [cameraInfo, setCameraInfo] = useState(vmsSession.cameraList[deviceId]);
  const [live, setLive] = useState(liveInitState);
  const [playback, setPlayback] = useState(playbackInitState);

  useEffect(() => {
    if (
      vmsSession.isLogged &&
      !vmsSession.loadingData &&
      deviceId in vmsSession.cameraList
    ) {
      if (live.isLive) {
        setLive({ ...liveInitState, isLive: false, playing: false });
      }
      if (playback.isPlayback) {
        setPlayback(playbackInitState);
      }
      setCameraInfo(vmsSession.cameraList[deviceId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  useEffect(() => {
    setLive(liveInitState);
  }, [cameraInfo]);

  return !vmsSession.isLogged || vmsSession.loadingData ? (
    <>
      <h3>La conexión con el VMS aún no está lista.</h3>
    </>
  ) : !cameraInfo ? (
    <>
      <h3>El dispositivo seleccionado no es válido</h3>
    </>
  ) : (
    <div style={{ ...styles.player, width: width, height: height }}>
      <div id={`container${cameraInfo.Id}`} style={styles.cameraContainer}>
        <h4 style={styles.cameraName}>{cameraInfo.Name}</h4>
        <canvas style={styles.cameraCanvas}></canvas>
        <audio />
        {live.ptz && <PtzControls setLive={setLive} />}
      </div>

      {live.isLive && (
        <LiveBar
          cameraInfo={cameraInfo}
          live={live}
          setLive={setLive}
          playback={playback}
          setPlayback={setPlayback}
          vmsSession={vmsSession}
        />
      )}

      {playback.isPlayback && (
        <PlaybackBar
          cameraInfo={cameraInfo}
          live={live}
          setLive={setLive}
          playback={playback}
          setPlayback={setPlayback}
          vmsSession={vmsSession}
        />
      )}
    </div>
  );
}
