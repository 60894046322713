import React, { useState } from 'react';
import jsPDF from 'jspdf';
import img1 from '../../../../assets/images/logo_metroFull.png';
import dayjs from 'dayjs';
import { getUser } from '../../../../utils/getUser';
import useAuth from '../../../../hooks/useAuth';
import { Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';


const EjemploPdf = ({itemsGeneral}) => {
    const { UsuariosList } = useAuth();
    const [loading,setLoading]=useState(false)
    const formatDate = (date) => date ? dayjs(date).format('MMM DD, YYYY hh:mm:ss A') : 'Sin fecha';

    const estadoFiltros = {
        Created: 'Creado',
        Started: 'Iniciado',
        Completed: 'Completado',
        FalseAlarm: 'Falsa alarma',
        Skipped: 'Saltada',
      };


      

    const FormatearMoneda = ( valor ) => {
        const valorFormateado = new Intl.NumberFormat('es-CO', {
          style: 'decimal',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(valor);
    
        return valorFormateado;
      }




  const addFirstPageContent = (pdf, itemsGeneral) => {
    let yPos = 50; // Posición inicial
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.getWidth();
    const leftColumnX = margin;
    const rightColumnX = pageWidth / 2 + margin;
    const lineHeight = 10;
    // Configuración de fuente
  
    
    // Títulos de columnas
    pdf.setFont('helvetica', 'bold');
    pdf.text(`Inicio: ${formatDate(itemsGeneral?.stateHistory?.Open?.date)}`, leftColumnX, yPos);
    pdf.text(`Cierre: ${formatDate(
        itemsGeneral?.stateHistory?.Closed?.date || 
        itemsGeneral?.stateHistory?.FalseAlarm?.date
      )}`, rightColumnX, yPos);
    yPos += 8;
    pdf.setFont('helvetica', 'normal');
    pdf.text('Estado:', leftColumnX, yPos);
    pdf.text(`Ubicación:  ${itemsGeneral?.location?.name }`, rightColumnX, yPos);
    yPos += 8;
  
    // Datos de la primera columna
    pdf.setFont('helvetica', 'normal');
    pdf.text(`Lugar: ${itemsGeneral?.location?.specific || 'No disponible'}`, leftColumnX, yPos);
    yPos += 8;
    pdf.text(`Zona: ${itemsGeneral?.location?.zone || 'No disponible'}`, leftColumnX, yPos);
    yPos += 8;
    pdf.text(`Fuente de Información: ${itemsGeneral?.source || 'No disponible'}`, leftColumnX, yPos);
    yPos += 8;
 
    pdf.text(`Creado por: ${getUser(itemsGeneral.createdBy, UsuariosList)}`, leftColumnX, yPos);
  
    // Datos de la segunda columna
    yPos = 66; // Reiniciar la posición vertical
    pdf.text(`Tipo: ${itemsGeneral?.location?.type || 'No disponible'}`, rightColumnX, yPos);
    yPos += 8;
    pdf.text(`Medio: ${itemsGeneral?.location?.means || 'No disponible'}`, rightColumnX, yPos);
    yPos += 8;
    pdf.text(`Impacto: ${itemsGeneral?.impact || 'No disponible'}`, rightColumnX, yPos);
    yPos += 10;
  
    // Añadir subtítulo "Informe"
    pdf.setFont('helvetica', 'bold');
    pdf.setFontSize(14);
    yPos += 10;
    pdf.text('Informe', margin, yPos);
    yPos += 8;
    
  
    // Texto del informe
    pdf.setFont('helvetica', 'normal');
    pdf.setFontSize(12);
    const reportText = 'Este es el informe detallado del evento. Aquí se especifica la naturaleza del evento, las circunstancias y cualquier detalle relevante.';
    pdf.text(reportText, margin, yPos, { maxWidth: pageWidth - margin * 2 });
    yPos += 20; // Espacio después del texto
  

    const addTextWithHeading = (heading, text, yPos) => {
        pdf.setFont('helvetica', 'bold');
        pdf.text(heading, margin, yPos);
        pdf.setFont('helvetica', 'normal');
        //yPos += lineHeight;
        const lines = pdf.splitTextToSize(text, pageWidth - margin * 2);
        lines.forEach((line) => {
          if (yPos + lineHeight > pdf.internal.pageSize.height - margin) {
            pdf.addPage();
            yPos = margin; // Reiniciar la posición vertical en la nueva página
          }
          pdf.text(line, margin + 20, yPos);
          yPos += lineHeight;
        });
        return yPos; // Retornar la posición vertical actualizada
      };
  yPos = addTextWithHeading('Costo:', FormatearMoneda(itemsGeneral.cost.total), yPos);
  yPos = addTextWithHeading('Descripción:', itemsGeneral?.cost?.description || ' ', yPos);
 

  // Causas Asociadas
  pdf.setFont('helvetica', 'bold');
  pdf.text('Causas Asociadas:', margin, yPos);
  pdf.setFont('helvetica', 'normal');
  yPos += lineHeight;

  const causes = itemsGeneral?.causes || [];

  causes.forEach((cause, index) => {
    const causeText = `Causa ${index + 1}: ${cause?.protocol_cause || "Sin causas asignadas"}`;
    const causeLines = pdf.splitTextToSize(causeText, pageWidth - margin * 2);

    causeLines.forEach((line) => {
      if (yPos + lineHeight > pdf.internal.pageSize.height - margin) {
        pdf.addPage();
        yPos = margin; // Reiniciar la posición vertical en la nueva página
      }
      pdf.text(line, margin + 20, yPos);
      yPos += lineHeight;
    });

    yPos += lineHeight; // Espacio después de cada causa
  });

  
  };

  const addHeader = (pdf,item) => {
    pdf.addImage(img1, 'PNG', 10, 10, 20, 30); // x, y, width, height
    pdf.setFont('helvetica');
    pdf.setFontSize(24);
    pdf.setTextColor(40);
    pdf.text(`Información General - ${item?.id || 'cise-xxxx-xxx'}`, 35, 25); // x, y
    pdf.setFontSize(12);
    pdf.text(`Fecha de creación del informe: ${dayjs().format('MMM DD, YYYY hh:mm:ss A')}`, 35, 15); // x, y
  };


  const addSecondPageContent = (pdf, itemProtocol) => {
    // Configuración inicial
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.getWidth();
    const columnWidth = (pageWidth - margin * 2) / 2;
    let yPos = 20; // Posición inicial después del encabezado
  
    // Añadir título
    pdf.setFont('helvetica', 'bold');
    pdf.setFontSize(18);
    pdf.text(`PARP ${itemProtocol?.protocol} - (${itemProtocol?.eventId})`, margin, yPos);
    yPos += 10; // Espacio después del título
  
    // Configuración de la tabla
    const rowHeight = 8; // Altura de fila
    const columnHeaders = ['Estado de Protocolo:' + estadoFiltros[itemProtocol?.state] || "NA", ''];
    const rows = [
   
      [`Inicio: ${formatDate(itemProtocol?.stateHistory?.Created?.date) }`, `Cierre: ${formatDate(
        itemProtocol?.stateHistory?.Completed?.date ||
          itemProtocol?.stateHistory?.FalseAlarm?.date ||
          itemProtocol?.stateHistory?.Skipped?.date
      ) }`],
      ["Iniciador: " + getUser(itemProtocol.stateHistory.Created.userId, UsuariosList),"Finalizador: " +  getUser(itemProtocol?.stateHistory?.Completed?.userId ||
        itemProtocol?.stateHistory?.FalseAlarm?.userId, UsuariosList)]
    ];
  
    // Añadir encabezados de columna
    pdf.setFont('helvetica', 'bold');
    pdf.setFontSize(12);
    pdf.text(columnHeaders[0], margin, yPos);
    pdf.text( columnHeaders[1], margin + columnWidth, yPos);
    yPos += rowHeight;
  
    // Añadir filas de la tabla
    pdf.setFont('helvetica', 'normal');
    rows.forEach((row) => {
      pdf.text(row[0], margin, yPos);
      pdf.text(row[1], margin + columnWidth, yPos);
      yPos += rowHeight;
    });
  
    // Añadir espacio adicional para asegurar que el contenido no se solape
    yPos += 5;

    pdf.setFont('helvetica', 'bold');
    pdf.setFontSize(16);
    pdf.text("Actividades de protocolo", margin, yPos);
    yPos += 5;

    return yPos; // Retornar la posición vertical actualizada
  };


  const renderActivityBlocks = (activities, pdf, margin, yPos, pageHeight) => {

   
  const tituloAct = (tituloProtocolo) => {
    let nombre = tituloProtocolo[1].replace(/-/g, ' ') || "act 1"

    if (tituloProtocolo.length ===4){
        nombre =tituloProtocolo[2].replace(/-/g, ' ')

    }
    return nombre;
  };

  

  activities.forEach(activity => {
      const leftColumnX = margin;
      const rightColumnX = pdf.internal.pageSize.getWidth() / 2;
      const tituloProtocolo = activity.protocol_activity_subactivity.split("_")
   
      // Título de la actividad
      pdf.setFontSize(14);
      pdf.setFont('helvetica', 'bold');

      
     pdf.text(tituloAct(tituloProtocolo), margin, yPos);
      yPos += 10;
  
      // Primera columna (Inicio)
      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'bold');
      pdf.text('Inicio:', leftColumnX, yPos);
      pdf.setFont('helvetica', 'normal');
      pdf.text(formatDate(activity.stateHistory?.Started?.date) || 'Sin fecha', leftColumnX + 20, yPos);
  
      // Segunda columna (Cierre)
      pdf.setFont('helvetica', 'bold');
      pdf.text('Cierre:', rightColumnX, yPos);
      pdf.setFont('helvetica', 'normal');
      pdf.text(formatDate(activity.stateHistory?.Completed?.date) || 'Sin fecha', rightColumnX + 20, yPos);
  
      yPos += 7;
  
      // Comentario
      if (activity.comments && activity.comments.length > 0) {
        pdf.setFont('helvetica', 'bold');
        pdf.text('Comentario:', margin, yPos);
        yPos += 7;
        pdf.setFont('helvetica', 'normal');
        const commentText = activity.comments[0].comment || 'No disponible';
        const commentLines = pdf.splitTextToSize(commentText, 180); // Ajusta el ancho si es necesario
        commentLines.forEach(line => {
          pdf.text(line, margin, yPos);
          yPos += 5; // Ajustar espacio entre líneas
        });
      }
  
      yPos += 10; // Espacio después de cada actividad
  
      // Verificar si es necesario agregar una nueva página
      if (yPos > pageHeight - margin) {
        pdf.addPage();
        yPos = margin;
      }
    });
  };
  
  const ajusteArray = (data) => {
    const sortedData = data.sort((a, b) => {
      const orderA = parseFloat(a.order);
      const orderB = parseFloat(b.order);

      // Comparar los valores como números
      return orderA - orderB;
    });

    return sortedData;
  };

  // Función para generar el PDF
  const generatePDF = () => {
    setLoading(true)
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10;
    let yPos = margin;

   
    
      // Añadir cuerpo de la primera página
  

      addHeader(pdf,itemsGeneral);
      addFirstPageContent(pdf,itemsGeneral);
    // Añadir una nueva página
    pdf.addPage();

    yPos=addSecondPageContent(pdf,itemsGeneral.protocols[0])
    yPos += 5;
    // Añadir el segundo div en la segunda página

   
      const ordenActividades = ajusteArray(itemsGeneral.protocols[0].activities);

            const itemsPerPage = 5;
            const initialRender = 5; // Los primeros 6 elementos a renderizar justo después del segundo div
            
            // Renderizar los primeros 6 elementos en la segunda página
            
        // Renderizar los primeros 5 elementos en la segunda página
            const pageItems = ordenActividades.slice(0, initialRender);
            yPos = renderActivityBlocks(pageItems, pdf, margin, yPos, pageHeight);

        // Renderizar los elementos restantes en grupos de 6 por página
            for (let i = initialRender; i < ordenActividades.length; i += itemsPerPage) {
                pdf.addPage(); // Nueva página para cada grupo de 6
                yPos = margin; // Reiniciar la posición vertical para la nueva página
                
                const pageItems = ordenActividades.slice(i, i + itemsPerPage);
                yPos = renderActivityBlocks(pageItems, pdf, margin, yPos, pageHeight);
                }
                // Descargar el PDF

        setLoading(false)
        pdf.save(`Informe_General_Evento_${itemsGeneral?.id || 'cise-xxxx-xxx'}.pdf`);
    };

  return (
    <div>
   
       <Button loading={loading} onClick={generatePDF} block icon={<FilePdfOutlined />} >Guardar PDF </Button>
    
    </div>
  );
};

export default EjemploPdf;
