import React, { useState } from 'react';
import { Space, Button, Badge, Tooltip } from 'antd';
// import remindersService from '../../../services/remindersService';
import { CalendarOutlined } from '@ant-design/icons';

import ModalCalendario from '../../../components/modales/calendario/ModalCalendario';

export default function ModalEventosClendario() {
  const [open, setOpen] = useState(false);
  // const [, setNotificaciones] = useState([]);
  const [longitud, setLongitud] = useState(0);

  // const consultaCalendario = async () => {
  //   try {
  //     const response = await remindersService.ObtenerEventosCalendario();
  //     let data = response;

  //     setNotificaciones(data?.Items || []);
  //     setLongitud(data?.Items.length || 0);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // useEffect(() => {
  //   // Función para realizar la consulta al calendario

  //   // Llamar a la función de consulta inicialmente
  //   consultaCalendario();

  //   // Establecer el intervalo para llamar a consultaCalendario cada 10 minutos
  //   const intervalId = setInterval(() => {
  //   consultaCalendario();
  //   }, 10 * 60 * 1000); // 10 minutos en milisegundos

  //   // Limpieza del intervalo al desmontar el componente o al ejecutarse el efecto nuevamente
  //   return () => clearInterval(intervalId);
  // }, []);

  const openModal = () => {
    setOpen(true);
    setLongitud(0);
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Space size={2.5}>
        <Badge count={longitud || 0}>
          <Tooltip title="Eventos Relevantes">
            <Button
              onClick={() => openModal(true)}
              type="text"
              icon={<CalendarOutlined />}
            ></Button>
          </Tooltip>
        </Badge>
      </Space>

      <ModalCalendario visible={open} onCancel={closeModal} />
    </>
  );
}
