import React, { useState } from 'react';
import Dashboard from '../../pages/dashboard';
// import HeaderP from './components/Header';

// import NotificationsToast from './components/NotificationsToast';
import { Layout, Button, FloatButton } from 'antd';
import { BulbOutlined } from '@ant-design/icons';

import FooterPage from './components/Footer';
import useAuth from '../../hooks/useAuth';

const { Content } = Layout;

export default function Application({ children }) {
  const { session } = useAuth();

  const [permisos] = useState(true);
  const [show, setShow] = useState(false);
  const [showNewEvent, setShowNewEvent] = useState(false);
  const [showActividades, setShowActividades] = useState(false);
  const [showReportes, setShowReportes] = useState(false);
  const [showAlertas, setAlertas] = useState(false);

  const [datosSeleccionados, setDatosSeleccionados] = useState('1');

  return (
    <Layout
      style={{
        height: '100vh',
      }}
    >
      {/* <HeaderP /> */}
      <Layout>
        <Content style={{ padding: '0px 0px 15px 0px ', overflowY: 'scroll' }}>
          <Dashboard
            setShow={setShow}
            show={show}
            permisos={permisos}
            setShowNewEvent={setShowNewEvent}
            showNewEvent={showNewEvent}
            setShowActividades={setShowActividades}
            showActividades={showActividades}
            setShowReportes={setShowReportes}
            showReportes={showReportes}
            showAlertas={showAlertas}
            setAlertas={setAlertas}
            datosSeleccionados={datosSeleccionados}
          />

          {session.roles.events.isAllowedtoCreate && (
            <div className="mx-auto mt-1" style={{ maxWidth: '10rem' }}>
              <Button
                type="primary"
                style={{
                  position: 'fixed', // Fijar el componente en la parte inferior
                  bottom: 0, // En la parte inferior
                  zIndex: 100,
                  yIndex: 700,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '50px',
                }}
                onClick={() => setShowNewEvent(true)}
              >
                Crear Evento{' '}
              </Button>
            </div>
          )}
          {session.roles.events.isAllowedtoCreate && (
            <div>
              <FloatButton
                icon={<BulbOutlined />}
                description="Alertas"
                shape="square"
                type="primary"
                style={{
                  right: 24,
                  marginBottom: '0px',
                  padding: '2px',
                  width: '100px',
                }}
                onClick={() => setAlertas(true)}
              />
            </div>
          )}
        </Content>
      </Layout>
      <FooterPage setDatosSeleccionados={setDatosSeleccionados} />
    </Layout>
  );
}
