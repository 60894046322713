import React, { useEffect, useState } from 'react';
import { List, Avatar, Space, Skeleton } from 'antd';
import { UserOutlined, ClockCircleOutlined } from '@ant-design/icons';
import extraService from '../../../../services/extrasService';
import { getUser } from '../../../../utils/getUser';
import useAuth from '../../../../hooks/useAuth';
import dayjs from 'dayjs';

const HistorialCambios = ({ open, eventId }) => {
  const { UsuariosList } = useAuth();
  const consultarHistorial = async (eventId) => {
    try {
      // Lógica para enviar la solicitud de edición del evento al servidor
      const response = await extraService.consultarLogs();
      // Manejar la respuesta del servidor, si es necesario

      const logsArray = response.Items || [];
      const filteredLogs = logsArray.filter((log) =>
        log.detail.includes(eventId)
      );

      setCambios(filteredLogs || []);
    } catch (error) {
      console.error('Error al editar el evento:', error);
      //message.error('No se logro registrar falsa alarma');
      // Manejar errores, si es necesario
    }
  };

  const [cambios, setCambios] = useState();

  useEffect(() => {
    // Aquí debes recuperar los datos de los cambios desde tu backend o base de datos.
    if (open) consultarHistorial(eventId);

    // Luego, actualiza el estado de cambios usando setCambios(data) donde "data" es la lista de cambios.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const diccionarioCambios = {
    state: 'Estado',
    location: 'Ubicación',
    protocols: 'Protocolos',
    title: 'Asunto',
    source: 'Funte de información',
  };

  return (
    <>
      {cambios ? (
        <List
          itemLayout="horizontal"
          dataSource={cambios}
          renderItem={(item) => (
            <List.Item
              key={item.title}
              actions={[
                <Space>
                  <ClockCircleOutlined />
                  {dayjs(item.createdAt).format(' DD/MMM/YYYY  hh:mm:ss')}
                </Space>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined />} />}
                title={`ID de Persona: ${getUser(
                  item.userId || '',
                  UsuariosList
                )}`}
                description={
                  <>
                    <p>{item.detail}</p>

                    <b>Cambios Realizados</b>

                    {item.elements.length > 0 && (
                      <>
                        <ul>
                          {item.elements.map((objeto, index) => {
                            return (
                              <li key={index}>
                                {Object.keys(objeto).map((key, innerIndex) => {
                                  return (
                                    <span key={innerIndex}>
                                      {diccionarioCambios[key] || key}
                                    </span>
                                  );
                                })}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default HistorialCambios;
