// import React from 'react';
// import getOptions from '../../../../utils/getOptions';
// import { Space, Avatar, Button, Tooltip } from 'antd';
// import { UserOutlined, DeleteOutlined } from '@ant-design/icons';
// import ModalEditarRole from '../ModalEditarRole';
// import BotonDelete from './BotonDelete';

// export default function tableColumnsRoles(items, refresh) {
//   const roles = {
//     CISEInspector: 'Inspector',
//     CISEAnalyts: 'Analista',
//     CISEAdministrador: 'Administrador',
//     Coordinator: 'Coordinador',
//   };

//   return [
//     {
//       title: 'Nombre',
//       dataIndex: 'email',
//       key: 'email',

//       aling: 'center',
//       filterSearch: true,
//       filters: getOptions(items, 'email'),
//       onFilter: (v, record) => record.email.indexOf(v) === 0,
//       render: (text, record) => (
//         <Space>
//           <Avatar icon={<UserOutlined />} /> <span>{text}</span>
//         </Space>
//       ),
//     },

//     {
//       title: 'Roles',
//       dataIndex: 'roles',
//       key: 'roles',
//       width: 200,

//       aling: 'center',
//       filterSearch: true,
//       filters: getOptions(items, 'roles'),
//       onFilter: (v, record) => record.roles.indexOf(v) === 0,
//       render: (text, record) => <Space>{roles[text]}</Space>,
//     },
//     {
//       title: 'Teléfono',
//       dataIndex: 'phone',
//       key: 'phone',
//       width: 200,

//       aling: 'center',
//       filterSearch: true,
//       filters: getOptions(items, 'phone'),
//       onFilter: (v, record) => record.phone.indexOf(v) === 0,
//     },

//     {
//       title: 'Acciones',
//       dataIndex: '',
//       key: '',
//       width: 100,
//       filterSearch: true,
//       aling: 'center',
//       render: (text, record) => (
//         <Space>
//           <ModalEditarRole item={text} refresh={refresh} />

//           <BotonDelete item={record} refesh={refresh} />
//         </Space>
//       ),
//     },
//   ];
// }

import React from 'react';
import getOptions from '../../../../utils/getOptions';
import { Space, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ModalEditarRole from '../ModalEditarRole';
import BotonDelete from './BotonDelete';

export default function tableColumnsRoles(items, refresh, session) {
  const roles = {
    CISEInspector: 'Inspector',
    CISEAnalyts: 'Analista',
    CISEAdministrador: 'Administrador',
    Coordinator: 'Coordinador',
  };

  return [
    {
      title: 'Nombre',
      dataIndex: 'email',
      key: 'email',
      align: 'left', // Corrected alignment spelling
      filterSearch: true,
      filters: getOptions(items, 'email'),
      onFilter: (v, record) => record.email.includes(v), // Updated filtering logic
      render: (text, record) => (
        <Space>
          <Avatar icon={<UserOutlined />} /> <span>{text}</span>
        </Space>
      ),
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      // width: 200,
      align: 'center', // Corrected alignment spelling
      filterSearch: true,
      filters: getOptions(items, 'roles'),
      onFilter: (v, record) => record.roles.includes(v), // Updated filtering logic
      render: (text, record) => <Space>{roles[text]}</Space>,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      key: 'phone',
      // width: 200,
      align: 'center', // Corrected alignment spelling
      filterSearch: true,
      filters: getOptions(items, 'phone'),
      onFilter: (v, record) => record.phone.includes(v), // Updated filtering logic
    },
    {
      title: 'Acciones',
      dataIndex: '',
      key: '',
      // width: 100,
      filterSearch: true,
      align: 'center', // Corrected alignment spelling
      render: (text, record) => (
        <Space>
          <ModalEditarRole item={text} refresh={refresh} />

          {session.roles.users.isAllowedtoDelete && (
            <BotonDelete item={record} refesh={refresh} />
          )}
        </Space>
      ),
    },
  ];
}
