import { Amplify } from 'aws-amplify';

const configureAmplify = (type = 'alarms') => {
  let graphqlConfig = {
    endpoint:
      'https://3cqjshbytna4zpfkdnpg5bojmu.appsync-api.us-east-1.amazonaws.com/graphql',
    realTimeEndpoint:
      'wss://3cqjshbytna4zpfkdnpg5bojmu.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
    apiKey: 'da2-37ri4zqrs5d6bcna5ibi32w3qm',
  };

  if (type === 'notification') {
    graphqlConfig.apiKey = 'da2-kuk4shrifzcnxk52qicl6d75wm';
    graphqlConfig.realTimeEndpoint =
      'wss://srgbqlccbva6jmdswugkm5yila.appsync-api.us-east-1.amazonaws.com/graphql';
    graphqlConfig.endpoint =
      'https://srgbqlccbva6jmdswugkm5yila.appsync-api.us-east-1.amazonaws.com/graphql';
  }

  Amplify.configure({
    API: {
      GraphQL: {
        endpoint: graphqlConfig.endpoint,
        region: 'us-east-1',
        defaultAuthMode: 'apiKey',
        apiKey: graphqlConfig.apiKey,
      },
    },
  });
};

export { configureAmplify };
