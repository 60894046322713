import axios from 'axios';

const api = 'https://sspd1z8jy0.execute-api.us-east-1.amazonaws.com/prod';
const apiKey = process.env.REACT_APP_KEY_PROTOCOLS || '';

const consultarProtocolo = async (
  startDate = null,
  endDate = null,
  lastEvaluatedKey = null
) => {
  const path = '/protocols'; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const params = {
      startDate,
      endDate,
      limit: 300,
    };

    // Agregar startKey solo si lastEvaluatedKey no es null
    if (lastEvaluatedKey !== null) {
      params.startKey = JSON.stringify(lastEvaluatedKey);
    }

    const response = await axios.get(apiUrl, {
      headers: {
        'x-api-key': apiKey,
      },
      params,
    });

    return response.data; // Devuelve los datos obtenidos
  } catch (error) {
    console.error('Error en la consulta:', error);
    throw error; // Relanza el error para que se maneje en otro lugar si es necesario
  }
};
const crearProtocolo = async (registro) => {
  //  const path = '/crear';
  const path = `/protocols`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'text/plain');

  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json(); // Parsear la respuesta a JSON
    return data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
  // const res = customFetch(() => API.post(apiName, path, myInit));
};

const EditarProtocolo = async (eventId, registro) => {
  const path = `/protocols`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.patch(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });

    return response.data; // Devolver los datos obtenidos from Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const protocolosService = {
  crearProtocolo,
  consultarProtocolo,
  EditarProtocolo,
};

export default protocolosService;
