import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, DatePicker, Select, Button, message } from 'antd';
import remindersService from '../../../services/remindersService';

const { Option } = Select;

const { RangePicker } = DatePicker;

const ModalCalendarioForm = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dateR, setDateR] = useState('');
  const [frecuencia, setFrecuencia] = useState('');

  const handleCycleChange = (value) => {
    setFrecuencia(value);
  };

  const onFinish = (values) => {
    values.isCyclic = values.cycle === '' ? false : true;

    if (dateR.length === 2) {
      values.date = dateR[0];
      values.startDate = dateR[0];
      values.endDate = dateR[1];
    } else {
      values.date = dateR;
    }

    setLoading(true);
    CrearEvento(values);
  };

  const onChange = (value, dateString) => {
    setDateR(dateString);
  };
  const onOk = (value) => {};

  const CrearEvento = async (registro) => {
    try {
      await remindersService.crearEventoCalendario(registro);

      message.success('Evento creado en calendario');
      onClose();
    } catch (error) {
      message.error('No se logro hacer esta solicitud');
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Función para realizar la consulta al calendario

    // Llamar a la función de consulta inicialmente
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title="Formulario"
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Nombre del evento"
          rules={[{ required: true, message: 'Por favor ingrese un nombre' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descripción"
          rules={[
            { required: true, message: 'Por favor ingrese una descripción' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="cycle" label="Frecuencia">
          <Select onChange={handleCycleChange}>
            <Option value="">Ninguno</Option>
            <Option value="daily">Diario</Option>
            <Option value="weekly">Semanal</Option>
            <Option value="monthly">Mensual</Option>
          </Select>
        </Form.Item>

        {frecuencia.length > 0 ? (
          <Form.Item
            name="startDate"
            label="Rango de fechas  de Eventos"
            rules={[
              { required: true, message: 'Por favor seleccione una fecha' },
            ]}
          >
            <RangePicker
              format={'YYYY-MM-DDThh:mm-05:00'}
              bordered
              onChange={onChange}
              style={{ marginRight: '5px', width: '60%' }}
              cesitas
              convertir
              las
              fechas
              a
              objetos
              dayjs
              allowClear={false}
            />
          </Form.Item>
        ) : (
          <Form.Item
            name="date"
            label="Fecha del evento"
            rules={[
              { required: true, message: 'Por favor seleccione una fecha' },
            ]}
          >
            <DatePicker
              showTime
              onChange={onChange}
              onOk={onOk}
              //disabled={disabledDate}
              format={'YYYY-MM-DDThh:mm-05:00'}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Crear Evento en el Calendario
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCalendarioForm;
