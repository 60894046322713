// import React, { useState } from 'react';
// import { Button, Tooltip, Modal, Form, message, Input } from 'antd';
// import useAuth from '../../../hooks/useAuth';
// import { EditOutlined } from '@ant-design/icons';
// import activitiesService from '../../../services/activitiesService';

// export default function ModalEditarComentario({
//   item,
//   textactual = '',
//   consultaActividades,
// }) {
//   const { session } = useAuth();
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);

//   const onFinish = (values) => {
//     const { comment } = values;
//     Completar(comment);
//   };

//   const Completar = (comment) => {
//     let registro = {
//       eventId: item.eventId,
//       userId: session?.user?.sub || 'userId',
//       protocol_activity_subactivity: item.protocol_activity_subactivity,
//       item: {
//         comment: comment,
//       },
//     };

//     ejecutarActividad(registro);
//   };

//   const ejecutarActividad = async (registro) => {
//     setLoading(true);
//     try {
//       await activitiesService.funcionActividad(registro);

//       message.success('Comentario Editado');

//       consultaActividades();
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       message.error('No se logro hacer la consulta');
//     }
//     setOpen(false);
//     setLoading(false);
//   };
//   const validateMessages = {
//     required: 'Es requerido',
//     types: {
//       email: 'Correo no valido',
//       number: 'Numero no valido',
//     },
//   };
//   return (
//     <>
//       <Tooltip title="Editar Comentario">
//         <Button
//           type="text"
//           icon={<EditOutlined />}
//           onClick={() => setOpen(true)}
//         ></Button>
//       </Tooltip>

//       <Modal
//         title={'Cambiar Comentario'}
//         visible={open}
//         onOk={() => setOpen(false)}
//         onCancel={() => setOpen(false)}
//         footer={null}
//       >
//         <Form
//           layout="vertical"
//           name="nest-messages"
//           onFinish={onFinish}
//           style={{
//             maxWidth: 600,
//           }}
//           validateMessages={validateMessages}
//           initialValues={{
//             comment: textactual, // Puedes establecer el valor inicial aquí
//           }}
//         >
//           <Form.Item
//             name="comment"
//             label="Comentario"
//             rules={[
//               {
//                 required: true,
//                 message: 'Debes de completar este campo.',
//               },
//             ]}
//           >
//             <Input.TextArea />
//           </Form.Item>
//           <Button type="primary" htmlType="submit" block loading={loading}>
//             Enviar
//           </Button>
//         </Form>
//       </Modal>
//     </>
//   );
// }

import React, { useState } from 'react';
import { Button, Tooltip, Modal, Form, message, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import activitiesService from '../../../services/activitiesService';
import useAuth from '../../../hooks/useAuth';

// const ModalEditarComentario = ({
//   item,
//   textactual = '',
//   consultaActividades,
// }) => {
//   const { session } = useAuth();
//   const [form] = Form.useForm(); // Agrega el hook useForm
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);

//   const onFinish = (values) => {
//     const { comment } = values;
//     Completar(comment);
//   };

//   const Completar = (comment) => {
//     let registro = {
//       eventId: item.eventId,
//       userId: session?.user?.sub || 'userId',
//       protocol_activity_subactivity: item.protocol_activity_subactivity,
//       item: {
//         comment: comment,
//       },
//     };

//     ejecutarActividad(registro);
//   };

//   const ejecutarActividad = async (registro) => {
//     setLoading(true);
//     try {
//       await activitiesService.funcionActividad(registro);
//       message.success('Comentario Editado');
//       consultaActividades();
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       message.error('No se logró hacer la consulta');
//     }
//     setOpen(false);
//     setLoading(false);
//     form.resetFields(); // Limpia el formulario después de cerrar el modal
//   };

//   const validateMessages = {
//     required: 'Es requerido',
//     types: {
//       email: 'Correo no válido',
//       number: 'Número no válido',
//     },
//   };

//   return (
//     <>
//       <Tooltip title="Editar Comentario">
//         <Button
//           type="text"
//           icon={<EditOutlined />}
//           onClick={() => {
//             setOpen(true);
//             form.setFieldsValue({ comment: textactual }); // Establece el valor inicial del campo comment
//           }}
//         ></Button>
//       </Tooltip>

//       <Modal
//         title={'Cambiar Comentario'}
//         visible={open}
//         onOk={() => setOpen(false)}
//         onCancel={() => {
//           setOpen(false);
//           form.resetFields(); // Limpia el formulario al cerrar el modal
//         }}
//         footer={null}
//       >
//         <Form
//           form={form}
//           layout="vertical"
//           name="nest-messages"
//           onFinish={onFinish}
//           style={{
//             maxWidth: 600,
//           }}
//           validateMessages={validateMessages}
//         >
//           <Form.Item
//             name="comment"
//             label="Comentario"
//             rules={[
//               {
//                 required: true,
//                 message: 'Debes completar este campo.',
//               },
//             ]}
//           >
//             <Input.TextArea />
//           </Form.Item>
//           <Button type="primary" htmlType="submit" block loading={loading}>
//             Enviar
//           </Button>
//         </Form>
//       </Modal>
//     </>
//   );
// };

// export default ModalEditarComentario;

const ModalEditarComentario = ({
  item,
  textactual = '',
  consultaActividades,
}) => {
  const { session } = useAuth();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const { comment } = values;
    Completar(comment);
  };

  const Completar = (comment) => {
    let registro = {
      eventId: item.eventId,
      userId: session?.user?.sub || 'userId',
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      item: {
        comment: comment,
      },
    };

    ejecutarActividad(registro);
  };

  const ejecutarActividad = async (registro) => {
    setLoading(true);
    try {
      await activitiesService.funcionActividad(registro);
      message.success('Comentario Editado');
      consultaActividades();
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logró hacer la consulta');
    }
    setOpen(false);
    setLoading(false);
    form.resetFields();
  };

  const validateMessages = {
    required: 'Es requerido',
    types: {
      email: 'Correo no válido',
      number: 'Número no válido',
    },
  };

  return (
    <>
      <Tooltip title="Editar Comentario">
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={() => {
            setOpen(true);
            form.setFieldsValue({ comment: textactual });
          }}
        ></Button>
      </Tooltip>

      <Modal
        title={'Cambiar Comentario'}
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
          validateMessages={validateMessages}
          initialValues={{ comment: 'Valor predeterminado aquí' }} // Agrega los valores predeterminados
        >
          <Form.Item
            name="comment"
            label="Comentario"
            rules={[
              {
                required: true,
                message: 'Debes completar este campo.',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Enviar
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ModalEditarComentario;
