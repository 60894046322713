import { Navigate, Outlet } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

export default function PublicProvider() {
  const { session } = useAuth();

  if (session.user) return <Navigate to="/" replace />;
  return <Outlet />;
}
