import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import AuthProvider from '../context/AuthProvider';
import PublicProvider from './PublicProvider';
import PrivateProvider from './PrivateProvider';
import LoginPage from '../pages/ingreso';

export default function index() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/auth" element={<PublicProvider />}>
            <Route index element={<LoginPage />} />
          </Route>

          <Route path="/inicio" element={<PrivateProvider />}>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}
