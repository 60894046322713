import React from 'react';
import getOptions from '../../../../utils/getOptions';
import { Space, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ModalEditarRole from '../ModalEditarRole';
import BotonDelete from './BotonDelete';

export default function tableColumnRolesMobile(items, refresh, session) {
  return [
    {
      title: 'Nombre',
      dataIndex: 'email',
      key: '',
      align: 'left', // Corrected alignment spelling
      filterSearch: true,
      width: 50,
      filters: getOptions(items, 'email'),
      onFilter: (v, record) => record.email.includes(v), // Updated filtering logic
      render: (text, record) => (
        <Space>
          <Avatar icon={<UserOutlined />} /> <span>{text}</span>
        </Space>
      ),
    },

    {
      title: 'Acciones',
      dataIndex: '',
      key: 'email',
      width: 50,
      filterSearch: true,
      align: 'center', // Corrected alignment spelling
      render: (text, record) => (
        <Space>
          <ModalEditarRole item={text} refresh={refresh} />

          {session.roles.users.isAllowedtoDelete && (
            <BotonDelete item={record} refesh={refresh} />
          )}
        </Space>
      ),
    },
  ];
}
