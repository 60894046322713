import React, { useState } from 'react';
import { Table, Modal, Typography, Button, Tag } from 'antd';
import PageHeader from '../../headers/PageHeader';
import ModalCrearAlarma from './ModalCrearAlarma';
import GeneralImage from '../components/GeneralImage';

const { Title, Paragraph } = Typography;
const { Column } = Table;
export default function ModalAlarmasPreventivas({ open, setOpen }) {
  const [showAlert, setShowAlert] = useState(false);
  const data = [
    {
      id: 1,
      ubicacion: 'Ubicación 1',
      tipo: 'Tipo 1',
      estado: 'Activo',
    },
    {
      id: 2,
      ubicacion: 'Ubicación 2',
      tipo: 'Tipo 2',
      estado: 'Inactivo',
    },
    {
      id: 3,
      ubicacion: 'Ubicación 3',
      tipo: 'Tipo 3',
      estado: 'Activo',
    },
  ];

  return (
    <Modal
      title=""
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={'50%'}
      footer={false}
    >
      <PageHeader title="Lista de Alertas Preventivas" head="Alertas">
        {/* <Button type="primary" onClick={() => setShowAlert(true)}>
          Crear Novedad
        </Button> */}
      </PageHeader>

      <GeneralImage />
      <Typography style={{ textAlign: 'center' }}>
        <Title level={3}>Estamos trabajando en mejorar la plataforma.</Title>
        <Paragraph>
          Pronto notarás los cambios que estamos implementado.
        </Paragraph>
      </Typography>
      {/* <ModalCrearAlarma setOpen={setShowAlert} open={showAlert} />
      <Table dataSource={data}>
        <Column title="Id" dataIndex="id" key="id" />
        <Column title="Ubicación" dataIndex="ubicacion" key="ubicacion" />
        <Column title="Tipo" dataIndex="tipo" key="tipo" />
        <Column
          title="Estado"
          dataIndex="estado"
          key="estado"
          render={(text, record) => (
            <Space size="middle">
              <Tag>{text}</Tag>
            </Space>
          )}
        />
      </Table> */}
    </Modal>
  );
}
