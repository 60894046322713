import React from 'react';
import getOptions from '../../../../utils/getOptions';
import { Space, Tag } from 'antd';
import MenuBoton from './MenuBoton';
import days from 'dayjs';
// import useAuth from '../../../../hooks/useAuth';
import localizedFormat from 'dayjs/plugin/localizedFormat';
days.extend(localizedFormat);
days.locale('es');
export default function TablaEventos(items, fetchData) {
  //const { session } = useAuth();
  const estadoFiltros = {
    InProgress: 'Ejecutando',
    FalseAlarm: 'Falsa alarma',
    Closed: 'Cerrado',
    Open: 'Abierto',
  };

  const formatDate = (date) => {
    return date ? days(date).format('DD/MMM/YYYY') : 'Sin fecha';
  };

  const formatDateHour = (date) => {
    return date ? days(date).format('hh:mm A') : 'Sin HORA';
  };

  const FormatearMoneda = ({ valor }) => {
    const valorFormateado = new Intl.NumberFormat('es-CO', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(valor);

    return <span>{valorFormateado}</span>;
  };

  return [
    {
      title: 'Evento Id',
      dataIndex: 'id',
      key: 'id',
      aling: 'center',
      fixed: 'left',
      width: 200,
      filterSearch: true,
      filters: getOptions(items, 'id'),
      onFilter: (v, record) => record.id.indexOf(v) === 0,
      render: (text, record) => <Space>{text}</Space>,
    },

    {
      title: 'Ubicación',
      dataIndex: 'location',
      key: 'nameLocation',
      width: 200,
      align: 'center',
      filterSearch: true,
      filters: getOptions(items, 'nameLocation'),
      onFilter: (v, record) => record.nameLocation.indexOf(v) === 0,
      render: (text, record) => (
        <Space direction="vertical">
          <p>{text && text.name}</p>
          <p>Zona: {text && text.zone}</p>
        </Space>
      ),
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      align: 'center',
      render: (text) => (
        <Space>
          <>{formatDate(text)}</>

          <>{formatDateHour(text)}</>
        </Space>
      ),
      // render: (text) => <Space>{formatDate(text.Open.date)}</Space>,
    },

    {
      title: 'Protocolos',
      dataIndex: 'protocols',
      key: 'protocols',
      width: 150,
      filterSearch: true,
      align: 'center',
      filters: [
        { text: 'Amit', value: 'amit' },
        { text: 'Agresión', value: 'agresion' },
        { text: 'Clave-alfa', value: 'clave-alfa' },
        { text: 'Clave-s', value: 'clave-s' },
        { text: 'Delitos', value: 'delitos' },
        { text: 'Emergencias', value: 'emergencias' },
        { text: 'Hamcc', value: 'hamcc' },
        {
          text: 'Incumplimiento al reglamento del usuario',
          value: 'incumplimiento-al-reglamento-del-usuario',
        },
        { text: 'Intrusión', value: 'intrusion' },
        { text: 'Persona-fallecida', value: 'persona-fallecida' },
        { text: 'Persona-herida', value: 'persona-herida' },
        { text: 'Terrorismo', value: 'terrorismo' },
      ],
      onFilter: (value, record) => record.protocols.includes(value),
      render: (text, record) => {
        const chunkArray = (array, size) => {
          const result = [];
          for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
          }
          return result;
        };

        // Dividir el arreglo de text en sublistas de tamaño 4
        const chunkedText = chunkArray(text, 4);
        return (
          <div>
            {chunkedText.map((row, index) => (
              <ul key={index} style={{ marginBottom: 8 }}>
                {row.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            ))}
          </div>
        );
      },
    },

    {
      title: 'Asunto',
      width: 300,
      dataIndex: 'title',
      key: 'title',
      filterSearch: true,
      filters: getOptions(items, 'title'),
      onFilter: (v, record) => record.title.indexOf(v) === 0,
    },
    {
      title: 'Costo',
      width: 100,
      dataIndex: 'cost',
      key: 'cost',
      render: (text) => (
        <Space>
          <FormatearMoneda valor={text.total} />
        </Space>
      ),
    },

    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      width: 100,
      fixed: 'right',
      filters: Object.keys(estadoFiltros).map((key) => ({
        text: estadoFiltros[key],
        value: key,
      })),
      onFilter: (v, record) => record.state.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {text === 'InProgress' && (
            <Tag key={text} color="blue">
              {estadoFiltros[text]}
            </Tag>
          )}
          {text === 'FalseAlarm' && (
            <Tag key={text} color="red">
              {estadoFiltros[text]}
            </Tag>
          )}
          {text === 'Closed' && (
            <Tag key={text} color="green">
              {estadoFiltros[text]}
            </Tag>
          )}
          {text === 'Open' && (
            <Tag key={text} color="yellow">
              {estadoFiltros[text]}
            </Tag>
          )}
        </Space>
      ),
    },

    {
      title: 'Acciones',
      dataIndex: 'url',
      key: 'url',
      width: 100,
      fixed: 'right',
      filterSearch: true,
      aling: 'center',
      render: (text, record) => (
        <MenuBoton key={record.id} item={record} fetchData={fetchData} />
      ),
    },
  ];
}
