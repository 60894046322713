import React from 'react';
import Header from './components/Header';
import LoginForm from './components/LoginForm';
import { Card, Image } from 'antd';
import '../../App.css';
import srcImg from '../../assets/images/logo_metroFull.png';

export default function index() {
  return (
    <body className="App-body">
      <div className="h-screen flex items-center justify-center">
        <div className="flex flex-col items-center">
          <Card>
            <Image
              preview={false}
              src={srcImg}
              width={'100px'}
              height={'160px'}
              style={{ marginBottom: '20px' }}
            ></Image>
            <Header />
            <div id="body" className="w-80 md:w-96 mt-5">
              <LoginForm />
            </div>
          </Card>
        </div>
      </div>
    </body>
  );
}
