import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Modal,
  Form,
  Select,
  Tag,
  Input,
  Image,
  message,
  Checkbox,
  List,
  Avatar,
  DatePicker,
} from 'antd';
import PageHeader from '../../headers/PageHeader';
import Title from 'antd/lib/typography/Title';
import days from 'dayjs';
import useAuth from '../../../hooks/useAuth';
import AMIT from '../../../assets/PARP/thief.png';
import Agresion from '../../../assets/PARP/aggression.png';
import ClaveAlfa from '../../../assets/PARP/accident.png';
import Emergencia from '../../../assets/PARP/emergencia.png';
import HAMCC from '../../../assets/PARP/HAMCC.png';
import Intrusion from '../../../assets/PARP/intrusion.png';
import Delitos from '../../../assets/PARP/Delitos.png';
import Heridos from '../../../assets/PARP/tape.png';
import Fallecidos from '../../../assets/PARP/tombstone.png';
import Incumplimiento from '../../../assets/PARP/Incuplimiento.png';
import Terrorismo from '../../../assets/PARP/threat.png';
import Suicide from '../../../assets/PARP/suicide.png';
import eventService from '../../../services/eventService';
// import InputPropover from './components/InputPropover';

const { TextArea } = Input;

// Formatea la fecha según el formato deseado

export default function ModalNuevoEvento({
  open,
  setOpen,
  mood = 'Eventos',
  item = null,
}) {
  const [selectedCards, setSelectedCards] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [formatoDeseado, setFormatoDeseado] = useState(null);
  const [, setRiskLevel] = useState(null);
  const [form] = Form.useForm();
  const { session, consultarEventos, locations } = useAuth();

  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (checkedValues) => {
    setCheckedItems(checkedValues);

    setSelectedCards(checkedValues);
  };

  // Datos de ejemplo para las cartas

  const [cards, setCards] = useState([
    { id: 0, value: 'amit', title: 'AMIT', checked: false, url: AMIT },
    {
      id: 1,
      value: 'agresion',
      title: 'Agresión',
      checked: false,
      url: Agresion,
    },
    {
      id: 2,
      value: 'clave-alfa',
      title: 'Clave Alfa',
      checked: false,
      url: ClaveAlfa,
    },
    { id: 3, value: 'delitos', title: 'Delitos', checked: false, url: Delitos },
    {
      id: 4,
      value: 'emergencias',
      title: 'Emergencias',
      checked: false,
      url: Emergencia,
    },
    { id: 5, value: 'hamcc', title: 'HAMCC', checked: false, url: HAMCC },
    {
      id: 6,
      value: 'intrusion',
      title: 'Intrusión',
      checked: false,
      url: Intrusion,
    },
    {
      id: 7,
      value: 'persona-fallecida',
      title: 'Fallecidos',
      checked: false,
      url: Fallecidos,
    },
    {
      id: 8,
      value: 'persona-herida',
      title: 'Heridos',
      checked: false,
      url: Heridos,
    },
    {
      id: 9,
      value: 'incumplimiento-al-reglamento-del-usuario',
      title: 'Incumplimiento reglamentos',
      checked: false,
      url: Incumplimiento,
    },
    {
      id: 10,
      value: 'terrorismo',
      title: 'Terrorismo',
      checked: false,
      url: Terrorismo,
    },
    {
      id: 11,
      value: 'clave-s',
      title: 'Clave S',
      checked: false,
      url: Suicide,
    },
  ]);

  const handleCardClick = (value) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.value === value ? { ...card, checked: !card.checked } : card
      )
    );

    setSelectedCards((prevSelectedCards) => {
      const cardIndex = prevSelectedCards.indexOf(value);
      if (cardIndex === -1) {
        return [...prevSelectedCards, value]; // Agregar el ID si no está en la lista
      } else {
        const updatedSelectedCards = [...prevSelectedCards];
        updatedSelectedCards.splice(cardIndex, 1); // Quitar el ID si ya está en la lista
        return updatedSelectedCards;
      }
    });
  };

  const CrearDatos = async (registro) => {
    try {
      const response = await eventService.crearEvento(registro);
      let mes =
        'El evento ' + response?.Item?.id + ' se ha creado correctamente.';
      message.success(mes, 15);

      let data = registro;
      data.id = response?.Item?.id || '';
      //setEventos([...eventos, data]);
      consultarEventos();
      if (mood === 'Alerta') {
        CambiarEstadoAlarma(item);
      }
      setOpen(false);
    } catch (error) {
      console.error('Error al crear datos:', error);
      message.error('No se logró hacer la solicitud.');
    }
    setLoadingButton(false);
  };

  const CambiarEstadoAlarma = async (registro) => {
    // try {
    //   const response = await eventService.crearEvento(registro);
    //   let mes =
    //     'El evento ' + response?.Item?.id + ' se ha creado correctamente.';
    //   message.success(mes, 15);
    //   let data = registro;
    //   data.id = response?.Item?.id || '';
    //   //setEventos([...eventos, data]);
    //   consultarEventos();
    //   if(mood === 'Alerta'){}
    //   setOpen(false);
    // } catch (error) {
    //   console.error('Error al crear datos:', error);
    //   message.error('No se logró hacer la solicitud.');
    // }
    // setLoadingButton(false);
  };

  const [selectedItems] = useState([]);
  const [selectedLaction, setSelectedLaction] = useState(null);
  const filteredOptions = locations.filter((o) => !selectedItems.includes(o));

  const [currentDateTime, setCurrentDateTime] = useState(days());

  const handleChangeDate = (date) => {
    setCurrentDateTime(date);
  };

  const handleSubmit = (values) => {
    setLoadingButton(true);
    const formattedDateTime = days(values.date).format('YYYY-MM-DDTHH:mm');
    let registro = {
      protocols: selectedCards,
      location: JSON.parse(values.Lugar),
      title: values.title,
      impact: 'leve',
      date: formattedDateTime + '-05:00',
      userId: session?.user?.id || session?.user?.sub,
      source: values?.source || 'Plataforma Cise',
    };

    if (mood === 'Alerta') {
      registro.alarmId = item.alarmaId;
      registro.alarmCreatedAt = item.alarmCreatedAt;
    }

    CrearDatos(registro);
  };

  const handleChangeSelect = (values) => {
    setSelectedLaction(JSON.parse(values));
  };

  useEffect(() => {
    form.resetFields();
    setSelectedCards([]);
    setRiskLevel(null);
    setCards([
      { id: 0, value: 'amit', title: 'AMIT', checked: false, url: AMIT },
      {
        id: 1,
        value: 'agresion',
        title: 'Agresión',
        checked: false,
        url: Agresion,
      },
      {
        id: 2,
        value: 'clave-alfa',
        title: 'Clave Alfa',
        checked: false,
        url: ClaveAlfa,
      },
      {
        id: 3,
        value: 'delitos',
        title: 'Delitos',
        checked: false,
        url: Delitos,
      },
      {
        id: 4,
        value: 'emergencia',
        title: 'Emergencia',
        checked: false,
        url: Emergencia,
      },
      { id: 5, value: 'hamcc', title: 'HAMCC', checked: false, url: HAMCC },
      {
        id: 6,
        value: 'intrusion',
        title: 'Intrusión',
        checked: false,
        url: Intrusion,
      },
      {
        id: 7,
        value: 'persona-fallecida',
        title: 'Fallecidos',
        checked: false,
        url: Fallecidos,
      },
      {
        id: 8,
        value: 'persona-herida',
        title: 'Heridos',
        checked: false,
        url: Heridos,
      },
      {
        id: 9,
        value: 'incumplimiento-al-reglamento-del-usuario',
        title: 'Incumplimiento reglamentos',
        checked: false,
        url: Incumplimiento,
      },
      {
        id: 10,
        value: 'terrorismo',
        title: 'Terrorismo',
        checked: false,
        url: Terrorismo,
      },
      {
        id: 11,
        value: 'clave-s',
        title: 'Clave S',
        checked: false,
        url: Suicide,
      },
    ]);

    const fecha = days(); // Obtén la fecha actual

    setFormatoDeseado(fecha.format('YYYY/MM/DD HH:mm:ss'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      title=""
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={'80%'}
      footer={false}
      style={{ paddingTop: '20px' }}
    >
      <div style={{ marginTop: '20px' }}>
        <PageHeader title="Crear Nuevo Evento" head="Eventos">
          <Title level={3}>Fecha: {formatoDeseado}</Title>
          {/* <span></span> */}
        </PageHeader>
        {/* <FiltrosTabla /> */}
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            date: currentDateTime,
            Lugar: '', // Establecer el valor inicial para Ubicación
            title:
              mood === 'Alerta'
                ? `Alarma - ${item.alertName} de ${item.component}`
                : '', // Establecer el valor inicial para Asunto de evento
            tipo: [], // Establecer el valor inicial para Tipo (Checkbox.Group)
            source:
              mood === 'Alerta'
                ? item.type === 'VMS'
                  ? 'Alarma Vms'
                  : 'Alarma Control de acceso'
                : '', // Establecer el valor inicial para Fuente de información
          }}
        >
          <Form.Item label="Fecha y Hora" name="date">
            <DatePicker
              showTime={{ format: 'HH:mm' }} // Configura el formato de la hora
              format="YYYY-MM-DD HH:mm" // Co
              style={{ width: '100%' }}
              value={currentDateTime}
              onChange={handleChangeDate}
            />
          </Form.Item>

          <Form.Item
            label="Ubicación"
            name="Lugar"
            rules={[
              {
                required: true,
                message: 'Debes de completar este campo.',
              },
            ]}
            extra={
              <div style={{ marginTop: '5px' }}>
                {selectedLaction && (
                  <>
                    <Tag>Zona {selectedLaction?.zone || 'z'}</Tag>
                    <Tag>{selectedLaction?.means || 'Medio'}</Tag>
                    <Tag>{selectedLaction?.type || 'Tipo'}</Tag>
                  </>
                )}
              </div>
            }
          >
            <Select
              placeholder="Inserte Ubicación del evento"
              value={selectedItems}
              onChange={handleChangeSelect}
              style={{
                width: '100%',
              }}
              showSearch
              options={filteredOptions.map((item) => ({
                value: JSON.stringify(item),
                label: item.specific + '-' + item.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Asunto de evento"
            name="title"
            rules={[
              {
                required: true,
                message: 'Debes de completar este campo.',
              },
            ]}
          >
            <TextArea />
            {/* <InputPropover /> */}
          </Form.Item>

          <Form.Item label="Tipo" name="tipo" className="textAjust">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(6, minmax(100px, 1fr)', // Tarjetas responsivas
                gap: '8px', // Espacio entre tarjetas
              }}
            >
              {cards.map((card) => (
                <Card
                  hoverable
                  key={card.id}
                  title={card.title}
                  style={{
                    width: '100%', // Tarjetas ocupan el 100% del espacio disponible en su contenedor
                    backgroundColor: card.checked ? '#e6f7ff' : 'white',
                    cursor: 'pointer',
                    padding: '5px',
                  }}
                  cover={<Image preview={false} src={card.url} />}
                  onClick={() => handleCardClick(card.value)}
                ></Card>
              ))}
            </div>
          </Form.Item>

          <Form.Item label="Tipo" name="tipo" className="componentWeb">
            <Checkbox.Group
              value={checkedItems}
              onChange={handleCheckboxChange}
            >
              <List
                dataSource={cards}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item.url} />}
                      title={
                        <Checkbox value={item.value}>{item.title}</Checkbox>
                      }
                    />
                  </List.Item>
                )}
              />
            </Checkbox.Group>
          </Form.Item>

          <Form.Item label="Fuente de información (Opcional)" name="source">
            <Select>
              <Select.Option value="Plataforma Cise">
                Plataforma Cise
              </Select.Option>
              <Select.Option value="Policía">Policía</Select.Option>
              <Select.Option value="Vigilancia privada">
                Vigilancia privada
              </Select.Option>
              <Select.Option value="Operador de medios tecnológicos">
                Operador de medios tecnológicos
              </Select.Option>
              <Select.Option value="Alarma Vms">Alarma Vms</Select.Option>
              <Select.Option value="Alarma Control de acceso">
                Alarma Control de acceso
              </Select.Option>

              <Select.Option value="IHR">IHR</Select.Option>
            </Select>
          </Form.Item>

          <Button
            htmlType="submit"
            style={{ marginTop: '20px' }}
            block
            type="primary"
            disabled={selectedCards.length === 0}
            loading={loadingButton}
            // disabled={NewUbicacion.direccion.length === 0}
          >
            Crear Evento
          </Button>
        </Form>
      </div>
    </Modal>
  );
}
