import gql from "graphql-tag";

export default gql`
query listAlarmsByStateAndDate($limit: Int, $nextToken: String, $state: String!, $createdAt: String!) {
  listAlarmsByStateAndDate(limit: $limit, nextToken: $nextToken, state: $state, createdAt: $createdAt) {
      nextToken
      alarms {
          id
          state
          __typename
          _lastChangedAt
          _version
          updatedAt
          createdAt
          stateHistory {
              Created {
                  userId
                  date
              }
              FalseAlarm {
                  userId
                  date
              }
              Associated {
                  userId
                  date
              }
          }
          item
          type
      }
  }
}
`