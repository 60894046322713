import React from 'react';
import { Space, Table } from 'antd';
import DeleteDocumentButton from './DeleteDocumentButton';

const Tabladocumentos = ({ dataExtras, dataProtocol, setRefresh, refresh }) => {
  // Extraemos la información de las llaves "extras" y "agresion"

  // Columnas para la tabla de "extras"
  const extrasColumns = [
    {
      title: 'Nombre de Actividad',
      dataIndex: 'protocol_activity_subactivity_id',
      key: 'protocol_activity_subactivity_id',
    },
    {
      title: 'Nombre del archivo',
      dataIndex: 'url',
      key: 'url',
      render: (text) => <>{text.split('/').pop().split('?')[0]}</>,
    },
    {
      title: 'Acciones',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <Space>
          <DeleteDocumentButton
            item={record}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Space>
      ),
    },
  ];

  // Columnas para la tabla de "agresion"
  const agresionColumns = [
    {
      title: 'Nombre de Actividad',
      dataIndex: 'protocol_activity_subactivity_id',
      key: 'protocol_activity_subactivity_id',
    },
    {
      title: 'Nombre del archivo',
      dataIndex: 'url',
      key: 'url',
      render: (text) => <>{text.split('/').pop().split('?')[0]}</>,
    },

    {
      title: 'Acciones',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <Space>
          <DeleteDocumentButton
            item={record}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2>Documentos generales </h2>
      <Table dataSource={dataExtras} columns={extrasColumns} />
      <h2>Documentos de protocolo</h2>
      <Table dataSource={dataProtocol} columns={agresionColumns} />
    </div>
  );
};

export default Tabladocumentos;
