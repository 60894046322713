import axios from 'axios';

const api = 'https://lcqea68lvi.execute-api.us-east-1.amazonaws.com/prod';
const apiKey = 'A0mDYHhECYauV96AWjuuMHknT25VRnJ84KUjaO6c';

const consultarEventos = async (
  startDate = null,
  endDate = null,
  starKey = null
) => {
  const path = '/events'; // Ruta del evento específico
  const apiUrl = `${api}${path}`;
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'x-api-key': apiKey,
      },

      params: {
        startDate: startDate,
        endDate: endDate,
        startKey: JSON.stringify(starKey),
        limit: 300,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al consultar eventos:', error);
    throw error;
  }
};

const consultarEventoParticular = async (eventId) => {
  const path = `/events/${eventId}`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'x-api-key': apiKey,
        Accept: 'application/json',
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar evento particular:', error);
    throw error;
  }
};

const crearEvento = async (registro) => {
  const path = `/events`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.post(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al crear evento:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};
// Llamar a la función para crear el evento
try {
  const nuevoEvento = await crearEvento();
  console.log('Nuevo evento creado:', nuevoEvento);
} catch (error) {
  console.error('Error al crear el evento:', error);
}

// const EditarEvento = async (eventId, registro) => {
//   const path = `/events/${eventId}`; // Ruta del evento específico
//   const apiUrl = `${api}${path}`;
//   const myHeaders = new Headers();
//   myHeaders.append('Content-Type', 'application/json'); // Cambiado a JSON

//   let raw = JSON.stringify(registro);

//   const requestOptions = {
//     method: 'PATCH',
//     headers: {
//       'Content-Type': 'application/json',
//       Accept: 'application/json',
//       'x-api-key': apiKey,
//     },
//     body: raw,
//     redirect: 'follow',
//   };

//   try {
//     const response = await fetch(apiUrl, requestOptions);
//     const data = await response.json(); // Parsear la respuesta a JSON
//     return data; // Devolver los datos obtenidos
//   } catch (error) {
//     console.error('Error:', error);
//     throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
//   }
// };

const EditarEvento = async (eventId, registro) => {
  const path = `/events/${eventId}`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.patch(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });

    return response.data; // Devolver los datos obtenidos from Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};
// funciones de las cuasas

const consultarCausas = async (eventId = null) => {
  let path = `/events/causes`; // Ruta del evento específico

  if (eventId) path = `/events/${eventId}/causes`;
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'x-api-key': apiKey,
        Accept: 'application/json',
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar evento particular:', error);
    throw error;
  }
};

const consultarTiposCausas = async (eventId = null) => {
  let path = `/events/causes/types`; // Ruta del evento específico

  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'x-api-key': apiKey,
        Accept: 'application/json',
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar evento particular:', error);
    throw error;
  }
};

const AsignarCausas = async (eventId, registro) => {
  const path = `/events/${eventId}/causes`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.post(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });

    return response.data; // Devolver los datos obtenidos from Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const ActualizarCausa = async (eventId, registro) => {
  const path = `/events/${eventId}/causes`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.patch(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });

    return response.data; // Devolver los datos obtenidos from Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

// const EliminarCausa = async (eventId, registro) => {
//   const path = `/events/${eventId}/causes`; // Ruta del evento específico
//   const apiUrl = `${api}${path}`;

//   try {
//     const response = await axios.delete(apiUrl, registro, {
//       headers: {
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//         'x-api-key': apiKey,
//       },
//     });

//     return response.data; // Devolver los datos obtenidos from Axios response.data
//   } catch (error) {
//     console.error('Error:', error);
//     throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
//   }
// };

const EliminarCausa = async (eventId, registro) => {
  const path = `/events/${eventId}/causes`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
      data: JSON.stringify(registro), // Convertir el objeto de registro a una cadena JSON
    });

    return response.data; // Devolver los datos obtenidos de Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};
// funciones implicados

const consultarImplicados = async (eventId = null, cuase = null) => {
  let path = `/events/${eventId}/causes/involved`; // Ruta del evento específico

  if (cuase)
    path = `/events/${eventId}/causes/involved?protocol_cause_id=${cuase}`;
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'x-api-key': apiKey,
        Accept: 'application/json',
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar evento particular:', error);
    throw error;
  }
};

const crearImplicado = async (eventId, registro) => {
  const path = `/events/${eventId}/causes/involved`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.post(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al crear evento:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

// let data = JSON.stringify({
//   "protocol_cause_id": "amit_afectacion-al-material-rodante_1072671425",
//   "name": "David",
//   "gender": "M",
//   "nationality": "CO"
// });

// let config = {
//   method: 'post',
//   maxBodyLength: Infinity,
//   url: 'https://lcqea68lvi.execute-api.us-east-1.amazonaws.com/prod/events/CISE-00018-2023/causes/involved',
//   headers: {
//     'x-api-key': 'A0mDYHhECYauV96AWjuuMHknT25VRnJ84KUjaO6c',
//     'Content-Type': 'application/json'
//   },
//   data : data
// };

// axios.request(config)
// .then((response) => {
//   console.log(JSON.stringify(response.data));
// })
// .catch((error) => {
//   console.log(error);
// });

const editarImplicado = async (eventId, registro) => {
  const path = `/events/${eventId}/involved`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.patch(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al crear evento:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

// const eliminarImplicado = async (eventId, registro) => {
//   const path = `/events/${eventId}/causes/involved`; // Ruta del evento específico
//   const apiUrl = `${api}${path}`;

//   try {
//     const response = await axios.delete(apiUrl, registro, {
//       headers: {
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//         'x-api-key': apiKey,
//       },
//     });
//     return response.data; // Devolver los datos obtenidos
//   } catch (error) {
//     console.error('Error al crear evento:', error);
//     throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
//   }
// };

const eliminarImplicado = async (eventId, registro) => {
  const path = `/events/${eventId}/causes/involved`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
      data: JSON.stringify(registro), // Convertir el objeto de registro a una cadena JSON
    });

    return response.data; // Devolver los datos obtenidos de Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const consultarReportes = async (eventId = null) => {
  let path = `/events/${eventId}/report`; // Ruta del evento específico

  if (eventId) path = `/events/${eventId}/report`;
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'x-api-key': apiKey,
        Accept: 'application/json',
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar evento particular:', error);
    throw error;
  }
};

const eventService = {
  crearEvento,
  consultarEventos,
  EditarEvento,
  consultarEventoParticular,
  consultarCausas,
  consultarImplicados,
  crearImplicado,
  editarImplicado,
  AsignarCausas,
  ActualizarCausa,
  EliminarCausa,
  eliminarImplicado,
  consultarReportes,
  consultarTiposCausas,
};

export default eventService;
