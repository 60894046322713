import React from 'react';
import { Table, Space } from 'antd';

import BotonEliminarImplicado from './BotonEliminarImplicado';

export default function TablaImplicados({
  items = [],
  loading,
  eventId,
  parm,
  obtenerImplicador,
}) {
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'id implicado',
      dataIndex: 'id',
      key: 'name',
    },
    {
      title: 'Genero',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Nacionalidad',
      dataIndex: 'nationality',
      key: 'nationality',
    },
    {
      title: 'Caracteristicas',
      dataIndex: 'features',
      key: 'features',
    },

    {
      title: 'Acciones',
      dataIndex: '',
      key: '',
      render: (record) => (
        <Space>
          {/* <Tooltip title="editar">
            <Button type="text">
              <EditOutlined />
            </Button>
          </Tooltip> */}
          <BotonEliminarImplicado
            item={record}
            eventId={eventId}
            obtenerImplicador={obtenerImplicador}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={items} loading={loading} />
    </div>
  );
}
