import React, { useState, useEffect } from 'react';
import { Tag, Card, Button, Select } from 'antd';
import MenuBoton from './MenuBoton';

const { Option } = Select;

const estadoFiltros = {
  InProgress: 'Ejecutando',
  FalseAlarm: 'Falsa alarma',
  Closed: 'Cerrado',
  Open: 'Abierto',
};

const CardMovilEvents = ({ dataSource, fetchData }) => {
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);
  const [filters, setFilters] = useState({
    id: null,
    zona: null,
    protocolo: null,
  });
  const applyFilters = () => {
    let filteredData = [...dataSource];

    if (filters.id) {
      filteredData = filteredData.filter((event) => event.id === filters.id);
    }

    if (filters.zona) {
      filteredData = filteredData.filter(
        (event) => event.location.zone === filters.zona
      );
    }

    if (filters.protocolo) {
      filteredData = filteredData.filter((event) =>
        event.protocols.includes(filters.protocolo)
      );
    }

    setFilteredDataSource(filteredData);
  };
  // const applyFilters = () => {
  //   let filteredData = [...dataSource];

  //   if (filters.id) {
  //     filteredData = filteredData.filter((event) => event.id === filters.id);
  //   }

  //   if (filters.zona) {
  //     filteredData = filteredData.filter(
  //       (event) => event.location.zone === filters.zona
  //     );
  //   }

  //   if (filters.protocolo) {
  //     filteredData = filteredData.filter((event) =>
  //       event.protocols.includes(filters.protocolo)
  //     );
  //   }

  //   setFilteredDataSource(filteredData);
  // };

  const clearFilters = () => {
    setFilters({
      id: null,
      zona: null,
      protocolo: null,
    });
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginBottom: '10px', display: 'flex' }}>
        <b>ID:</b>
        <Select
          style={{ flex: 1, marginLeft: 8 }}
          placeholder="Seleccionar ID"
          allowClear
          onChange={(value) => setFilters({ ...filters, id: value })}
        >
          {dataSource.map((event) => (
            <Option key={event.id} value={event.id}>
              {event.id}
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ marginBottom: '10px', display: 'flex' }}>
        <b>Zona:</b>
        <Select
          style={{ flex: 1, marginLeft: 8 }}
          placeholder="Seleccionar Zona"
          allowClear
          onChange={(value) => setFilters({ ...filters, zona: value })}
        >
          {dataSource.map((event) => (
            <Option key={event.location.zone} value={event.location.zone}>
              {event.location.zone}
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ marginBottom: '10px', display: 'flex' }}>
        <b>Protocolo:</b>
        <Select
          style={{ flex: 1, marginLeft: 8 }}
          placeholder="Seleccionar Protocolo"
          allowClear
          onChange={(value) => setFilters({ ...filters, protocolo: value })}
        >
          {dataSource
            .flatMap((event) => event.protocols)
            .map((protocol) => (
              <Option key={protocol} value={protocol}>
                {protocol}
              </Option>
            ))}
        </Select>
      </div>
      <Button
        type="primary"
        onClick={clearFilters}
        style={{ marginBottom: 10 }}
      >
        Limpiar Filtros
      </Button>
      {filteredDataSource.map((event, index) => (
        <Card
          key={event.id}
          style={{ marginBottom: '10px' }}
          title={event.id}
          extra={
            <MenuBoton key={event.id} item={event} fetchData={fetchData} />
          }
        >
          <span>{event.title}</span>
          <p>
            <b>Ubicación:</b>
            <span>
              {`${event.location.specific} - Zona: ${event.location.zone}`}
            </span>
          </p>

          <p>
            <b>Estado:</b>
            <span>
              {event.state === 'InProgress' && (
                <Tag key={event.state} color="blue">
                  {estadoFiltros[event.state]}
                </Tag>
              )}
              {event.state === 'FalseAlarm' && (
                <Tag key={event.state} color="red">
                  {estadoFiltros[event.state]}
                </Tag>
              )}
              {event.state === 'Closed' && (
                <Tag key={event.state} color="green">
                  {estadoFiltros[event.state]}
                </Tag>
              )}
              {event.state === 'Open' && (
                <Tag key={event.state} color="yellow">
                  {estadoFiltros[event.state]}
                </Tag>
              )}
            </span>
          </p>
          <div>
            <ul key={index} style={{ marginBottom: 8 }}>
              {event.protocols.map((row, index) => (
                <li>{row}</li>
              ))}
            </ul>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default CardMovilEvents;
