import React, { useState, useEffect } from 'react';
import { Spin, Table, message, Result } from 'antd';
import columsActividades from './columsTables/columsActividades';
import activitiesService from '../../services/activitiesService';
import BotonMultiproposito from './components/BotonMultiproposito';
import useAuth from '../../hooks/useAuth';
import SeguimientodeIntrusion from '../Listas/SeguimientodeIntrusion';

export default function TablaActividades({
  loading,
  itemsData,
  eventId,
  consultaProtocolos,
  setFilteredItemCount,
}) {
  const [expandedRow] = useState(false);
  const { session } = useAuth();
  const [expandedRowData, setExpandedRowData] = useState({});

  const consultaACTIVIDADESIniciar = async (record) => {
    setExpandedRowData(null);
    try {
      const response = await activitiesService.consultarActividadEspecifica(
        record.eventId,
        record.protocol
      );
      let datos = ajusteArray(response?.Items || []);

      consultaProtocolos();
      return datos || [];
    } catch (error) {
      console.error('Error fetching data:', error);

      message.error('No se logró hacer la consulta');
    }
  };

  const consultaACTIVIDADES = async (record) => {
    setExpandedRowData(null);
    try {
      const response = await activitiesService.consultarActividadEspecifica(
        record.eventId,
        record.protocol
      );
      // Lógica para ajustar el arra
      let datos = ajusteArray(response?.Items || []);

      return datos || [];
    } catch (error) {
      console.error('Error fetching data:', error);

      message.error('No se logró hacer la consulta');
    }
  };

  const ajusteArray = (data) => {
    const sortedData = data.sort((a, b) => {
      const orderA = parseFloat(a.order);
      const orderB = parseFloat(b.order);

      // Comparar los valores como números
      return orderA - orderB;
    });

    return sortedData;
  };

  const handleExpand = async (expanded, record) => {
    if (expanded) {
      // Ejecutar la función asincrónica al expandir la fila
      const result = await consultaACTIVIDADES(record);
      setExpandedRowData(result);
    }
  };

  const expandedRowRender = (record) => {
    // setExpandedRow(!expandedRow);
    return (
      <>
        {expandedRowData ? (
          <>
            {record.state === 'Created' ? (
              <Result
                status="warning"
                title="Aun no has iniciado el protocolo"
                extra={
                  <BotonMultiproposito
                    record={record}
                    type="iniciar"
                    typeButton="primary"
                    fun={consultaACTIVIDADESIniciar}
                  />
                }
              />
            ) : (
              <ExpandedRowContent
                record={record}
                expanded={expandedRow}
                items={expandedRowData}
                consultaProtocolos={consultaProtocolos}
              />
            )}
          </>
        ) : (
          <>
            <Spin />
          </>
        )}
      </>
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // Calcular la cantidad de elementos después del filtro
    const filteredItems = itemsData.filter((item) => {
      let isValid = true;
      for (const key in filters) {
        if (filters[key] && filters[key].length > 0) {
          isValid = isValid && item[key].toString().startsWith(filters[key][0]);
        }
      }
      return isValid;
    });

    setFilteredItemCount(filteredItems.length);
  };

  return (
    <>
      <Table
        loading={loading}
        rowKey={'idKey'}
        columns={columsActividades(itemsData, consultaProtocolos, session)}
        expandable={{
          expandedRowRender,
          onExpand: handleExpand,
          rowExpandable: (record) => record.state !== 'Created',
        }}
        dataSource={itemsData}
        onChange={handleTableChange}
        locale={{
          filterTitle: 'Filtrar',
          filterConfirm: 'Aceptar',
          filterReset: 'Limpiar',
          emptyText: 'Sin datos',
        }}
      />
    </>
  );
}

const ExpandedRowContent = ({
  record,
  expanded,
  items,
  consultaProtocolos,
}) => {
  const [activitiesItems, setActivities] = useState(items || null);

  const [documentosActividades, setDocumentosActividades] = useState({});
  const ajusteArray = (data) => {
    const sortedData = data.sort((a, b) => {
      const orderA = parseFloat(a.order);
      const orderB = parseFloat(b.order);

      // Comparar los valores como números
      return orderA - orderB;
    });

    return sortedData;
  };
  const consultaACTIVIDADES = async () => {
    // setActivities(null);
    try {
      const response = await activitiesService.consultarActividadEspecifica(
        record.eventId,
        record.protocol
      );
      // Lógica para ajustar el array
      let data = ajusteArray(response?.Items || []);
      setActivities(data || []);
      consultaDocumentos();
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logró hacer la consulta');
    }
  };

  const consultaDocumentos = async () => {
    // setActivities(null);
    try {
      const response = await activitiesService.consultardocumentoPorProtocolo(
        record.eventId,
        record.protocol
      );
      setDocumentosActividades(response?.data[record.protocol] || {});
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logró hacer la consulta');
    }
  };

  useEffect(() => {
    //  consultaACTIVIDADES();
    consultaDocumentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <>
      {activitiesItems ? (
        <SeguimientodeIntrusion
          item={activitiesItems || []}
          refreshEventos={consultaACTIVIDADES}
          consultaProtocolos={consultaProtocolos}
          documentosActividades={documentosActividades}
          protocol={record.protocol}
        />
      ) : (
        <Spin />
      )}
    </>
  );
};
