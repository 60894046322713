import React, { useState, useEffect } from 'react';
import { Tag, Card, Button, Select, Tooltip } from 'antd';
import BotonMultiproposito from '../../../Tablas/components/BotonMultiproposito';
import ModalActividadesMobile from './ModalActividadesMobile';

const { Option } = Select;

const estadoFiltros = {
  Started: 'Iniciado',
  FalseAlarm: 'Falsa alarma',
  Completed: 'Completado',
  Created: 'Creado',
};

const CardProtocolos = ({ dataSource, fetchData }) => {
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);
  const [filters, setFilters] = useState({
    id: null,
    zona: null,
    protocolo: null,
  });
  const applyFilters = () => {
    let filteredData = [...dataSource];

    if (filters.id) {
      filteredData = filteredData.filter(
        (event) => event.eventId === filters.id
      );
    }

    // if (filters.zona) {
    //   filteredData = filteredData.filter(
    //     (event) => event.location.zone === filters.zona
    //   );
    // }

    if (filters.protocolo) {
      filteredData = filteredData.filter(
        (event) => event.protocol === filters.protocolo
      );
    }

    setFilteredDataSource(filteredData);
  };
  // const applyFilters = () => {
  //   let filteredData = [...dataSource];

  //   if (filters.id) {
  //     filteredData = filteredData.filter((event) => event.id === filters.id);
  //   }

  //   if (filters.zona) {
  //     filteredData = filteredData.filter(
  //       (event) => event.location.zone === filters.zona
  //     );
  //   }

  //   if (filters.protocolo) {
  //     filteredData = filteredData.filter((event) =>
  //       event.protocols.includes(filters.protocolo)
  //     );
  //   }

  //   setFilteredDataSource(filteredData);
  // };

  const clearFilters = () => {
    setFilters({
      id: null,
      zona: null,
      protocolo: null,
    });
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginBottom: '10px', display: 'flex' }}>
        <b>ID:</b>
        <Select
          style={{ flex: 1, marginLeft: 8 }}
          placeholder="Seleccionar ID"
          allowClear
          onChange={(value) => setFilters({ ...filters, id: value })}
        >
          {dataSource.map((event) => (
            <Option key={event.id} value={event.id}>
              {event.id}
            </Option>
          ))}
        </Select>
      </div>
      {/* <div style={{ marginBottom: '10px', display: 'flex' }}>
        <b>Zona:</b>
        <Select
          style={{ flex: 1, marginLeft: 8 }}
          placeholder="Seleccionar Zona"
          allowClear
          onChange={(value) => setFilters({ ...filters, zona: value })}
        >
          {dataSource.map((event) => (
            <Option key={event.location.zone} value={event.location.zone}>
              {event.location.zone}
            </Option>
          ))}
        </Select>
      </div> */}
      <div style={{ marginBottom: '10px', display: 'flex' }}>
        <b>Protocolo:</b>
        <Select
          style={{ flex: 1, marginLeft: 8 }}
          placeholder="Seleccionar Protocolo"
          allowClear
          onChange={(value) => setFilters({ ...filters, protocolo: value })}
        >
          {dataSource.map((event, index) => (
            <Option key={index} value={event.protocol}>
              {event.protocol}
            </Option>
          ))}
        </Select>
      </div>
      <Button
        type="primary"
        onClick={clearFilters}
        style={{ marginBottom: 10 }}
      >
        Limpiar Filtros
      </Button>
      {filteredDataSource.map((event, index) => (
        <Card
          key={`${event.protocol}-${event.eventId}`}
          style={{ marginBottom: '10px' }}
          title={`${event.protocol}-${event.eventId}`}
          extra={
            <>
              {event.state === 'Created' ? (
                <>
                  <Tooltip title="Iniciar protocolo">
                    <BotonMultiproposito
                      record={event}
                      type={'iniciar'}
                      fun={fetchData}
                    />
                  </Tooltip>
                </>
              ) : (
                <ModalActividadesMobile record={event} fechData={fetchData} />
              )}
            </>
          }
        >
          <p>
            <b>Estado:</b>
            <span>
              {event.state === 'Started' && (
                <Tag key={event.state} color="blue">
                  {estadoFiltros[event.state]}
                </Tag>
              )}
              {event.state === 'FalseAlarm' && (
                <Tag key={event.state} color="red">
                  {estadoFiltros[event.state]}
                </Tag>
              )}
              {event.state === 'Completed' && (
                <Tag key={event.state} color="green">
                  {estadoFiltros[event.state]}
                </Tag>
              )}
              {event.state === 'Created' && (
                <Tag key={event.state} color="yellow">
                  {estadoFiltros[event.state]}
                </Tag>
              )}
            </span>
          </p>
        </Card>
      ))}
    </div>
  );
};

export default CardProtocolos;
