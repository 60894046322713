import React from 'react';
import { Divider, Image, Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import logo from '../../assets/images/logo_metroFull.png';

export default function PageHeader({
  children,
  title = 'CISE',
  head = 'Administración',
  createby = '',
}) {
  return (
    <div>
      <div className="md:flex justify-between items-center">
        <div>
          <Row gutter={10}>
            <Col>
              {' '}
              <Image
                style={{ width: '60px' }}
                src={logo}
                preview={false}
                alt="Logo App"
              />
            </Col>
            <Col>
              <div>
                {' '}
                <span>{head}</span>
                <Title level={3}>{title}</Title>
              </div>
              <span>{createby}</span>
            </Col>
          </Row>
        </div>
        <div>{children}</div>
      </div>
      <Divider />
    </div>
  );
}
