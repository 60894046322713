import React, { useState } from 'react';
import { Space, Button, Tooltip } from 'antd';
import ModalNuevoEvento from '../../../components/modales/eventos/ModalNuevoEvento';
import { AlertOutlined } from '@ant-design/icons';

export default function BotonCrear({ item }) {
  const [show, setShow] = useState();
  return (
    <>
      <Space size="middle">
        <Tooltip title="Crear Evento">
          <Button type="text" onClick={() => setShow(true)}>
            <AlertOutlined />
          </Button>
        </Tooltip>
        {/* Add your custom actions/buttons here */}
      </Space>

      <ModalNuevoEvento
        open={show}
        setOpen={setShow}
        mood={'Alerta'}
        item={item}
      />
    </>
  );
}
