import React, { useState, useEffect } from 'react';

import {  Modal, message } from 'antd';
import Descripciongeneral from './componentesPdf/Descripciongeneral';
import eventService from '../../../services/eventService';
import DescripcionProtocolo from './componentesPdf/DescripcionProtocolo';
import Loading from '../../layouts/Loading';
import EjemploPdf from './componentesPdf/EjemploPdf';

export default function ModalInformePDF({ open, setOpen, item }) {
  const [loadingPDF, setLoadingPDF] = useState(true);
  const [infoEvent, setInfoEvent] = useState(null);
  // const generarPdf = async () => {
  //   setLoading(true);
  //   const doc = new jsPDF();

  //   const container = document.getElementById('paginaPincipal');

  //   const addPageWithContent = async (contentId) => {
  //     const container = document.getElementById(contentId);
  //     const canvas = await html2canvas(container);
  //     const imageData = canvas.toDataURL('image/png');

  //     // Añadir página con el mismo tamaño que A4 después de la primera página
  //     if (doc.internal.getNumberOfPages() > 0) {
  //       doc.addPage('a4');
  //     } else {
  //       doc.addImage(imageData, 'PNG', 10, 10);
  //     }
  //     doc.addImage(imageData, 'PNG', 10, 10);
  //   };

  //   const canvas = await html2canvas(container);
  //   const imageData = canvas.toDataURL('image/png');

  //   doc.addImage(imageData, 'PNG', 10, 10);

  //   const protocolsArray = infoEvent.protocols;

  //   // Utiliza Promise.all para esperar a que todas las promesas se completen
  //   await Promise.all(
  //     protocolsArray.map(async (protocol, index) => {
  //       // Aquí dentro, ejecutas la promesa para cada valor del array
  //       await addPageWithContent(`pagina${index}`);
  //     })
  //   );

  //   // Puedes agregar lógica adicional para los detalles si es necesario

  //   // Guardar el PDF
  //   doc.save(`Informe_General_Evento_${item?.id || 'cise-xxxx-xxx'}.pdf`);
  //   setLoading(false);
  //   message.success('Pdf creado');
  // };


  const consultaReportes = async () => {
    setLoadingPDF(true);
    try {
      const response = await eventService.consultarReportes(item.id);
      let data = response;
      setInfoEvent(data);
      setLoadingPDF(false);
    } catch (error) {
      message.error('No se logro hacer la consulta');
      setLoadingPDF('error');
    }
  };

  useEffect(() => {
    if (open) consultaReportes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {open && (
        <Modal
    
          visible={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          footer={null}
          width={'880px'}
        >
          <div>
            {!loadingPDF && (

              <>
               <EjemploPdf itemsGeneral={infoEvent}/>
               <div className="scrollable-div">
                {infoEvent ? (
                  <>
                    <div className="pagina-A4" id="paginaPincipal">
                      <Descripciongeneral item={infoEvent} />
                    </div>

                    {infoEvent.protocols.map((objeto, index) => (
                      <div className="pagina-A4" id={`pagina${index}`}>
                        <DescripcionProtocolo
                          key={index}
                          itemProtocol={objeto}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </div>
              </>
             
             
            )}

            {loadingPDF && <Loading />}
            {loadingPDF === 'error' && <Loading />}
          </div>
          
      
        </Modal>
      )}
    </>
  );
}
