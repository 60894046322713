import React, { useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
import BotonCrear from './BotonCrear';
import useAuth from '../../../hooks/useAuth';

dayjs.extend(advancedFormat);
dayjs.locale('es-mx');

const pageSize = 5;
const states = {
  Created: 'Creado',
};
const types = {
  VMS: 'VMS',
  Access: 'Acceso',
};
const TablaAlarmas = ({ setContador }) => {
  const { session, alarmsSession } = useAuth();
  const { alarms, loading, setLastItem } = alarmsSession;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLastItem(page * pageSize);
    if (loading) return;
    setContador(alarms.filter((alarm) => alarm.state === 'Created').length);

    const firstIndex = (page - 1) * pageSize;
    const lastIndex = firstIndex + pageSize;
    const normAlarms = alarms.slice(firstIndex, lastIndex).map((alarm) => {
      let timeStamp = new Date(alarm.item.Timestamp);
      timeStamp.setHours(timeStamp.getHours() - 5);

      const { updatedAt, state, type, id, createdAt } = alarm;

      let result = {
        updatedAt: dayjs(updatedAt).format(
          'DD [de] MMMM [de] YYYY [a] [las] HH:mm:ss a'
        ),
        state: states[state],
        type: types[type],
        alarmaId: id || '512',
        alarmCreatedAt: createdAt || '2024-01-29T10:19:00.000Z',
      };
      switch (type) {
        case 'VMS':
          const { Name, SourceName } = alarm.item;
          result = {
            ...result,
            alertName: Name,
            component: SourceName,
          };
          break;

        case 'Access':
          const { eventName, devName } = alarm.item;

          result = {
            ...result,
            alertName: eventName,
            component: devName,
          };
          break;
        default:
          break;
      }
      return result;
    });
    setData(normAlarms);
  }, [page, alarms, loading, setContador, setLastItem]);

  const columns = [
    {
      title: 'Nombre Alerta',
      dataIndex: 'alertName',
      key: 'alertName',
    },
    {
      title: 'Componente',
      dataIndex: 'component',
      key: 'component',
    },
    {
      title: 'Fecha de última actualización',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (text, record) => (
        <>
          {session.roles.events.isAllowedtoCreate && (
            <BotonCrear item={record} />
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
      <div id="alarmsTableContainer">
        <Pagination
          defaultPageSize={pageSize}
          total={alarms.length}
          onChange={setPage}
        />
      </div>
    </>
  );
};

export default TablaAlarmas;
