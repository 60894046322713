import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Input, message } from 'antd';
import ModalComentario from '../../modales/generales/ModalComentario';

import activitiesService from '../../../services/activitiesService';
import useAuth from '../../../hooks/useAuth';
import TablaSubActividades from './TablaSubActividades';

export default function ModalListasubActividades({
  subActivities,
  selector,
  consultaEventos,
  funcionCerra,
  consultaIniciar,
}) {
  const { session } = useAuth();

  const [ViewTable] = useState(true);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [falsaAlarma, setFalsaAlarma] = useState(false);
  const [form] = Form.useForm();
  const validateMessages = {
    required: 'Es requerido',
    types: {
      email: 'Correo no valido',
      number: 'Numero no valido',
    },
  };

  const Iniciar = (item) => {
    const registro = {
      eventId: item.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      start: true,
      order: item.order,
    };
    ejecutarActividad(registro);
  };

  const ejecutarActividad = async (registro) => {
    setLoading(true);
    try {
      await activitiesService.funcionActividad(registro);
      message.success('Actividad Iniciada');
      setOpen(true);
    } catch (error) {
      console.error(error);
      message.error('No se logro hacer la consulta');
    }
    setLoading(false);
  };

  const onFinish = (values) => {
    const { comment } = values;

    Completar(comment);
  };

  const Completar = (comment) => {
    let registro = {
      eventId: selector.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: selector.protocol_activity_subactivity,
      complete: true,
      order: selector.order,
      comment,
    };

    const contienePalabras =
      selector.protocol_activity_subactivity.includes('falsa') &&
      selector.protocol_activity_subactivity.includes('alarma');

    if (contienePalabras) {
      registro.falseAlarm = false;
    }
    finalizarActividad(registro);
  };

  const finalizarActividad = async (registro) => {
    setLoading(true);
    try {
      await activitiesService.funcionActividad(registro);
      message.success('Actividad Completada');
      setOpen(false);
      consultaIniciar();
    } catch (error) {
      console.error(error);
      message.error('No se logro hacer la consulta');
    }
    setLoading(false);
  };

  const cancel = () => {
    funcionCerra();
    setOpen(false);
  };

  useEffect(() => {
    form.resetFields();
    setFalsaAlarma(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {selector.state === 'Created' && (
        <>
          <Button
            type="primary"
            loading={loading}
            key="console"
            onClick={() => Iniciar(selector)}
            style={{ marginBottom: '10px', width: '200px' }}
          >
            Iniciar
          </Button>
          {selector.isMandatory === false && (
            <ModalComentario
              item={selector}
              consultaEventos={consultaIniciar}
              label={'Saltar'}
            />
          )}
          {ViewTable && <TablaSubActividades data={subActivities} />}

          <Modal
            title={
              falsaAlarma ? 'Justificar Falsa Alarma' : 'Justificar Acción'
            }
            visible={open}
            onOk={() => setOpen(false)}
            onCancel={() => cancel()}
            footer={null}
          >
            <Form
              layout="vertical"
              name="nest-messages"
              onFinish={onFinish}
              style={{
                maxWidth: 600,
              }}
              validateMessages={validateMessages}
            >
              <Form.Item
                name="comment"
                label="Comentario"
                rules={[
                  {
                    required: true,
                    message: 'Debes de completar este campo.',
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Enviar
              </Button>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
}
