import axios from 'axios';

const api = 'https://f2r8ymqcse.execute-api.us-east-1.amazonaws.com/prod';
const apiKey = '';

const consultarCamaras = async () => {
  // const path = `/locations/arcgis/camaras`; // Ruta del evento específico
  const apiUrl =
    'https://f2r8ymqcse.execute-api.us-east-1.amazonaws.com/prod/locations/arcgis/camaras';

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Accept: 'application/json',
        // 'x-api-key': apiKey,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar actividades:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const consultarUrlDashBoard = async () => {
  // const path = `/locations/arcgis/camaras`; // Ruta del evento específico
  const apiUrl =
    'https://hyxntxhtt5.execute-api.us-east-1.amazonaws.com/prod/reports';

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Accept: 'application/json',
        // 'x-api-key': apiKey,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar actividades:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const consultarActividadEspecifica = async (eventId, protocoloId) => {
  const path = `/activities?eventId=${eventId}&protocol_activity_subactivity=${protocoloId}`;

  let apiUrl = `${api}/activities?eventId=${eventId}`;

  if (protocoloId) {
    apiUrl = `${api}${path}`;
  } // Ruta del evento específico

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar actividades:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const funcionActividad = async (registro) => {
  const path = `/activities`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json'); // Cambiado a JSON

  let raw = JSON.stringify(registro);

  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-api-key': apiKey,
    },
    body: raw,
    redirect: 'follow',
  };

  // const requestOptions = {
  //   method: 'POST',
  //   headers: myHeaders,
  //   body: JSON.stringify(registro), // Convertir el objeto a JSON
  //   redirect: 'follow',
  // };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json(); // Parsear la respuesta a JSON
    return data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const consultarLogs = async (service = 'events') => {
  // const path = `/locations/arcgis/camaras`; // Ruta del evento específico
  const apiUrl =
    'https://dlpf7d7mtc.execute-api.us-east-1.amazonaws.com/prod/logs';

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Accept: 'application/json',
        'x-api-key': process.env.REACT_APP_KEY_EXTRAS || '',
      },
      params: {
        service,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar actividades:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const extraService = {
  consultarCamaras,
  consultarActividadEspecifica,
  funcionActividad,
  consultarUrlDashBoard,
  consultarLogs,
};

export default extraService;
