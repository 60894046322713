import React, { useState, useEffect } from 'react';
import { Table, Modal } from 'antd';
import PageHeader from '../../headers/PageHeader';
import TablaEventos from './components/TablaEventos';
import BotonExportarExcelM from '../../botones/BotonExportarExcelM';
import axios from 'axios';
import DateRangeFilter from './components/DateRangeFilter';
import CardMovilEvents from './components/CardMovilEvents';

export default function ModalEventos({ open, setOpen }) {
  const [loadingTable, setLoadingTable] = useState(true);
  const [eventTable, setEventTable] = useState([]);
  const [, setEventFilter] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [filters] = useState({});
  const [filteredItemCount, setFilteredItemCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [
    {
      title: 'Evento Id',

      dataIndex: 'id',
      key: 'id',
    },

    {
      title: 'Cod ',
      dataIndex: 'specificLocation',
      key: 'specificLocation',
    },

    {
      title: 'Nombre',
      dataIndex: 'nombrelocation',
      key: 'nombrelocation',
    },
    {
      title: 'Zona',
      dataIndex: 'zoneLocation',
      key: 'zoneLocation',
    },
    {
      title: 'Tipo',
      dataIndex: 'protocols',
      key: 'protocols',

      aling: 'center',
    },
    {
      title: 'Impacto',
      width: 100,
      dataIndex: 'impact',
      key: 'impact',
      aling: 'center',
    },
    {
      title: 'Nivel de riesgo',
      dataIndex: 'risk',
    },
    {
      title: 'Asunto',

      dataIndex: 'title',
      key: 'title',
    },

    {
      title: 'Estado',
      dataIndex: 'eventState',
      key: 'eventState',
    },
    {
      title: 'Costo',
      dataIndex: 'totalCost',
      key: 'totalCost',
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    // Calcular la cantidad de elementos después del filtro

    const filteredItems = eventTable.filter((item) => {
      let isValid = true;
      for (const key in filters) {
        if (filters[key] && filters[key].length > 0) {
          isValid = isValid && item[key].toString().startsWith(filters[key][0]);
        }
      }
      return isValid;
    });

    setFilteredItemCount(filteredItems.length);
  };

  const fetchData = async () => {
    const myHeaders = {
      Accept: 'application/json',
      'x-api-key': 'A0mDYHhECYauV96AWjuuMHknT25VRnJ84KUjaO6c',
    };

    try {
      const response = await axios.get(
        'https://lcqea68lvi.execute-api.us-east-1.amazonaws.com/prod/events',
        {
          headers: myHeaders,
        }
      );

      const data = response.data;

      if (!Array.isArray(data.Items)) {
        throw new Error('Los datos no son un array válido.');
      }

      const arrayEvents = data.Items.filter(
        (item) =>
          item.id &&
          typeof item.id === 'string' &&
          item.id.match(/^CISE-\d{5}-\d{4}$/)
      )
        .map((item) => ({
          ...item,
          nameLocation: item.location.name || null, // Asegurarse de manejar valores nulos o indefinidos
        }))
        .sort((a, b) => {
          const idA = parseInt(a.id.split('-')[1]);
          const idB = parseInt(b.id.split('-')[1]);

          return idA - idB;
        });
      AjusteArray(arrayEvents);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoadingTable(false);
  };

  useEffect(() => {
    // Este código se ejecutará solo cuando `open` sea `true`
    setLoadingTable(true);
    setEventTable([]);

    if (open) fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setDocumentos(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const AjusteArray = (arrayEvents) => {
    const Events = arrayEvents
      .filter(
        (item) =>
          item.id &&
          typeof item.id === 'string' &&
          item.id.match(/^CISE-\d{5}-\d{4}$/)
      )
      .sort((a, b) => {
        const idA = parseInt(a.id.split('-')[1]); // Obtener el número del campo 'id' para el objeto 'a'
        const idB = parseInt(b.id.split('-')[1]); // Obtener el número del campo 'id' para el objeto 'b'
        return idB - idA; // Ordenar de mayor a menor
      });

    setEventTable(Events);
    setEventFilter(Events);
    setFilteredItemCount(Events.length || 0);
  };
  return (
    <Modal
      title=""
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={'auto'}
      footer={false}
    >
      <div>
        <PageHeader title="Registro de Eventos" head="Eventos">
          <BotonExportarExcelM documentos={documentos} columns={columns} />
        </PageHeader>

        <div>
          <DateRangeFilter
            type="eventos"
            filteredItemCount={filteredItemCount}
            loadingButton={loadingTable}
            setLoadingButton={setLoadingTable}
            AjusteArray={AjusteArray}
          />
        </div>
        <div className="componentWeb">
          <CardMovilEvents dataSource={eventTable} fetchData={fetchData} />
        </div>

        {/* <Table
          className="textAjust"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          rowKey="id"
          style={{ marginTop: '10px' }}
          loading={loadingTable}
          responsive={{
            xs: 'scroll',
            sm: 'scroll',
            md: 'scroll',
            lg: 'scroll',
          }}
          dataSource={eventTable}
          columns={TablaEventos(eventTable, fetchData)}
          onChange={handleTableChange} // Capturar cambios en los filtros
          scroll={{ x: 'max-content' }}
          // Aplicar los filtros almacenados
          {...(Object.keys(filters).length > 0 ? { filters: filters } : {})}
          locale={{
            filterTitle: 'Filtrar',
            filterConfirm: 'Aceptar',
            filterReset: 'Limpiar',
            emptyText: 'Sin datos',
          }}
          pagination={{
            pageSize: 10, // Número predeterminado de elementos por página
            pageSizeOptions: ['10', '25', '50'], // Opciones del selector de cantidad por página
            showSizeChanger: true, // Mostrar el selector de cantidad por página
            // Otros props de paginación si es necesario
          }}
        /> */}
        <Table
          className="textAjust"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          rowKey="id"
          style={{ marginTop: '10px' }}
          // Asegúrate de tener tu loadingTable definida
          loading={loadingTable}
          responsive={{
            xs: 'scroll',
            sm: 'scroll',
            md: 'scroll',
            lg: 'scroll',
          }}
          dataSource={eventTable}
          columns={TablaEventos(eventTable, fetchData)}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          {...(Object.keys(filters).length > 0 ? { filters: filters } : {})}
          locale={{
            filterTitle: 'Filtrar',
            filterConfirm: 'Aceptar',
            filterReset: 'Limpiar',
            emptyText: 'Sin datos',
          }}
        />
      </div>
    </Modal>
  );
}
