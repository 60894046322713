import React, { useState } from 'react';
import { DatePicker, Button, Statistic, message, Tooltip } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Importa el complemento para trabajar con UTC
import timezone from 'dayjs/plugin/timezone'; // Importa el complemento para trabajar con zonas horarias
import protocolosService from '../../../../services/protocolosService';
import eventService from '../../../../services/eventService';
dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

const DateRangeFilter = ({
  type = 'eventos',
  filteredItemCount = 0,
  loadingButton,
  setLoadingButton,
  AjusteArray,
}) => {
  const endDate = dayjs().format('YYYY-MM-DD'); // Fecha actual en formato "YYYY-MM-DD"
  const startDate = dayjs().subtract(15, 'days').format('YYYY-MM-DD');
  const [range, setRange] = useState([startDate, endDate]);

  const onChange = (value, dateString) => {
    setRange(dateString);
  };

  function convertirAFechaUTC(fechaHoraLocalString, zonaHorariaLocal) {
    // Configura la zona horaria local
    dayjs.tz.setDefault(zonaHorariaLocal);

    // Parsea la fecha y hora local
    const fechaHoraLocal = dayjs(fechaHoraLocalString);

    // Convierte la fecha y hora local a UTC
    const fechaHoraUTC = fechaHoraLocal.utc();

    // Formatea la fecha y hora UTC en el formato deseado
    const fechaHoraUTCString = fechaHoraUTC.format(
      'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
    );

    return fechaHoraUTCString;
  }
  const onOk = () => {
    const zonaHorariaLocal = 'America/Bogota';

    const fechaHoraUTCstart = convertirAFechaUTC(
      range[0] + ' 00:00:00',
      zonaHorariaLocal
    );
    const fechaHoraUTCend = convertirAFechaUTC(
      range[1] + ' 23:59:59',
      zonaHorariaLocal
    );

    if (type === 'eventos') {
      consultaEventsPorFechas(fechaHoraUTCstart, fechaHoraUTCend);
    } else if (type === 'protocolos') {
      consultaProtocolosPorFechas(fechaHoraUTCstart, fechaHoraUTCend);
    }
  };

  const onDelete = () => {
    // Fecha actual menos 15 días en formato "YYYY-MM-DD"
    setRange([startDate, endDate]);

    if (type === 'eventos') {
      consultaEventsPorFechas();
    } else if (type === 'protocolos') {
      consultaProtocolosPorFechas();
    }
  };

  // const consultaProtocolosPorFechas = async (
  //   startDate = null,
  //   endDate = null
  // ) => {
  //   setLoadingButton(true);
  //   try {
  //     const response = await protocolosService.consultarProtocolo(
  //       startDate,
  //       endDate
  //     );
  //     let data = response;

  //     const arrayEvents = data.Items.filter(
  //       (item) =>
  //         item.eventId &&
  //         typeof item.eventId === 'string' &&
  //         item.eventId.match(/^CISE-\d{5}-\d{4}$/)
  //     ).sort((a, b) => {
  //       const idA = parseInt(a.eventId.split('-')[1]); // Obtener el número del campo 'id' para el objeto 'a'
  //       const idB = parseInt(b.eventId.split('-')[1]); // Obtener el número del campo 'id' para el objeto 'b'
  //       return idB - idA; // Ordenar de mayor a menor
  //     });

  //     AjusteArray(arrayEvents || []);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     message.error('No se logro hacer la consulta');
  //   }
  //   setLoadingButton(false);
  // };

  const consultaEventsPorFechas = async (startDate = null, endDate = null) => {
    setLoadingButton(true);
    let eventosFiltrados = []; // Array para almacenar todos los eventos filtrados
    let lastEvaluatedKey = null; // Inicializamos la variable lastEvaluatedKey

    try {
      // Bucle para realizar solicitudes de paginación hasta que no haya más LastEvaluatedKey
      do {
        // Realizar la consulta con la clave lastEvaluatedKey si está presente
        const response = await eventService.consultarEventos(
          startDate,
          endDate,
          lastEvaluatedKey
        );

        // Actualizar lastEvaluatedKey con el valor de la respuesta actual
        lastEvaluatedKey = response.LastEvaluatedKey;

        // Filtrar y ordenar los eventos

        // Agregar los eventos filtrados al array eventosFiltrados

        eventosFiltrados = [...eventosFiltrados, ...response.Items];
      } while (lastEvaluatedKey); // Repetir el bucle si hay más LastEvaluatedKey

      // Ahora puedes hacer lo que necesites con eventosFiltrados, ya contiene todos los eventos de todas las páginas

      AjusteArray(eventosFiltrados);

      // AjusteArray(arrayEvents);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logro hacer la consulta');
    }
    setLoadingButton(false);
  };

  const consultaProtocolosPorFechas = async (
    startDate = null,
    endDate = null
  ) => {
    setLoadingButton(true);
    let protocolosFiltrados = []; // Array para almacenar todos los eventos filtrados
    let lastEvaluatedKey = null; // Inicializamos la variable lastEvaluatedKey

    try {
      // Bucle para realizar solicitudes de paginación hasta que no haya más LastEvaluatedKey
      do {
        // Realizar la consulta con la clave lastEvaluatedKey si está presente
        const response = await protocolosService.consultarProtocolo(
          startDate,
          endDate,
          lastEvaluatedKey
        );

        // Actualizar lastEvaluatedKey con el valor de la respuesta actual
        lastEvaluatedKey = response.LastEvaluatedKey;

        // Filtrar y ordenar los eventos

        // Agregar los eventos filtrados al array eventosFiltrados
        protocolosFiltrados = [...protocolosFiltrados, ...response.Items];
      } while (lastEvaluatedKey); // Repetir el bucle si hay más LastEvaluatedKey
      // Ahora puedes hacer lo que necesites con eventosFiltrados, ya contiene todos los eventos de todas las páginas
      const arrayEvents = protocolosFiltrados
        .filter(
          (item) =>
            item.eventId &&
            typeof item.eventId === 'string' &&
            item.eventId.match(/^CISE-\d{5}-\d{4}$/)
        )
        .sort((a, b) => {
          const idA = parseInt(a.eventId.split('-')[1]);
          const idB = parseInt(b.eventId.split('-')[1]);
          return idB - idA;
        });

      AjusteArray(arrayEvents);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logró hacer la consulta');
    }
    setLoadingButton(false);
  };

  const disabledDate = (current) => {
    // Deshabilitar fechas futuras (después de hoy)
    return current && current > dayjs().endOf('day');
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '5px' }}>Filtrar por fechas:</span>
      <RangePicker
        format="YYYY-MM-DD"
        bordered
        onChange={onChange}
        disabledDate={disabledDate}
        style={{ marginRight: '5px', width: '60%' }}
        value={range.map((date) => dayjs(date, 'YYYY-MM-DD'))} // Necesitas convertir las fechas a objetos dayjs
        allowClear={false}
      />
      <Button
        type="primary"
        onClick={onOk}
        style={{ marginRight: '5px' }}
        disabled={range.length === 0}
        loading={loadingButton}
      >
        Filtrar
      </Button>

      <Tooltip title="Actualizar tabla">
        <Button
          onClick={onDelete}
          style={{ marginRight: '5px' }}
          icon={<RedoOutlined />}
        ></Button>
      </Tooltip>

      <Statistic
        className="textAjust"
        title="Cantidad de elementos"
        value={filteredItemCount}
        style={{ marginLeft: '15px' }}
      />
    </div>
  );
};

export default DateRangeFilter;
