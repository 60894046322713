import gql from "graphql-tag";

export default gql`
  subscription onCreateAlarm {
    onCreateAlarm {
      id
      state
      stateHistory {
        Created {
          userId
          date
        }
        FalseAlarm {
          userId
          date
        }
        Associated {
          userId
          date
        }
      }
      item
      type
      updatedAt
      createdAt
    }
  }
`