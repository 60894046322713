import React, { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';

import activitiesService from '../../../../../services/activitiesService';

export default function DeleteDocumentButton({ item, setRefresh, refresh }) {
  const [loading, setLoading] = useState(false);

  const eliminarDocumentos = async (registro) => {
    setLoading(true);
    try {
      await activitiesService.eliminarArchivos(registro);

      message.success('Documento Eliminado');
      setRefresh(!refresh);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logró eliminar el documento');
    }
    setLoading(false);
  };

  const getPath = (url) => {
    let arrayUrl = url.split('.com/')[1];

    let arraypath = arrayUrl.split('?')[0];

    return arraypath;
  };

  const eliminar = () => {
    setLoading(true);

    let registro = {
      eventId: item.eventId,
      protocol_activity_subactivity_id: item.protocol_activity_subactivity_id,
      path: getPath(item.url),
    };

    eliminarDocumentos(registro);
  };

  return (
    <Tooltip>
      <Button
        loading={loading}
        onClick={() => eliminar()}
        icon={<DeleteOutlined />}
        title="text"
      ></Button>
    </Tooltip>
  );
}
