import React, { useState, useEffect } from 'react';

import {
  Form,
  Tag,
  Select,
  Button,
  Row,
  Col,
  Checkbox,
  Input,
  message,
  Spin,
  DatePicker,
} from 'antd';
import protocolosService from '../../../../../services/protocolosService';
import eventService from '../../../../../services/eventService';
import useAuth from '../../../../../hooks/useAuth';
import dayjs from 'dayjs';

import loactionService from '../../../../../services/loactionService';

const { TextArea } = Input;

export default function InfoPrincipal({
  setRefresh,
  refresh,
  item,
  protocolosSeleccionados,
}) {
  const { locations, session } = useAuth();

  const [selectedItems] = useState([]);
  const [selectedItemsRisk] = useState([]);
  const [selectedRisck] = useState(['BAJO', 'MEDIO', 'ALTO']);
  const [selectedLaction, setSelectedLaction] = useState(null);
  const [protocolsIniciados, setProtocolosIniciados] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [subLocation, setSubLocation] = useState(null);
  const [loadingsubLocation, setLoadingSubLocation] = useState(false);

  const handleSubmit = (values) => {
    const data = checkedProtocols;
    const keysWithTrueValues = Object.keys(data).filter((key) => data[key]);
    const formattedDateTime = dayjs(values.createdAt).format(
      'YYYY-MM-DDTHH:mm'
    );

    let registro = {
      userId: session?.user?.id || session?.user?.sub,
      item: {
        protocols: keysWithTrueValues,
        title: values.title,
        location: selectedLaction || item.location,
        impact: item.impact,
        source: values.source,
        createdAt: formattedDateTime + '-05:00',
      },
    };
    //console.log(registro);
    editar(registro);
  };

  const editar = async (registro) => {
    setLoadingButton(true);
    try {
      await eventService.EditarEvento(item.id, registro);
      message.success('Evento editado');
      setRefresh(!refresh);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logro editar información');
    }
    setLoadingButton(false);
  };

  const filteredOptions = locations.filter((o) => !selectedItems.includes(o));

  const OptionsRisk = selectedRisck.filter(
    (o) => !selectedItemsRisk.includes(o)
  );

  const handleChangeSelect = (values) => {
    let valuesLocation = JSON.parse(values);
    setSelectedLaction(JSON.parse(values));
    consultaSubLocations(valuesLocation.specific);
  };

  const [currentDateTime, setCurrentDateTime] = useState(dayjs(item.createdAt));

  const handleChangeDate = (date) => {
    setCurrentDateTime(date);
  };

  const initialValues = {
    Lugar: item?.location?.name || 'Estación Vallejuelos', // Valor inicial para el campo de ubicación
    nivel: 'bajo',
    title: item?.title, // Valor inicial para el campo de nivel de riesgo
    source: item?.source || '',
    createdAt: currentDateTime,
    //tipo: item.protocols, // Valor inicial para el campo de tipo (usando un array para Checkbox)
  };
  const arrayProtocolos = [
    { id: 0, value: 'amit', title: 'AMIT', checked: false },
    { id: 1, value: 'agresion', title: 'Agresión', checked: false },
    { id: 2, value: 'clave-alfa', title: 'Clave Alfa', checked: false },
    { id: 3, value: 'delitos', title: 'Delitos', checked: false },
    { id: 4, value: 'emergencias', title: 'Emergencia', checked: false },
    { id: 5, value: 'hamcc', title: 'HAMCC', checked: false },
    { id: 6, value: 'intrusion', title: 'Intrusión', checked: false },
    { id: 7, value: 'persona-fallecida', title: 'Fallecidos', checked: false },
    { id: 8, value: 'persona-herida', title: 'Heridos', checked: false },
    {
      id: 9,
      value: 'incumplimiento-al-reglamento-del-usuario',
      title: 'Incumplimiento reglamentos',
      checked: false,
    },
    { id: 10, value: 'terrorismo', title: 'Terrorismo', checked: false },
    { id: 11, value: 'clave-s', title: 'Clave S', checked: false },
  ];

  const [checkedProtocols, setCheckedProtocols] = useState([]);

  const handleCheckboxChange = (protocolItem) => {
    const objetoEncontrado = protocolsIniciados.find(
      (objeto) => objeto.protocol === protocolItem && objeto.state !== 'Created'
    );

    if (objetoEncontrado === undefined) {
      setCheckedProtocols((prev) => ({
        ...prev,
        [protocolItem]: !prev[protocolItem],
      }));
    } else {
      message.warning('El protocolo ya se inició');
    }
  };

  const consultaProtocolos = async () => {
    try {
      const response = await protocolosService.consultarProtocolo();
      let data = response?.Items || [];
      let arrayFiltrado = data.filter((objeto) => objeto.eventId === item.id);

      setProtocolosIniciados(arrayFiltrado);
      setCheckedProtocols(
        arrayProtocolos.reduce((acc, protocol) => {
          const protocolInfo = arrayFiltrado.find(
            (item) => item.protocol === protocol.value
          );
          acc[protocol.value] = protocolInfo
            ? protocolInfo.state !== 'started'
            : false;
          return acc;
        }, {})
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const consultaSubLocations = async (specific) => {
    setLoadingSubLocation(true);
    try {
      const response = await loactionService.ConsultarSubLocation(specific);
      let data = response?.sublocations || [];

      setSubLocation(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoadingSubLocation(false);
  };

  useEffect(() => {
    // Este código se ejecutará solo cuando `open` sea `true`

    consultaProtocolos();
    consultaSubLocations(item?.location?.specific);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item label="Fecha y Hora" name="createdAt">
        <DatePicker
          showTime={{ format: 'HH:mm' }} // Configura el formato de la hora
          format="YYYY-MM-DD HH:mm" // Co
          style={{ width: '100%' }}
          value={currentDateTime}
          onChange={handleChangeDate}
        />
      </Form.Item>
      <Row gutter={10}>
        <Col span={12}>
          {' '}
          <Form.Item
            label="Ubicación"
            name="Lugar"
            rules={[
              {
                required: true,
                message: 'Debes de completar este campo.',
              },
            ]}
            extra={
              <div style={{ marginTop: '5px' }}>
                {selectedLaction && (
                  <>
                    <Tag>Zona {selectedLaction?.zone || 'z'}</Tag>
                    <Tag>{selectedLaction?.means || 'Medio'}</Tag>
                    <Tag>{selectedLaction?.type || 'Tipo'}</Tag>
                  </>
                )}
              </div>
            }
          >
            <Select
              placeholder="Inserte Ubicación del evento"
              value={selectedItems}
              onChange={handleChangeSelect}
              style={{
                width: '100%',
              }}
              showSearch
              options={filteredOptions.map((item) => ({
                value: JSON.stringify(item),
                label: item.specific + '-' + item.name,
              }))}
            />
          </Form.Item>
        </Col>

        {subLocation && (
          <Col span={12}>
            <Form.Item
              label="Ubicación Dentro de la estación"
              name="Ubicacion2"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Debes de completar este campo.',
              //   },
              // ]}
            >
              {loadingsubLocation ? (
                <Spin></Spin>
              ) : (
                <Select
                  placeholder="Inserte Ubicación"
                  style={{
                    width: '100%',
                  }}
                  loading={loadingsubLocation}
                  options={subLocation.map((item) => ({
                    value: item.code_specific,
                    label: item.denomination,
                  }))}
                ></Select>
              )}
            </Form.Item>
          </Col>
        )}
      </Row>

      <Form.Item
        label="Asunto de evento"
        name="title"
        rules={[
          {
            required: true,
            message: 'Debes de completar este campo.',
          },
        ]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        label="Nivel de riesgo"
        name="nivel"
        rules={[
          {
            required: true,
            message: 'Debes de completar este campo.',
          },
        ]}
      >
        <Select
          placeholder="Inserte Nivel de riesgo"
          value={selectedItemsRisk}
          //onChange={setselectedItemsRisk}
          style={{
            width: '100%',
          }}
          options={OptionsRisk.map((item) => ({
            value: item,
            label: item,
          }))}
        />
      </Form.Item>
      <Form.Item label="Fuente de información (Opcional)" name="source">
        <Select>
          <Select.Option value="Plataforma Cise">Plataforma Cise</Select.Option>
          <Select.Option value="Policía">Policía</Select.Option>
          <Select.Option value="Vigilancia privada">
            Vigilancia privada
          </Select.Option>
          <Select.Option value="Operador de medios tecnológicos">
            Operador de medios tecnológicos
          </Select.Option>
          <Select.Option value="Alarma Vms">Alarma Vms</Select.Option>
          <Select.Option value="Alarma Control de acceso">
            Alarma Control de acceso
          </Select.Option>
          <Select.Option value="IHR">IHR</Select.Option>
        </Select>
      </Form.Item>
      {protocolosSeleccionados.lenght !== 0 && (
        <Form.Item label="Tipo" name="tipo" valuePropName="checked">
          <p>
            Los protocolos que encontrarás seleccionados y no se pueden editar
            es porque se están ejecutando actualmente, por lo que no pueden ser
            cambiados.
          </p>
          {arrayProtocolos.map((protocol) => (
            <Checkbox
              key={protocol.id}
              checked={checkedProtocols[protocol.value]}
              onChange={() => handleCheckboxChange(protocol.value)}
              disabled={item.state === 'Closed' || item.state === 'FalseAlarm'}
            >
              {protocol.title}
            </Checkbox>
          ))}
        </Form.Item>
      )}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loadingButton}
          //onClick={() => summitFn()}
        >
          Guardar Cambios
        </Button>
      </Form.Item>
    </Form>

    // <FormInfo item={item} />
  );
}
