import React, { useState } from 'react';
import remindersService from '../../../services/remindersService';
import { Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export default function BotonEliminar({ record, funtionRefresh }) {
  const [loading, setLoading] = useState(false);

  const eliminarCalendario = async (id) => {
    try {
      await remindersService.eliminarEventoCalendario(id, { id });

      funtionRefresh();
      message.success('Se elimino el Evento');
    } catch (error) {
      message.error('No se logro hacer la solicitud');
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const deleteEvent = () => {
    setLoading(true);
    eliminarCalendario(record.id);
  };

  return (
    <Button
      key={record.id}
      type="text"
      loading={loading}
      icon={<DeleteOutlined />}
      onClick={() => deleteEvent()}
    ></Button>
  );
}
