import React from 'react';
import days from 'dayjs';
import { Typography, Divider, Tag } from 'antd';
import useAuth from '../../../../hooks/useAuth';
import { getUser } from '../../../../utils/getUser';

const { Title } = Typography;

export default function DescripcionProtocolo({ itemProtocol }) {
  const { UsuariosList } = useAuth();

  const formatDate = (date) => {
    return date ? days(date).format('MMM DD, YYYY hh:mm:ss A') : 'Sin fecha';
  };

  const estadoFiltros = {
    Created: 'Creado',
    Started: 'Iniciado',
    Completed: 'Completado',
    FalseAlarm: 'Falsa alarma',
    Skipped: 'Saltada',
  };
  const ajusteArray = (data) => {
    const sortedData = data.sort((a, b) => {
      const orderA = parseFloat(a.order);
      const orderB = parseFloat(b.order);

      // Comparar los valores como números
      return orderA - orderB;
    });

    return sortedData;
  };

  const ordenActividades = ajusteArray(itemProtocol.activities);

  return (
    <div>
      <Title level={4}>
        {' '}
        {`PARP ${itemProtocol?.protocol} - (${itemProtocol?.eventId})`}
      </Title>

      <div>
        <b style={{ marginRight: '5px' }}>Estado de Protocolo:</b>

        {itemProtocol.state === 'Created' && (
          <Tag key={itemProtocol.state} color="yellow">
            {estadoFiltros[itemProtocol.state]}
          </Tag>
        )}
        {itemProtocol.state === 'FalseAlarm' && (
          <Tag key={itemProtocol.state} color="red">
            {estadoFiltros[itemProtocol.state]}
          </Tag>
        )}
        {itemProtocol.state === 'Completed' && (
          <Tag key={itemProtocol.state} color="green">
            {estadoFiltros[itemProtocol.state]}
          </Tag>
        )}
        {itemProtocol.state === 'Started' && (
          <Tag key={itemProtocol.state} color="blue">
            {estadoFiltros[itemProtocol.state]}
          </Tag>
        )}
        {itemProtocol.state === 'Skipped' && (
          <Tag key={itemProtocol.state} color="blue">
            {estadoFiltros[itemProtocol.state]}
          </Tag>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '600px', // Establece el ancho máximo al 100% del contenedor principal
          overflow: 'hidden', // Oculta el contenido que se desborda
        }}
      >
        <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <b>Inicio: </b>{' '}
          {formatDate(itemProtocol?.stateHistory?.Created?.date) || ''}
        </div>

        <div
          style={{
            flex: 1,
            textAlign: 'right',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <b>Cierre: </b>{' '}
          {formatDate(
            itemProtocol?.stateHistory?.Completed?.date ||
              itemProtocol?.stateHistory?.FalseAlarm?.date ||
              itemProtocol?.stateHistory?.Skipped?.date
          ) || 'sin fecha'}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '600px', // Establece el ancho máximo al 100% del contenedor principal
          overflow: 'hidden', // Oculta el contenido que se desborda
        }}
      >
        <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {getUser(itemProtocol.stateHistory.Created.userId, UsuariosList)}
        </div>

        <div
          style={{
            flex: 1,
            textAlign: 'right',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {getUser(
            itemProtocol?.stateHistory?.Completed?.userId ||
              itemProtocol?.stateHistory?.FalseAlarm?.userId,
            UsuariosList
          )}
        </div>
      </div>

      <Divider style={{ margin: '2px' }} />

      <Title level={4}>Actividades</Title>

      {ordenActividades.map((objeto, index) => (
        <ActividadesPdf
          key={index}
          itemActividad={objeto}
          UsuariosList={UsuariosList}
        />
      ))}
    </div>
  );
}

const ActividadesPdf = ({ itemActividad, index, UsuariosList }) => {
  const formatDate = (date) => {
    return date ? days(date).format('MMM DD, YYYY hh:mm:ss A') : 'Sin fecha';
  };

  function obtenerDatoMasReciente(arrayDeObjetos) {
    const arrayOrdenado = arrayDeObjetos
      .slice()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    return arrayOrdenado[0];
  }

  const estadoFiltros = {
    Created: 'Creado',
    Started: 'Iniciado',
    Completed: 'Completado',
    FalseAlarm: 'Falsa alarma',
    Skipped: 'Saltada',
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '600px', // Establece el ancho máximo al 100% del contenedor principal
          overflow: 'hidden', // Oculta el contenido que se desborda
        }}
      >
        <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Title level={5} color="green">
            {itemActividad.protocol_activity_subactivity
              .split('_')[1]
              .replace(/-/g, ' ')}
          </Title>
        </div>

        <div
          style={{
            flex: 1,
            textAlign: 'right',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Tag color="green">{estadoFiltros[itemActividad.state]}</Tag>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '600px', // Establece el ancho máximo al 100% del contenedor principal
          overflow: 'hidden', // Oculta el contenido que se desborda
        }}
      >
        <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <b>Inicio: </b>{' '}
          {formatDate(itemActividad?.stateHistory?.Created?.date) || ''}
        </div>

        <div
          style={{
            flex: 1,
            textAlign: 'right',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <b>Cierre: </b>{' '}
          {formatDate(
            itemActividad?.stateHistory?.Completed?.date ||
              itemActividad?.stateHistory?.FalseAlarm?.date
          ) || 'sin fecha'}
        </div>
      </div>

      <div
        style={{
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'justify',
        }}
      >
        <b>Comentario: </b>
        {itemActividad.comments.length !== 0 ? (
          <>
            {obtenerDatoMasReciente(itemActividad.comments).comment ||
              'Sin descripción'}
          </>
        ) : (
          'Sin descripción'
        )}
      </div>
      <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <b>Usuario: </b>
        {getUser(
          itemActividad?.stateHistory?.Completed?.userId ||
            itemActividad?.stateHistory?.Skipped?.userId,
          UsuariosList
        )}
      </div>

      <Divider style={{ margin: '2px' }} />
    </>
  );
};
