import React, { useState } from 'react';
import { Button, Tooltip, Modal, Form, message, Input } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import activitiesService from '../../../services/activitiesService';
import useAuth from '../../../hooks/useAuth';

const ModalIniciarSubA = ({
  item,
  textactual = '',
  consultaActividades,
  typeModal = 'completar',
}) => {
  const { session } = useAuth();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const Iniciar = (item) => {
    const registro = {
      eventId: item.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      start: true,
      order: item.order,
    };
    ejecutarActividad(registro);
  };

  const ejecutarActividad = async (registro) => {
    setLoading(true);
    try {
      await activitiesService.funcionActividad(registro);
      message.success('Actividad Iniciada');
      setOpen(true);
    } catch (error) {
      console.error(error);
      message.error('No se logro hacer la consulta');
    }
    setLoading(false);
  };
  const onFinish = (values) => {
    const { comment } = values;

    if (typeModal === 'completar' || typeModal === 'completarfull') {
      Completar(comment);
    } else {
      Saltar(comment);
    }
  };

  const Saltar = (comment) => {
    const registro = {
      eventId: item.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      skip: true,
      order: item.order,
      comment: comment,
    };
    finalizarActividad(registro);
  };

  const Completar = (comment) => {
    let registro = {
      eventId: item.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      complete: true,
      order: item.order,
      comment,
    };

    const contienePalabras =
      item.protocol_activity_subactivity.includes('falsa') &&
      item.protocol_activity_subactivity.includes('alarma');

    if (contienePalabras) {
      registro.falseAlarm = false;
    }
    finalizarActividad(registro);
  };

  const finalizarActividad = async (registro) => {
    setLoading(true);
    try {
      await activitiesService.funcionActividad(registro);
      message.success('Actividad Completada');
      setOpen(false);
      consultaActividades();
    } catch (error) {
      console.error(error);
      message.error('No se logro hacer la consulta');
    }
    setLoading(false);
  };

  const validateMessages = {
    required: 'Es requerido',
    types: {
      email: 'Correo no válido',
      number: 'Número no válido',
    },
  };

  return (
    <>
      {typeModal === 'completarfull' && (
        <Tooltip title="Completar">
          <Button
            type="text"
            loading={open}
            icon={<CheckCircleOutlined />}
            onClick={() => {
              setOpen(true);
            }}
          ></Button>
        </Tooltip>
      )}
      {typeModal === 'completar' ? (
        <>
          {' '}
          <Tooltip title="Iniciar">
            <Button
              type="text"
              loading={open}
              icon={<CheckCircleOutlined />}
              onClick={() => {
                Iniciar(item);
              }}
            ></Button>
          </Tooltip>
        </>
      ) : (
        <>
          {/* <Tooltip title="Saltar">
            <Button
              type="text"
              loading={open}
              icon={<ArrowDownOutlined />}
              onClick={() => {
                setOpen(true);
              }}
            ></Button>
          </Tooltip> */}
        </>
      )}

      <Modal
        title={'Justificacion de comentario'}
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
          consultaActividades();
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
          validateMessages={validateMessages}
          initialValues={{ comment: '' }} // Agrega los valores predeterminados
        >
          <Form.Item
            name="comment"
            label="Comentario"
            rules={[
              {
                required: true,
                message: 'Debes completar este campo.',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Enviar
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ModalIniciarSubA;
