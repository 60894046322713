import axios from 'axios';

const api =
  'https://f2r8ymqcse.execute-api.us-east-1.amazonaws.com/prod/locations';

const ConsultarSubLocation = async (specific = null) => {
  // Ruta del evento específico
  const apiUrl = `${api}/sublocations?specific=${specific}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {},
    });

    return response.data; // Devuelve los datos obtenidos
  } catch (error) {
    console.error('Error en la consulta:', error);
    throw error; // Relanza el error para que se maneje en otro lugar si es necesario
  }
};

const locationService = {
  ConsultarSubLocation,
};

export default locationService;
