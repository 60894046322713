import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import extraService from '../../../services/extrasService';
import Loading from '../../layouts/Loading';

export default function ModalReportes({ open, setOpen }) {
  const [urlDb1, setUrlDb1] = useState(null);
  // const handleCardClick = (title) => {

  const consultaUrl = async () => {
    try {
      const response = await extraService.consultarUrlDashBoard();
      let data = response;

      setUrlDb1(data.embedUrl);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Este código se ejecutará solo cuando `open` sea `true`

    if (open) consultaUrl();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <Modal
      title=""
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={'90%'}
      footer={false}
      style={{ height: '90%' }}
    >
      {urlDb1 ? (
        <div>
          <iframe
            title="dashboard 1"
            src={urlDb1}
            width="100%"
            height="1000px"
            frameborder="0"
          ></iframe>
        </div>
      ) : (
        <Loading />
      )}
    </Modal>
  );
}
