import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import {
  AuthenticationDetails,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

const CambiarContraseña = ({ setopen }) => {
  const [form] = Form.useForm();
  const [messageText, setMessageText] = useState('');

  const onFinish = async (values) => {
    const { oldPassword, newPassword, confirmPassword } = values;

    // Validaciones adicionales según tus necesidades
    if (newPassword !== confirmPassword) {
      setMessageText('La nueva contraseña y la confirmación no coinciden');
      return;
    }

    // Configuración de Cognito User Pool
    const poolData = {
      UserPoolId: 'us-east-1_H54j9YEXQ',
      ClientId: '43k5sm5mhn0m6rokqfrrgttmur',
    };
    const userPool = new CognitoUserPool(poolData);

    // Obtener el usuario actual
    const cognitoUser = userPool.getCurrentUser();

    // Autenticación con contraseña antigua
    const authenticationDetails = new AuthenticationDetails({
      Username: cognitoUser.getUsername(),
      Password: oldPassword,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        // Cambiar la contraseña
        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) {
            setMessageText(`Error al cambiar la contraseña: ${err.message}`);
          } else {
            message.success('Contraseña cambiada exitosamente');
            form.resetFields(); // Limpiar los campos del formulario
            setopen(false);
          }
        });
      },
      onFailure: (err) => {
        setMessageText(`Error de autenticación: ${err.message}`);
      },
    });
  };

  return (
    <div>
      <h2>Cambiar Contraseña</h2>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="oldPassword"
          label="Contraseña Antigua"
          rules={[{ required: true, message: 'Ingrese la contraseña antigua' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="Nueva Contraseña"
          rules={[{ required: true, message: 'Ingrese la nueva contraseña' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirmar Nueva Contraseña"
          rules={[
            { required: true, message: 'Confirme la nueva contraseña' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Las contraseñas no coinciden');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Cambiar Contraseña
          </Button>
        </Form.Item>

        <span
          className="text-sm text-gray-400 underline cursor-pointer"
          onClick={() => setopen(false)}
        >
          Volver
        </span>
      </Form>
      {messageText && <p style={{ color: 'red' }}>{messageText}</p>}
    </div>
  );
};

export default CambiarContraseña;
