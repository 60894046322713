import { Button, Form, Input, message, Modal, notification } from 'antd';

import { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import CambiarContraseña from './CambiarContraseña';

export default function LoginForm() {
  const [submit, setSubmit] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [, setMail] = useState('');
  const [resetPsw, setResetPasw] = useState(false); // variable para reiniciar la contraseña por correo
  const { login } = useAuth();

  async function handleSubmit(values) {
    let registro = {
      password: values.password,
      username: values.username.toLowerCase(),
    };

    try {
      setSubmit(true);
      await login(registro);
      message.success('Inicio de sesión exitoso');
      // return <Navigate to="/inicio" replace />;
    } catch (error) {
      console.error(error);
      message.error(
        'Usuario o contraseña incorrectos. Por favor validar o comunicarse con Telemática.'
      );
    } finally {
      setSubmit(false);
    }
  }



  const handleUsernameChange = (e) => {
    const newValue = e.target.value;
    setMail(newValue);
  };
  return (
    <div>
      {createUser ? (
        <CambiarContraseña setopen={setCreateUser} />
      ) : (
        //
        <Form size="large" onFinish={handleSubmit} autoComplete="off">
          <Form.Item
            name={'username'}
            rules={[
              {
                required: true,
                message: 'Debes introducir tu correo electrónico',
              },
              {
                type: 'email',
                message: 'Debes introducir un correo electrónico válido',
              },
            ]}
          >
            <Input
              placeholder="Correo electrónico"
              onChange={handleUsernameChange}
            />
          </Form.Item>
          <Form.Item
            name={'password'}
            rules={[
              { required: true, message: 'Debes introducir tu contraseña' },
            ]}
            // extra={
            //   <span onClick={() => enviarCodigo()}>
            //     ¿Olvidate tu contraseña?
            //   </span>
            // }
          >
            <Input.Password placeholder="Contraseña" autoComplete="off" />
          </Form.Item>
          <Form.Item>
            <span
              className="text-sm text-gray-400 underline cursor-pointer"
              onClick={() => setCreateUser(true)}
            >
              ¿Desea modificar su contraseña?
            </span>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={submit}>
              Iniciar sesión
            </Button>
          </Form.Item>

          <ModalResetPasword setOpen={setResetPasw} open={resetPsw} />
          {/* <span
            className="text-sm text-gray-400 underline cursor-pointer"
            onClick={() => setCreateUser(true)}
          >
            ¿Quieres crear un usuario?
          </span> */}
        </Form>
      )}
    </div>
  );
}

function ModalResetPasword({ open, setOpen, name }) {
  const [verificationCode, setVerificationCode] = useState('');
  const [form] = Form.useForm();

  const handleReSendCode = () => {
    // Aquí puedes implementar la lógica para reenviar el código
    console.log('Reenviar código');
  };

  const handleOk = () => {
    // Aquí puedes implementar la lógica para manejar el código de verificación
    console.log('Código de verificación:', verificationCode);
    setOpen(false); // Cerrar el modal después de manejar el código
  };

  const handleCancel = () => {
    setOpen(false); // Cerrar el modal si el usuario hace clic en Cancelar
  };



  const handleChangePassword = (values) => {
    // Aquí puedes implementar la lógica para cambiar la contraseña
    console.log('Nueva contraseña:', values.password);

    // Simulación de éxito para el ejemplo
    notification.success({
      message: 'Contraseña cambiada',
      description: 'La contraseña se ha cambiado exitosamente.',
    });

    // Puedes redirigir al usuario a otra página o realizar otras acciones después de cambiar la contraseña
  };

  return (
    <Modal
      visible={open}
      title="Reestablecer Contraseña"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Form form={form} onFinish={handleChangePassword} layout="vertical">
        <p>
          {' '}
          Se ha enviado un código de verificación al correo tal para autorizar
          el cambio de contraseña{' '}
        </p>
        <Form.Item
          label="Código de Verificación"
          extra={
            <span onClick={() => handleReSendCode(true)}>Reenviar código</span>
          }
          name="verificationCode"
          rules={[
            {
              required: true,
              message: 'Por favor, ingrese el código de verificación',
            },
          ]}
        >
          <Input
            placeholder="Ingrese el código de verificación"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Nueva Contraseña"
          rules={[
            {
              required: true,
              message: 'Por favor, ingrese la nueva contraseña',
            },
            {
              min: 6,
              message: 'La contraseña debe tener al menos 6 caracteres',
            },
          ]}
        >
          <Input.Password placeholder="Ingrese la nueva contraseña" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirmar Contraseña"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Por favor, confirme la nueva contraseña',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Las contraseñas no coinciden')
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirme la nueva contraseña" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Cambiar Contraseña
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
