import React, { useState, useEffect, useRef } from 'react';
import { loadModules } from 'esri-loader';
import { Modal } from 'antd';
import mapaIconCameras from '../../../assets/mapIcons/camara-de-video.png';
import Camera from './components/Camera';

export default function ModalCamaras({ items, camaras, close }) {
  const [open, setOpen] = useState(true);

  const [deviceId, setDeviceId] = useState(
    camaras[0]?.attributes?.deviceid || 'hf'
  );
  const mapContainer = useRef(null);

  const closeModal = () => {
    close();
    setOpen(false);
  };

  const Camaras = (iten) => {
    if (iten?.attributes?.deviceid) {
      setDeviceId(iten?.attributes?.deviceid || '');
    }
  };

  useEffect(() => {
    // authenticateArcGIS().then(() => {
    //   // Después de autenticarte, puedes cargar el mapa y realizar otras operaciones

    // });
    loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/Graphic',
      'esri/PopupTemplate',
      'esri/layers/FeatureLayer',
    ]).then(([Map, MapView, Graphic, FeatureLayer, PopupTemplate]) => {
      // Crear un nuevo mapa y vista
      const map = new Map({
        basemap: 'streets',
      });

      const view = new MapView({
        container: mapContainer.current,
        map: map,
        center: [
          camaras[0]?.attributes?.longitud || -75.56359,
          camaras[0]?.attributes?.latitud || 6.25184,
        ], // Coordenadas del centro del mapa
        zoom: 17, // Nivel de zoom inicial
      });

      const featureLayer1 = new FeatureLayer({
        url: 'https://metrogis.metrodemedellin.gov.co/server/rest/services/Cise/Sistema_Metro/MapServer/1',
      });
      map.add(featureLayer1);

      // Agregar marcadores al mapa basados en los datos de la API
      camaras.forEach((item) => {
        // ... tu lógica para agregar los marcadores de cámaras
        const point = {
          type: 'point',
          x: item.attributes.longitud, // Usar la longitud del objeto attributes
          y: item.attributes.latitud,
        };

        const popupTemplate = new PopupTemplate({
          title: `${item.attributes.nombre} `,
        });

        const markerSymbol = {
          type: 'picture-marker',
          url: mapaIconCameras, // Ruta al icono personalizado
          width: '10px', // Ancho del icono en píxeles
          height: '10px', // Altura del icono en píxeles
        };

        const graphic = new Graphic({
          geometry: point,
          symbol: markerSymbol,
          attributes: item,
          popupTemplate: popupTemplate,
        });
        view.graphics.add(graphic);

        view.on('click', (event) => {
          view.hitTest(event).then((response) => {
            const firstResult = response.results[0];
            if (firstResult && firstResult.graphic) {
              const graphic = firstResult.graphic;
              Camaras(graphic.attributes);
            } else {
              Camaras(null);
            }
          });
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title={`Camaras de ${items?.attributes?.alias_estacion || ''} - ${
        items?.attributes?.nombre || ''
      }`}
      centered
      open={open}
      onOk={() => closeModal()}
      onCancel={() => closeModal()}
      width={'90%'}
      footer={false}
    >
      <div className="contenedortipoA">
        <div className="columna">
          <h3>Mapa de Elementos del VMS</h3>

          <div
            style={{ height: '500px', width: 'auto' }}
            ref={mapContainer}
          ></div>
        </div>

        {items?.attributes && (
          <div className="columna">
            <h3>Equipo seleccionado</h3>{' '}
            {!!deviceId && deviceId !== 'hf' ? (
              <Camera width={'90%'} height={'50%'} deviceId={deviceId} />
            ) : (
              <h4>Id de camara no obtenido</h4>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
