import React, { useState, useEffect } from 'react';
import { Button, Modal, Table } from 'antd';
import tableColumnsRoles from './components/tableColumnsRoles';
import PageHeader from '../../headers/PageHeader';
import userService from '../../../services/UserService';
import useAuth from '../../../hooks/useAuth';
import ModalCrearUsuario from './components/ModalCrearUsuario';
import tableColumnRolesMobile from './components/tableColumnRolesMobile';

export default function ModalRoles({ open, setOpen }) {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newUser, setNewUser] = useState(false);
  const { session } = useAuth();
  const fetchData = async () => {
    setLoading(true);
    try {
      const usuariosData = await userService.consultarUsuarios();
      setUsuarios(usuariosData.response.Items);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
      // Manejar el error, mostrar un mensaje de error al usuario, etc.
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      fetchData(); // Llama a la función para obtener usuarios cuando el modal se abre
    }
  }, [open]);

  const hangCancel = () => {
    setOpen(false);
  };
  const scrollConfig = { x: true };
  return (
    <Modal
      visible={open}
      onCancel={hangCancel}
      onOk={hangCancel}
      footer={false}
      width={'auto'}
    >
      <PageHeader title="Lista de Usuarios">
        {session.roles.users.isAllowedtoCreate && (
          <Button onClick={() => setNewUser(true)}> Crear Usuario</Button>
        )}

        <ModalCrearUsuario
          setOpen={setNewUser}
          open={newUser}
          fetchData={fetchData}
        />
      </PageHeader>

      <div className="textAjust">
        <Table
          columns={tableColumnsRoles(usuarios, fetchData, session)}
          dataSource={usuarios}
          loading={loading}
          locale={{
            filterTitle: 'Filtrar',
            filterConfirm: 'Aceptar',
            filterReset: 'Limpiar',
            emptyText: 'Sin datos',
          }}
          scroll={scrollConfig}
        />
      </div>
      <div className="componentWeb">
        <Table
          columns={tableColumnRolesMobile(usuarios, fetchData, session)}
          dataSource={usuarios}
          loading={loading}
          locale={{
            filterTitle: 'Filtrar',
            filterConfirm: 'Aceptar',
            filterReset: 'Limpiar',
            emptyText: 'Sin datos',
          }}
        />
      </div>
    </Modal>
  );
}
