import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Input, message } from 'antd';

import eventService from '../../../services/eventService';

export default function ModalFalsaAlarma({ open, setOpen, item, fetchData }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const validateMessages = {
    required: 'Es requerido',
    types: {
      email: 'Correo no valido',
      number: 'Numero no valido',
    },
  };
  const editarEventoEnServidor = async (eventId, registro) => {
    setLoading(true);
    try {
      // Lógica para enviar la solicitud de edición del evento al servidor
      const response = await eventService.EditarEvento(eventId, registro);
      // Manejar la respuesta del servidor, si es necesario
      console.debug('Respuesta del servidor:', response);
      setOpen(false);
      message.success('Falsa alarma Registrada');
      fetchData();
    } catch (error) {
      console.error('Error al editar el evento:', error);
      message.error('No se logro registrar falsa alarma');
      // Manejar errores, si es necesario
    }
    setLoading(false);
  };

  const onFinish = (values) => {
    const { comment } = values;

    let registro = {
      falseAlarm: true,
      comment,
    };
    // Llamar a la función para editar el evento con el comentario proporcionado
    editarEventoEnServidor(item.id, registro);
    // Cerrar el modal
  };

  useEffect(() => {
    form.resetFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      title={'Justificar Falsa Alarma ' + item.id}
      visible={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Form
        name="nest-messages"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
        validateMessages={validateMessages}
      >
        <Form.Item
          name="comment"
          label="Comentario"
          rules={[
            {
              required: true,
              message: 'Debes de completar este campo.',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Button type="primary" htmlType="submit" block loading={loading}>
          Enviar
        </Button>
      </Form>
    </Modal>
  );
}
