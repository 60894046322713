import React, { useState } from 'react';
import { Button, Tooltip, message, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import eventService from '../../../../services/eventService';

const BotonEliminarImplicado = ({ eventId, item, obtenerImplicador }) => {
  const [Loading, setLoading] = useState(false);
  const deleteImplicado = async (registro) => {
    try {
      // Lógica para enviar la solicitud de edición del evento al servidor
      await eventService.eliminarImplicado(eventId, registro);

      message.success('Implicado Eliminado');
      obtenerImplicador();
      // setShow(false);
      // Manejar la respuesta del servidor, si es necesario
    } catch (error) {
      console.error('Error al crear implicado', error);
    }
    setLoading(false);
  };

  const eliminar = (item) => {
    setLoading(true);
    let registro = {
      protocol_cause_id: item.protocol_cause_id,
    };
    deleteImplicado(registro);
  };

  return (
    <>
      {Loading ? (
        <Spin></Spin>
      ) : (
        <Tooltip title="Eliminar">
          <Button type="text" onClick={() => eliminar(item)}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default BotonEliminarImplicado;
