// export function formarLimipiar(texto) {
//   const textoLimpio = texto.replace(/[^\w\s]/gi, ' ');

//   // Reemplazar múltiples espacios con uno solo
//   const textoSinEspaciosMultiples = textoLimpio.replace(/\s+/g, ' ').trim();

//   // Poner la primera letra de cada palabra en mayúscula
//   const textoFormateado = textoSinEspaciosMultiples
//     .toLowerCase()
//     .replace(/(?:^|\s)\S/g, (letra) => letra.toUpperCase());

//   return textoFormateado;
// }

export function formarLimipiar(texto) {
  // Reemplazar caracteres no alfanuméricos con espacios
  const textoLimpio = texto.replace(/[^\w\s]/gi, ' ');

  // Reemplazar múltiples espacios con uno solo
  const textoSinEspaciosMultiples = textoLimpio.replace(/\s+/g, ' ').trim();

  // Poner la primera letra de cada palabra en mayúscula
  const textoFormateado = textoSinEspaciosMultiples
    .toLowerCase()
    .replace(/(?:^|\s)\S/g, (letra) => letra.toUpperCase());

  // Reemplazar "Cerrar Evento" con "Cerrar Protocolo"
  const textoFinal = textoFormateado.replace(
    /Cerrar Evento/g,
    'Cerrar Protocolo'
  );

  return textoFinal;
}
