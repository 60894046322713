import axios from 'axios';

const api = 'https://9abrh080wa.execute-api.us-east-1.amazonaws.com/prod';

const getUserInfo = async (email) => {
  try {
    // Consulta el usuario específico utilizando el email
    const usuarioEspecifico = await consultarUsuarioEspecifico(email);

    // Extrae el ID de usuario del resultado de la consulta anterior
    const userId = usuarioEspecifico.response.Items[0].roles;

    const roles = await consultarRolesUsuario(userId);

    const user = usuarioEspecifico.response.Items[0]; // Podrías querer manipular la respuesta de consultarUsuarioEspecifico antes de asignarla
    const empresa = {}; // Asegúrate de obtener la información de la empresa si es necesario
    const configuracionesEmpresa = {}; // Asegúrate de obtener las configuraciones de la empresa si es necesario

    // Retorna los datos del usuario y sus roles
    return { user, empresa, configuracionesEmpresa, roles };
  } catch (error) {
    console.error('Error al obtener información del usuario:', error);
    throw error; // Lanza el error para que pueda ser manejado por quien llama a esta función
  }
};



const consultarUsuarios = async () => {
  try {
    const response = await axios.get(`${api}/users`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error al consultar usuarios:', error);
    throw error;
  }
};



const consultarUsuarioEspecifico = async (email) => {
  try {
    const response = await axios.get(`${api}/users?email=${email}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error al consultar usuario específico:', error);
    throw error;
  }
};

const EditarUsusairo = async (userId, registro) => {
  const path = `/users/${userId}`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.patch(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    return response.data; // Devolver los datos obtenidos from Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};
const EliminarUsuario = async (userId, registro) => {
  const path = `/users/${userId}`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.delete(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    return response.data; // Devolver los datos obtenidos from Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};
const consultarRolesUsuario = async (userId) => {
  try {
    const response = await axios.get(`${api}/roles/${userId}`);
    const data = response.data.response.Item.permissions;
    return data;
  } catch (error) {
    console.error('Error al consultar roles del usuario:', error);
    throw error;
  }
};

const ObtenerTokenArgis = async () => {
  // Primero, obtén el token de autenticación
  const formdata = new FormData();
  formdata.append('username', 'cise_app');
  formdata.append('password', '51x3_4p11c4x10#');
  formdata.append('client', 'referer');
  formdata.append('expiration', '60');
  formdata.append('f', 'pjson');
  formdata.append(
    'referer',
    'https://www.medellin.gov.co/mapas/rest/services/ServiciosCiudad/CartografiaBase/MapServer'
  );

  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  try {
    await fetch(
      'https://metrogis.metrodemedellin.gov.co/portal/sharing/rest/generateToken',
      requestOptions
    );
    // Obtén el token de la respuesta

    // Luego usa el token para obtener los datos de usuario
  } catch (error) {
    console.error('Error al obtener el token:', error);
  }
};

const userService = {
  getUserInfo,
  consultarUsuarios,
  consultarUsuarioEspecifico,
  consultarRolesUsuario,
  ObtenerTokenArgis,
  EditarUsusairo,
  EliminarUsuario,
};

export default userService;
