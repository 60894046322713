import React from 'react';
import { Typography, Divider, Descriptions, List } from 'antd';
import days from 'dayjs';
import PageHeader from '../../../headers/PageHeader';
import useAuth from '../../../../hooks/useAuth';
import { getUser } from '../../../../utils/getUser';

const { Title } = Typography;
const { Item } = Descriptions;

export default function Descripciongeneral({ item }) {
  const estadoFiltros = {
    InProgress: 'Ejecutando',
    FalseAlarm: 'Falsa alarma',
    Closed: 'Cerrado',
    Open: 'Abierto',
  };

  const { session, UsuariosList } = useAuth();
  const FormatearMoneda = ({ valor }) => {
    const valorFormateado = new Intl.NumberFormat('es-CO', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(valor);

    return <span>{valorFormateado}</span>;
  };
  return (
    <div>
      {' '}
      <PageHeader
        title={`Información General - ${item?.id || 'cise-xxxx-xxx'}`}
        head={`Fecha de creación del informe: ${days().format(
          'MMM DD, YYYY hh:mm:ss A'
        )}`}
        createdBy={`Informe solicitado por: ${getUser(
          session?.user?.id || session?.user?.sub,
          UsuariosList
        )}`}
      ></PageHeader>
      <TextDivider item={item} />
      <Divider style={{ margin: '2px' }} />
      <Descriptions
        title={''}
        column={2}
        style={{ marginTop: '2px', marginBottom: '2px' }}
      >
        <Item label="Estado">{estadoFiltros[item?.state] || 'NA'}</Item>
        <Item label="Ubicación">{item?.location?.name || 'NA'}</Item>
        <Item label="Lugar">{item?.location?.specific || 'NA'}</Item>
        <Item label="Tipo">{item?.location?.type || 'NA'}</Item>
        <Item label="Zona">{item?.location?.zone || 'NA'}</Item>
        <Item label="Medio">{item?.location?.means || 'NA'}</Item>
        <Item label="Fuente Información">{item?.source || 'NA'}</Item>
        <Item label="Impacto">{item?.impact || 'NA'}</Item>

        <Item label="Evento creado por:">
          {getUser(item.createdBy, UsuariosList)}
        </Item>
      </Descriptions>
      <Title level={5}>Informe</Title>
      <p>
        {item?.title ||
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ac ligula sed risus ullamcorper volutpat. Sed sagittis orci egetjusto ultricies, vel facilisis tellus aliquet. Proin non est nec  purus facilisis consectetur non in metus. Nulla facilis Integer id lacus vel turpis fringilla consectetur. Vivamus'}
      </p>
      <Divider style={{ margin: '2px' }} />
      <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <b>Costo: </b>
        <FormatearMoneda valor={item.cost.total} />
      </div>
      <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <b>Descripción: </b>
        {item.cost.description}
      </div>
      <Divider style={{ margin: '2px' }} />
      <Title level={5}>Causas Asociadas</Title>
      {item.causes.map((objeto, index) => (
        <div key={index}>
          <Causas item={objeto} />
        </div>
      ))}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '600px', // Establece el ancho máximo al 100% del contenedor principal
          overflow: 'hidden', // Oculta el contenido que se desborda
        }}
      >
        <Divider style={{ margin: '2px' }} />
        <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Title level={5}>Costo de protocolo</Title>
        </div>

        <div
          style={{
            flex: 1,
            textAlign: 'right',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <b>:</b>

          <FormatearMoneda valor={item.cost.total} />
          {/* {FormatearMoneda(item.cost.total || 0)} */}
        </div>
      </div>
    </div>
  );
}

const TextDivider = ({ item }) => {
  const formatDate = (date) => {
    return date ? days(date).format('MMM DD, YYYY hh:mm:ss A') : 'Sin fecha';
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        maxWidth: '600px', // Establece el ancho máximo al 100% del contenedor principal
        overflow: 'hidden', // Oculta el contenido que se desborda
      }}
    >
      <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <b>Inicio: </b> {formatDate(item?.stateHistory?.Open?.date) || ''}
      </div>

      <div
        style={{
          flex: 1,
          textAlign: 'right',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <b>Cierre: </b>{' '}
        {formatDate(
          item?.stateHistory?.Closed?.date ||
            item?.stateHistory?.FalseAlarm?.date
        ) || 'sin fecha'}
      </div>
    </div>
  );
};

const Causas = ({ item }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        maxWidth: '600px', // Establece el ancho máximo al 100% del contenedor principal
        overflow: 'hidden', // Oculta el contenido que se desborda
      }}
    >
      <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <b>{item.protocol_cause.split('_')[1].replace(/-/g, ' ') || ''}</b>

        <div>
          <List
            dataSource={item.involved}
            renderItem={(task) => (
              <List.Item style={{ padding: '5px' }}>
                <p>
                  <strong>Nombre:</strong> {task.name}
                </p>
                <p>
                  <strong>Género:</strong> {task.gender}
                </p>

                <p>
                  <strong>ID:</strong> {task.id}
                </p>
                {/* Agrega más campos según sea necesario */}
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
};
