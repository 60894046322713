import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Avatar, Spin, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import useAuth from '../../../hooks/useAuth';
import userService from '../../../services/UserService';

const ModalPerfil = ({ visible, setVisible, onCancel }) => {
  const { session } = useAuth();
  const [infoUser, setInfoUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const profilePic = null;

  const fetchData = async () => {
    setLoading(true);
    try {
      const usuariosData = await userService.consultarUsuarios();
      let data = usuariosData?.response?.Items || [];

      const objetoEncontrado = data.find(
        (objeto) => objeto.email === session.user.email
      );

      setInfoUser(objetoEncontrado || null);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
      // Manejar el error, mostrar un mensaje de error al usuario, etc.
    }
    setLoading(false);
  };

  const onFinish = (values) => {
    let registro = {
      alias: values.lastName + '-' + values.name,
      document: infoUser.document,
      email: values.email,
      lastName: values.lastName,
      name: values.name,
      outsideId: infoUser.outsideId,
      phone: values.phone,
      preferences: infoUser.preferences,
      roles: infoUser.roles,
    };

    editarUsuario(registro);
  };

  const editarUsuario = async (registro) => {
    setLoading(true);
    try {
      await userService.EditarUsusairo(infoUser.id, registro);

      message.success('Usuario Editado');
      setEditMode(false); // Desactiva el modo de edición después de guardar cambios
      // Puedes llamar a fetchData() si necesitas actualizar la información después de guardar cambios.
      onCancel();
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logró editar usuario');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchData();
      setEditMode(false); // Asegúrate de que el modo de edición esté desactivado al abrir el modal
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const roles = {
    CISEInspector: 'Inspector',
    CISEAnalyts: 'Analista',
    CISEAdministrador: 'Administrador',
    Coordinator: 'Coordinador',
  };

  return (
    <Modal
      title="Mi perfil"
      visible={visible}
      confirmLoading={loading}
      onCancel={() => setVisible(false)}
      centered
      footer={false}
    >
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Avatar
          size={64}
          icon={profilePic ? null : <UserOutlined />}
          src={profilePic || undefined}
        />
        {infoUser && <h2>{roles[infoUser.roles]}</h2>}
      </div>

      {infoUser ? (
        <Form
          layout="vertical"
          initialValues={infoUser}
          name="your_form_profile"
          onFinish={onFinish}
        >
          <Form.Item label="Correo" name="email">
            <Input placeholder="Correo" disabled />
          </Form.Item>
          <Form.Item label="Nombres" name="name">
            <Input placeholder="Nombres" readOnly={!editMode} />
          </Form.Item>
          <Form.Item label="Apellidos" name="lastName">
            <Input placeholder="Apellidos" readOnly={!editMode} />
          </Form.Item>

          <Form.Item label="Teléfono" name="phone">
            <Input placeholder="Teléfono" readOnly={!editMode} />
          </Form.Item>

          {editMode && (
            <Button type="primary" htmlType="submit" block loading={loading}>
              Guardar Cambios
            </Button>
          )}
        </Form>
      ) : (
        <Spin />
      )}

      {/* {!editMode && (
        <Button onClick={() => setEditMode(true)} block>
          Editar Perfil
        </Button>
      )} */}
    </Modal>
  );
};

export default ModalPerfil;
