import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Input, message } from 'antd';

import activitiesService from '../../../services/activitiesService';
import useAuth from '../../../hooks/useAuth';

export default function ModalIniciarActividad({
  consultaIniciar,
  selector,
  funcionCerra,
  typeLabel = '',
  subActividades = [],
}) {
  const { session } = useAuth();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [falsaAlarma, setFalsaAlarma] = useState(false);
  const [form] = Form.useForm();
  const validateMessages = {
    required: 'Es requerido',
    types: {
      email: 'Correo no valido',
      number: 'Numero no valido',
    },
  };

  const ejecutarActividad = async (registro) => {
    setLoading(true);
    try {
      await activitiesService.funcionActividad(registro);
      message.success('Actividad Iniciada');
      setOpen(true);
    } catch (error) {
      console.error(error);
      message.error('No se logro hacer la consulta');
    }
    setLoading(false);
  };
  const finalizarActividad = async (registro) => {
    setLoading(true);
    try {
      await activitiesService.funcionActividad(registro);
      message.success('Actividad Completada');
      setOpen(false);
      consultaIniciar();
    } catch (error) {
      console.error(error);
      message.error('No se logro hacer la consulta');
    }
    setLoading(false);
  };

  const FalsaAlarma = (comment) => {
    const registro = {
      eventId: selector.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: selector.protocol_activity_subactivity,
      complete: true,
      order: selector.order,
      falseAlarm: true,
      comment,
    };

    ejecutarActividad(registro, 'falsa alarma');
  };
  const Iniciar = (item) => {
    const registro = {
      eventId: item.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      start: true,
      order: item.order,
    };
    ejecutarActividad(registro);
  };

  const marcarFalsaAlarma = (item) => {
    const registro = {
      eventId: item.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      start: true,
      order: item.order,
    };
    setFalsaAlarma(true);
    ejecutarActividad(registro);
  };

  const onFinish = (values) => {
    const { comment } = values;
    // Completar(comment);

    if (falsaAlarma) {
      FalsaAlarma(comment);
    } else {
      Completar(comment);
    }
  };

  const Completar = (comment) => {
    let registro = {
      eventId: selector.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: selector.protocol_activity_subactivity,
      complete: true,
      order: selector.order,
      comment,
    };

    const contienePalabras =
      selector.protocol_activity_subactivity.includes('falsa') &&
      selector.protocol_activity_subactivity.includes('alarma');

    if (contienePalabras) {
      registro.falseAlarm = false;
    }
    finalizarActividad(registro);
  };

  const cancel = () => {
    funcionCerra();
    setOpen(false);
  };

  useEffect(() => {
    form.resetFields();
    setFalsaAlarma(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <div>
      {typeLabel === 'Falsa Alarma' ? (
        <>
          <div>
            <Button
              loading={loading}
              key="console"
              onClick={() => marcarFalsaAlarma(selector)}
              style={{ marginBottom: '10px', width: '200px' }}
            >
              Es una Falsa alarma
            </Button>
          </div>
          <div>
            <Button
              loading={loading}
              key="console"
              onClick={() => Iniciar(selector)}
              style={{ marginBottom: '10px', width: '200px' }}
            >
              No es una Falsa alarma
            </Button>
          </div>
        </>
      ) : (
        <Button
          type="primary"
          loading={loading}
          key="console"
          onClick={() => Iniciar(selector)}
          style={{ marginBottom: '10px', width: '200px' }}
        >
          Iniciar
        </Button>
      )}

      <Modal
        title={falsaAlarma ? 'Justificar Falsa Alarma' : 'Justificar Acción'}
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => cancel()}
        footer={null}
      >
        <Form
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="comment"
            label="Comentario"
            rules={[
              {
                required: true,
                message: 'Debes de completar este campo.',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Enviar
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
