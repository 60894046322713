/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

const RequestStreamParams = (cameraId, signalType) => {
  return {
    CameraId: cameraId,
    DestWidth: 600,
    DestHeight: 450,
    SignalType: signalType /*'Live' or 'Playback'*/,
    MethodType: 'Push' /*'Pull'*/,
    Fps: 25, // This doesn't work for Pull mode, but we have to supply it anyway to keep the server happy
    ComprLevel: 71,
    KeyFramesOnly: 'No' /*'Yes'*/, // Server will give only key frame thumb nails. This will reduce FPS
    RequestSize: 'Yes',
    StreamType: 'Transcoded',
  };
};

export default function useVmsCameraVideo({
  cameraInfo,
  vmsSession,
  live,
  setLive,
  playback,
  setPlayback,
}) {
  const [loading, setLoading] = useState(true);

  let videoInfo = useRef({
    Id: null,
    container: null,
    canvas: null,
    canvasContext: null,
    image: null,
    imageURL: null,
    videoController: null,
    drawing: null,
    playbackTimestamp: null,
    playbackSpeed: null,
    streamRequest: null,
    isLive: null,
    lastFrameTime: null,
    frameCheckIntervalId: null,
  });

  /**
   * Executed on received frame.
   */
  const videoConnectionReceivedFrame = (frame) => {
    if (!videoInfo.current.drawing && frame.dataSize > 0) {
      videoInfo.current.drawing = true;

      if (frame.hasSizeInformation) {
        const multiplier =
          frame.sizeInfo.destinationSize.resampling *
            vmsSession.XPMobileSDK.getResamplingFactor() || 1;
        videoInfo.current.image.width =
          multiplier * frame.sizeInfo.destinationSize.width;
        videoInfo.current.image.height =
          multiplier * frame.sizeInfo.destinationSize.height;
      }

      if (videoInfo.current.imageURL) {
        window.URL.revokeObjectURL(videoInfo.current.imageURL);
      }

      videoInfo.current.imageURL = window.URL.createObjectURL(frame.blob);

      videoInfo.current.image.src = videoInfo.current.imageURL;

      if (
        playback.isPlayback &&
        frame.timestamp.getTime() !==
          videoInfo.current.playbackTimestamp.valueOf()
      ) {
        updateTime(dayjs(frame.timestamp));
      }

      videoInfo.current.lastFrameTime = new Date().getTime();
    }
  };

  const videoConnectionObserver = {
    videoConnectionReceivedFrame: videoConnectionReceivedFrame,
  };

  const checkCanvasChange = () => {
    const timeDiff = new Date().getTime() - videoInfo.current.lastFrameTime;
    let lowFrames = timeDiff > 1000;
    if (videoInfo.current.isLive) {
      setLive((prevState) => {
        return { ...prevState, lowFrames: lowFrames };
      });
    } else {
      lowFrames = lowFrames && videoInfo.current.playbackSpeed !== 0;
      setPlayback((prevState) => {
        return { ...prevState, lowFrames: lowFrames };
      });
    }

    if (timeDiff > 5000 && videoInfo.current.isLive && live.playing) {
      stop();
      setLoading(true);
      requestLiveVideoStream();
    }

    if (
      timeDiff > 20000 &&
      !videoInfo.current.isLive &&
      videoInfo.current.playbackSpeed !== 0
    ) {
      stop();
      setLoading(true);
      requestPlaybackVideoStream();
    }
  };

  /**
   * Video stream request callback
   */
  const requestStreamCallback = (videoConnection) => {
    videoInfo.current.videoController = videoConnection;
    videoConnection.addObserver(videoConnectionObserver);
    videoConnection.open();
    setLoading(false);
    videoInfo.current.lastFrameTime = new Date().getTime();
    if (!videoInfo.current.frameCheckIntervalId) {
      videoInfo.current.frameCheckIntervalId = setInterval(
        checkCanvasChange,
        2000
      );
    }
  };

  /**
   * Executed on image load.
   */
  const onImageLoad = (event) => {
    videoInfo.current.canvas.width = videoInfo.current.image.width;
    videoInfo.current.canvas.height = videoInfo.current.image.height;
    videoInfo.current.canvasContext.drawImage(
      videoInfo.current.image,
      0,
      0,
      videoInfo.current.canvas.width,
      videoInfo.current.canvas.height
    );

    videoInfo.current.drawing = false;
  };

  const onImageError = (event) => {
    videoInfo.current.drawing = false;
  };

  /**
   * Stop camera stream
   */
  const stop = () => {
    videoInfo.current.canvasContext.clearRect(
      0,
      0,
      videoInfo.current.canvas.width,
      videoInfo.current.canvas.height
    );
    if (videoInfo.current.videoController) {
      videoInfo.current.videoController.removeObserver(videoConnectionObserver);
      videoInfo.current.videoController.close();
      videoInfo.current.videoController = null;
    }

    if (videoInfo.current.streamRequest) {
      vmsSession.XPMobileSDK.cancelRequest(videoInfo.current.streamRequest);
      videoInfo.current.streamRequest = null;
    }

    if (videoInfo.current.frameCheckIntervalId) {
      clearInterval(videoInfo.current.frameCheckIntervalId);
      videoInfo.current.frameCheckIntervalId = null;
    }
  };

  function resetState() {
    videoInfo.current.playbackSpeed = 0;

    if (videoInfo.current.streamRequest) {
      vmsSession.XPMobileSDK.cancelRequest(videoInfo.current.streamRequest);
      videoInfo.current.streamRequest = null;
    }
  }

  /**
   * Switch to camera playback mode.
   */
  const switchToPlayback = () => {
    stop();

    videoInfo.current.playbackTimestamp = playback.timeStamp;

    resetState();

    updateTime(videoInfo.current.playbackTimestamp);
    requestPlaybackVideoStream();
  };

  /**
   * Change video speed
   */
  const playbackChangeSpeed = (speed) => {
    if (
      !videoInfo.current.videoController ||
      speed === videoInfo.current.playbackSpeed
    )
      return;

    setLoading(true);
    speed = Math.round(speed);

    var params = {
      VideoId: videoInfo.current.videoController.videoId,
      Speed: speed,
    };

    vmsSession.XPMobileSDK.ChangeStream(params, () => {
      setLoading(false);
    });

    videoInfo.current.playbackSpeed = speed;
  };

  const playbackChangeTime = (timeStamp) => {
    if (!videoInfo.current.videoController) return;
    setLoading(true);
    let params = {
      VideoId: videoInfo.current.videoController.videoId,
      SeekType: 'Time',
      Time: timeStamp.valueOf(),
    };
    vmsSession.XPMobileSDK.ChangeStream(params, () => {
      setLoading(false);
      //this.dispatchCustomEvent('playStream', this.container, {videoConnection: this.videoController})
    });
  };

  /**
   * Updates time element
   */
  const updateTime = (timestamp) => {
    if (live.isLive) return;

    videoInfo.current.playbackTimestamp = timestamp;

    setPlayback((prevState) => {
      return { ...prevState, timeStamp: timestamp };
    });
  };

  const requestLiveVideoStream = () => {
    videoInfo.current.streamRequest = vmsSession.XPMobileSDK.RequestStream(
      RequestStreamParams(cameraInfo.Id, 'Live'),
      requestStreamCallback,
      function (error) {}
    );
    videoInfo.current.isLive = true;
  };

  const requestPlaybackVideoStream = () => {
    videoInfo.current.streamRequest = vmsSession.XPMobileSDK.RequestStream(
      RequestStreamParams(videoInfo.current.Id, 'Playback'),
      requestStreamCallback,
      null
    );
    videoInfo.current.isLive = false;
  };
  const init = () => {
    videoInfo.current = {
      Id: cameraInfo.Id,
      container: document.querySelector('#container' + cameraInfo.Id),
      canvas: document.querySelector('#container' + cameraInfo.Id + ' canvas'),
      image: document.createElement('img'),
      drawing: false,
      playbackTimestamp: new Date(),
      playbackSpeed: 0,
    };
    videoInfo.current.canvasContext = videoInfo.current.canvas.getContext('2d');
    videoInfo.current.image.addEventListener('error', onImageError);
    videoInfo.current.image.addEventListener('load', onImageLoad);
    vmsSession.XPMobileSDK.library.Connection.webSocketBrowser = false;
  };

  useEffect(() => {
    if (live.isLive && live.playing) {
      setLoading(true);
      init();
      requestLiveVideoStream();
    }
    return () => {
      if (!videoInfo.current.isLive) return;
      stop();
    };
  }, [live.isLive, live.playing]);

  useEffect(() => {
    if (playback.isPlayback) {
      setLoading(true);
      init();
      switchToPlayback();
    }
    return () => {
      if (videoInfo.current.isLive) return;
      stop();
    };
  }, [playback.isPlayback]);

  useEffect(() => {
    if (playback.isPlayback) {
      playbackChangeSpeed(playback.speed);
    }
  }, [playback.speed]);

  useEffect(() => {
    if (playback.timeStampUserChange) {
      playbackChangeTime(playback.timeStamp);
      setPlayback((prevState) => {
        return { ...prevState, timeStampUserChange: false };
      });
    }
  }, [playback.timeStampUserChange]);

  return {
    loadingVideo: loading,
    videoController: videoInfo.current.videoController,
  };
}
