import React, { useState } from 'react';
import { Button, Popconfirm, Input, message } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { downloadExcel } from '../../utils/DownloadExcelFile';

export default function BotonExportarExcelM({ documentos = [], columns }) {
  const cancel = (e) => {};
  const confirm = (e) => {
    if (inputValue.length !== 0) {
      let documentosNew = transformarArray(documentos);
      downloadExcel(columns, documentosNew, inputValue);
    } else {
      message.error('Añada un nombre al archivo');
    }
  };

  const [inputValue, setInputValue] = useState('');
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  function transformarArray(arrayOriginal) {
    const estadoFiltros = {
      Open: 'Abierto',
      InProgress: 'En progreso',
      Closed: 'Cerrado',
      FalseAlarm: 'Falsa alarma',
    };

    if (arrayOriginal.length !== 0) {
      return arrayOriginal.map((objetoOriginal) => {
        const nuevoObjeto = objetoOriginal;

        // Extraer las propiedades deseadas
        nuevoObjeto.nombrelocation = objetoOriginal.location?.name || '';
        nuevoObjeto.specificLocation = objetoOriginal.location?.specific || '';
        nuevoObjeto.zoneLocation = objetoOriginal.location?.zone || '';
        nuevoObjeto.totalCost = objetoOriginal.cost?.total || 0;
        nuevoObjeto.eventState = estadoFiltros[objetoOriginal.state];

        // Mantener otras propiedades del objeto

        return nuevoObjeto;
      });
    }
  }

  return (
    <div>
      {' '}
      <Popconfirm
        title={
          <Input
            placeholder="Ingresa Nombre Documento"
            value={inputValue}
            onChange={handleChange}
            required
          />
        }
        placement="rightTop"
        // description="Are you sure to delete this task?"
        onConfirm={() => confirm()}
        onCancel={cancel}
        okText="Descargar"
        cancelText="Cancelar"
      >
        <Button
          style={{ marginRight: '5px', marginTop: '5px' }}
          disabled={documentos.length === 0}
          icon={<FileExcelOutlined />}
          type="primary"
          // loading={loadingButton}
        >
          Exportar a excel
        </Button>
      </Popconfirm>
    </div>
  );
}
