import React, { useEffect, useState } from 'react';
import { Button, Modal, message, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import activitiesService from '../../../../services/activitiesService';
import SeguimientodeActividades from '../../../Listas/SeguimientodeActividades';

export default function ModalActividadesMobile({ record, fechData }) {
  const [open, setOpen] = useState();

  const [activitiesItems, setActivities] = useState(null);
  const ajusteArray = (data) => {
    const sortedData = data.sort((a, b) => {
      const orderA = parseFloat(a.order);
      const orderB = parseFloat(b.order);

      // Comparar los valores como números
      return orderA - orderB;
    });

    return sortedData;
  };
  const consultaACTIVIDADES = async () => {
    //setActivities(null);
    try {
      const response = await activitiesService.consultarActividadEspecifica(
        record.eventId,
        record.protocol
      );
      // Lógica para ajustar el array
      let data = ajusteArray(response?.Items || []);
      setActivities(data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logró hacer la consulta');
    }
  };
  useEffect(() => {
    if (open) {
      consultaACTIVIDADES();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Button
        key={`${record.protocol}-${record.eventId}`}
        type="text"
        onClick={() => setOpen(true)}
      >
        <PlusOutlined />
      </Button>

      <Modal
        key={`${record.protocol}-${record.eventId}`}
        title="Gestión de protocolo"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={'100%'}
        footer={false}
        style={{ paddingTop: '20px' }}
      >
        <>
          {activitiesItems ? (
            <SeguimientodeActividades
              item={activitiesItems || []}
              refreshEventos={consultaACTIVIDADES}
              dateIni={
                record.state !== 'Started'
                  ? activitiesItems.length
                  : parseFloat(record?.token?.activity)
              }
              consultaProtocolos={fechData}
            />
          ) : (
            <Spin />
          )}
        </>
      </Modal>
    </>
  );
}
