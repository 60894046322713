import React, { useState, useEffect } from 'react';
import { Modal, message, Button, Form, Input, Select, Tooltip } from 'antd';
import { formarLimipiar } from '../../../../utils/formarLimipiar';
// import { PlusCircleOutlined } from '@ant-design/icons';

import TablaImplicados from './TablaImplicados';

import eventService from '../../../../services/eventService';

const { Option } = Select;

export default function ModalCrearImplicados({ item, eventId }) {
  const [form] = Form.useForm();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingCrear, setLoadingTrue] = useState(false);
  const [loadingImplicado, setLoadingImplicado] = useState(false);

  const [implicados, setImplicados] = useState([]);

  const handleFormSubmit = (values) => {
    // Aquí puedes enviar los datos a tu servicio de notificaciones

    values.protocol_cause_id = item.elemento + '_' + values.IdUser;
    let registro = {
      gender: values.gender,
      name: values.name,
      nationality: values.nationality,
      protocol_cause_id: item.elemento + '_' + values.IdUser,
      features: values.features || '',
    };
    crearImplicador(registro);
  };

  const crearImplicador = async (registro) => {
    setLoadingTrue(true);
    try {
      // Lógica para enviar la solicitud de edición del evento al servidor
      await eventService.crearImplicado(eventId, registro);
      setLoadingImplicado(false);
      message.success('Implicado Creado');
      obtenerImplicador();

      // setShow(false);
      // Manejar la respuesta del servidor, si es necesario
    } catch (error) {
      console.error('Error al crear implicado', error);
    }
    setLoadingTrue(false);
  };

  const obtenerImplicador = async () => {
    setLoading(true);
    try {
      // Lógica para enviar la solicitud de edición del evento al servidor
      const response = await eventService.consultarImplicados(
        eventId,
        item.elemento
      );
      setImplicados(response?.Items || []);
    } catch (error) {
      console.error('Error al editar el evento:', error);
      //message.error('No se logro registrar falsa alarma');
      // Manejar errores, si es necesario
    }
    setLoading(false);
  };

  const abrirCrear = () => {
    form.resetFields();
    setLoadingImplicado(true);
  };

  useEffect(() => {
    // Este código se ejecutará solo cuando `open` sea `true`
    // editarEventoEnServidor();
    if (show) {
      obtenerImplicador();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  // eslint-

  const countryOptions = [
    // { value: 'AR', name: 'Argentina' },
    // { value: 'BO', name: 'Bolivia' },
    // { value: 'BR', name: 'Brasil' },
    // { value: 'CL', name: 'Chile' },
    // { value: 'CO', name: 'Colombia' },
    // { value: 'EC', name: 'Ecuador' },
    // { value: 'GY', name: 'Guyana' },
    // { value: 'PY', name: 'Paraguay' },
    // { value: 'PE', name: 'Perú' },
    // { value: 'SR', name: 'Surinam' },
    // { value: 'UY', name: 'Uruguay' },
    // { value: 'VE', name: 'Venezuela' },
    // { value: 'US', name: 'Estados Unidos' },
    // { value: 'CA', name: 'Canadá' },
    // { value: 'MX', name: 'México' },
    // { value: 'GT', name: 'Guatemala' },
    // { value: 'HN', name: 'Honduras' },
    // { value: 'SV', name: 'El Salvador' },
    // { value: 'NI', name: 'Nicaragua' },
    // { value: 'CR', name: 'Costa Rica' },
    // { value: 'PA', name: 'Panamá' },
    // { value: 'DO', name: 'República Dominicana' },
    // { value: 'CU', name: 'Cuba' },
    // { value: 'HT', name: 'Haití' },
    // { value: 'JM', name: 'Jamaica' },
    // { value: 'PR', name: 'Puerto Rico' },
    // { value: 'ES', name: 'España' },
    // { value: 'FR', name: 'Francia' },
    // { value: 'IT', name: 'Italia' },
    // { value: 'DE', name: 'Alemania' },
    // { value: 'GB', name: 'Reino Unido' },
    // { value: 'PT', name: 'Portugal' },
    { value: 'AR', name: 'Argentina' },
    { value: 'BO', name: 'Bolivia' },
    { value: 'BR', name: 'Brasil' },
    { value: 'CL', name: 'Chile' },
    { value: 'CO', name: 'Colombia' },
    { value: 'EC', name: 'Ecuador' },
    { value: 'GY', name: 'Guyana' },
    { value: 'PY', name: 'Paraguay' },
    { value: 'PE', name: 'Perú' },
    { value: 'SR', name: 'Surinam' },
    { value: 'UY', name: 'Uruguay' },
    { value: 'VE', name: 'Venezuela' },
    { value: 'US', name: 'Estados Unidos' },
    { value: 'CA', name: 'Canadá' },
    { value: 'MX', name: 'México' },
    { value: 'GT', name: 'Guatemala' },
    { value: 'HN', name: 'Honduras' },
    { value: 'SV', name: 'El Salvador' },
    { value: 'NI', name: 'Nicaragua' },
    { value: 'CR', name: 'Costa Rica' },
    { value: 'PA', name: 'Panamá' },
    { value: 'DO', name: 'República Dominicana' },
    { value: 'CU', name: 'Cuba' },
    { value: 'HT', name: 'Haití' },
    { value: 'JM', name: 'Jamaica' },
    { value: 'PR', name: 'Puerto Rico' },
    { value: 'ES', name: 'España' },
    { value: 'FR', name: 'Francia' },
    { value: 'IT', name: 'Italia' },
    { value: 'DE', name: 'Alemania' },
    { value: 'GB', name: 'Reino Unido' },
    { value: 'PT', name: 'Portugal' },
    { value: 'GR', name: 'Grecia' },
    { value: 'NL', name: 'Países Bajos' },
    { value: 'BE', name: 'Bélgica' },
    { value: 'SE', name: 'Suecia' },
    { value: 'DK', name: 'Dinamarca' },
    { value: 'NO', name: 'Noruega' },
    { value: 'FI', name: 'Finlandia' },
    { value: 'AT', name: 'Austria' },
    { value: 'CH', name: 'Suiza' },
    { value: 'IE', name: 'Irlanda' },
    { value: 'PL', name: 'Polonia' },
    { value: 'CZ', name: 'República Checa' },
    { value: 'HU', name: 'Hungría' },
    { value: 'SK', name: 'Eslovaquia' },
    { value: 'SI', name: 'Eslovenia' },
    { value: 'HR', name: 'Croacia' },
    { value: 'BG', name: 'Bulgaria' },
    { value: 'RO', name: 'Rumanía' },
    { value: 'LT', name: 'Lituania' },
    { value: 'LV', name: 'Letonia' },
    { value: 'EE', name: 'Estonia' },
    { value: 'CY', name: 'Chipre' },
    { value: 'MT', name: 'Malta' },
    { value: 'LU', name: 'Luxemburgo' },
    { value: 'IS', name: 'Islandia' },
    { value: 'LI', name: 'Liechtenstein' },
    { value: 'MC', name: 'Mónaco' },
    { value: 'SM', name: 'San Marino' },
    { value: 'VA', name: 'Ciudad del Vaticano' },
    { value: 'MK', name: 'Macedonia del Norte' },
    { value: 'AL', name: 'Albania' },
    { value: 'RS', name: 'Serbia' },
    { value: 'ME', name: 'Montenegro' },
    { value: 'BA', name: 'Bosnia y Herzegovina' },
    { value: 'XK', name: 'Kosovo' },
    { value: 'MD', name: 'Moldavia' },
    { value: 'BY', name: 'Bielorrusia' },
    { value: 'UA', name: 'Ucrania' },
    { value: 'RU', name: 'Rusia' },
    { value: 'CN', name: 'China' },
    { value: 'JP', name: 'Japón' },
    { value: 'IN', name: 'India' },
    { value: 'ID', name: 'Indonesia' },
    { value: 'PK', name: 'Pakistán' },
    { value: 'BD', name: 'Bangladés' },
    { value: 'PH', name: 'Filipinas' },
    { value: 'VN', name: 'Vietnam' },
    { value: 'TH', name: 'Tailandia' },
    { value: 'MY', name: 'Malasia' },
    { value: 'KR', name: 'Corea del Sur' },
    { value: 'KP', name: 'Corea del Norte' },
    { value: 'MM', name: 'Myanmar' },
    { value: 'LK', name: 'Sri Lanka' },
    { value: 'NP', name: 'Nepal' },
    { value: 'KH', name: 'Camboya' },
    { value: 'LA', name: 'Laos' },
    { value: 'MM', name: 'Mongolia' },
    { value: 'TW', name: 'Taiwán' },
    { value: 'HK', name: 'Hong Kong' },
    { value: 'MO', name: 'Macao' },
    { value: 'KZ', name: 'Kazajistán' },
    { value: 'KG', name: 'Kirguistán' },
    { value: 'UZ', name: 'Uzbekistán' },
    { value: 'TJ', name: 'Tayikistán' },
    { value: 'TM', name: 'Turkmenistán' },
    { value: 'AF', name: 'Afganistán' },
    { value: 'IR', name: 'Irán' },
    { value: 'IQ', name: 'Irak' },
    { value: 'SY', name: 'Siria' },
    // Agrega más países según sea necesario
  ];

  return (
    <>
      <Tooltip title={'Ver implicados a causa'}>
        <Button onClick={() => setShow(true)}>Implicados</Button>
      </Tooltip>

      <Modal
        title={'Lista de implicados de: ' + formarLimipiar(item.elemento || '')}
        centered
        open={show}
        onOk={() => setShow(false)}
        onCancel={() => setShow(false)}
        width={'50%'}
        footer={false}
      >
        {loadingImplicado ? (
          <Form form={form} onFinish={handleFormSubmit} layout="vertical">
            <Form.Item
              label="Nombre"
              name="name"
              rules={[
                { required: true, message: 'Por favor, ingresa tu nombre.' },
                {
                  pattern: /^[^0-9]+$/,
                  message: 'El nombre no debe contener números.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Id"
              name="IdUser"
              rules={[{ required: true, message: 'Por favor, ingresa tu Id.' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Género"
              name="gender"
              rules={[
                { required: true, message: 'Por favor, selecciona tu género.' },
              ]}
            >
              <Select placeholder="Selecciona tu género">
                <Option value="M">Masculino</Option>
                <Option value="F">Femenino</Option>
                <Option value="O">Otro</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Nacionalidad"
              name="nationality"
              rules={[
                {
                  required: true,
                  message: 'Por favor, ingresa tu nacionalidad.',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Selecciona una nacionalidad"
                optionFilterProp="children"
              >
                {countryOptions.map((country) => (
                  <Option
                    key={country.value}
                    value={country.value}
                    label={country.name}
                  >
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Caracteristicas"
              name="features"
              rules={[
                {
                  pattern: /^[^0-9]+$/,
                  message: 'El nombre no debe contener números.',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={loadingCrear}
              >
                Crear
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // Establece el ancho máximo al 100% del contenedor principal
              overflow: 'hidden', // Oculta el contenido que se desborda
            }}
          >
            <div
              style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}
            ></div>

            <div
              style={{
                flex: 1,
                textAlign: 'right',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Button onClick={() => abrirCrear()}>Crear implicado</Button>
            </div>
          </div>
        )}

        <TablaImplicados
          items={implicados}
          laoding={loading}
          eventId={eventId}
          obtenerImplicador={obtenerImplicador}
        />
      </Modal>
    </>
  );
}
