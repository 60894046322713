import React, { useState } from 'react';
import {
  Form,
  Modal,
  message,
  Button,
  InputNumber,
  Alert,
  Empty,
  Avatar,
  Input,
} from 'antd';
import UploadButton from '../../botones/UploadButton';
import eventService from '../../../services/eventService';
import { FileProtectOutlined } from '@ant-design/icons';

export default function CostModal({
  open,
  setOpen,
  pantalla = 'Actividades',
  record,
  fetchData,
}) {
  const [loadingButton, setLoadingButton] = useState(false);
  const [keyDocument, setKeyDocument] = useState(record?.cost?.report || '');

  const handleFormSubmit = (values) => {
    let registro = {
      userId: '1010101',
      item: {
        cost: {
          total: values.totalCost || 0,
          report: keyDocument || '',
          description: values.description || '',
        },
      },
    };

    asignarCosto(registro);
  };

  const asignarCosto = async (registro) => {
    setLoadingButton(true);
    try {
      await eventService.EditarEvento(record.id, registro);
      message.success('Envío Exitoso');
      setOpen(false);
      fetchData();
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('No se logro editar información');
    }
    setLoadingButton(false);
  };
  const inicialValues = {
    totalCost: record.cost.total,
  };

  return (
    <>
      <Modal
        title={`Asignar costo de operacion de Evento: ${record?.id || ''}`}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={'50%'}
        footer={false}
      >
        <div>
          <Form
            onFinish={handleFormSubmit}
            layout="vertical"
            initialValues={inicialValues}
          >
            <Form.Item
              label="Costo estimado en Pesos Colombianos"
              name="totalCost"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese el costo estimado',
                },
                {
                  pattern: /^[0-9]*$/,
                  message: 'Ingrese solo números',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                defaultValue={0}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>

            <Form.Item
              label="Comentarios"
              name="description"
              rules={
                [
                  // {
                  //   required: true,
                  //   message: 'Por favor ingrese el costo estimado',
                  // },
                ]
              }
            >
              <Input />
            </Form.Item>

            <Alert
              style={{ marginBottom: '10px' }}
              message="Información importante."
              description=" Solo se puede subir un documento para validar el costo."
              type="info"
              showIcon
            >
              {' '}
            </Alert>
            <div className="contenedortipoA">
              <div className="columna">
                {keyDocument.length === 0 ? (
                  <Empty />
                ) : (
                  <>
                    <Avatar
                      size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 64,
                        xl: 80,
                        xxl: 100,
                      }}
                      shape="square"
                      style={{ backgroundColor: '#7AC142' }}
                      icon={<FileProtectOutlined />}
                    />
                    <p>{keyDocument.split('/')[2]}</p>
                  </>
                )}
              </div>
              <div className="columna">
                <UploadButton
                  eventId={record.id}
                  setKey={setKeyDocument}
                  tipo="saldo"
                />
              </div>
            </div>

            {/* <DownloadButton /> */}

            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loadingButton}
              style={{ marginTop: '20px' }}
            >
              Guardar
            </Button>
          </Form>
        </div>
      </Modal>
    </>
  );
}
