import React, { useState } from 'react';
import { Dropdown, Menu, Button, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import ModalEditarEvento from '../ModalEditarEvento';
import SendWoT from '../../components/sendWoT';
import ModalActividadesEventos from './ModalActividadesEventos';
import ModalFalsaAlarma from '../../generales/ModalFalsaAlarma';
import ModalInformePDF from '../../generales/ModalInformePDF';
import ModalConfirmacion from '../../generales/ModalConfirmacion';
import CostModal from '../../components/CostModal';
import protocolosService from '../../../../services/protocolosService';
import useAuth from '../../../../hooks/useAuth';

const MenuBoton = ({ item, fetchData }) => {
  const [show, setShow] = useState(false);
  const [showNotificaciones, setShowNotificaciones] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showActividades, setShowActividades] = useState(false);
  const [showModalInforme, setModalInforme] = useState(false);
  const [showCosto, setCosto] = useState(false);
  const [showConfirmacion, setConfirmacion] = useState(false);
  const [LoadingButon, setLoadingButon] = useState(false);
  const { session } = useAuth();
  /* eslint-enable no-template-curly-in-string */

  const funcionBoton = async () => {
    // Utilizar Promise.all para esperar todas las consultas asincrónicas
    setLoadingButon(true);
    Promise.all(
      item.protocols.map(async (protocolo) => {
        let registro = {
          eventId: item.id,
          protocol: protocolo,
          start: true,
          userId: session?.user?.id || session?.user?.sub,
        };
        await editarProtocolo(registro);
      })
    ).then(() => {
      //console.log('Todas las consultas asincrónicas han finalizado');
      setLoadingButon(false);
      message.success('Solicitud realizada');
      fetchData(); // Llamar a fetchData después de que todas las consultas hayan finalizado
      setConfirmacion(false);
    });
  };

  const editarProtocolo = async (registro) => {
    try {
      await protocolosService.EditarProtocolo('', registro);
    } catch (error) {
      console.error('Error al editar el protocolo:', error);
      message.error('No se logró realizar la consulta');
    }
  };

  // Contenido del menú desplegable
  const menu = (
    <Menu>
      {/* {item.state === 'Open' && (
        <Menu.Item key="1" onClick={() => showPromiseConfirm()}>
          Iniciar PARP
        </Menu.Item>
      )} */}
      {item.state === 'Open' && session.roles.events.isAllowedtoUpdate && (
        <Menu.Item key="1" onClick={() => setConfirmacion(true)}>
          Iniciar PARP
        </Menu.Item>
      )}

      {/* {item.state === 'InProgress' &&
        session.roles.events.isAllowedtoUpdate && (
          <Menu.Item key="1" onClick={() => setShowActividades(true)}>
            Ver PARP
          </Menu.Item>
        )} */}

      {session.roles.events.isAllowedtoUpdate && (
        <Menu.Item key="2" onClick={() => setShow(true)}>
          Editar evento
        </Menu.Item>
      )}
      {/* {session.roles.events.isAllowedtoUpdate && (
        <Menu.Item key="3" onClick={() => setShowModal(true)}>
          Falsa Alarma
        </Menu.Item>
      )} */}

      {item.state === 'Closed' && (
        <Menu.Item key="4" onClick={() => setModalInforme(true)}>
          Informe Pdf
        </Menu.Item>
      )}

      {item.state === 'FalseAlarm' && (
        <Menu.Item key="4" onClick={() => setModalInforme(true)}>
          Informe Pdf
        </Menu.Item>
      )}

      {/* <BotonInformePDF /> */}
      <Menu.Item key="5" onClick={() => setShowNotificaciones(true)}>
        Notificar
      </Menu.Item>
      {item.state === 'Closed' && session.roles.events.isAllowedtoLoadCost && (
        <Menu.Item key="6" onClick={() => setCosto(true)}>
          Asignar Costo
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Button>
          <DownOutlined />
        </Button>
      </Dropdown>
      <ModalEditarEvento open={show} setOpen={setShow} item={item} />
      <ModalActividadesEventos
        setOpen={setShowActividades}
        open={showActividades}
        item={item}
      />
      <ModalInformePDF
        open={showModalInforme}
        setOpen={setModalInforme}
        item={item}
      />

      <ModalConfirmacion
        open={showConfirmacion}
        setOpen={setConfirmacion}
        funtion={funcionBoton}
        title="¿Está seguro de que desea iniciar los PARP?"
        message="Una vez iniciados los protocolos, no se podrán eliminar del evento. Por favor, valide los protocolos en la edición de eventos antes de iniciarlos."
        LoadingButon={LoadingButon}
      />

      <ModalFalsaAlarma
        open={showModal}
        setOpen={setShowModal}
        item={item}
        fetchData={fetchData}
      />
      <CostModal
        open={showCosto}
        setOpen={setCosto}
        pantalla=""
        record={item}
        fetchData={fetchData}
      />

      <SendWoT
        open={showNotificaciones}
        setOpen={setShowNotificaciones}
        record={item}
      />
    </>
  );
};

export default MenuBoton;
