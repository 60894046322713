import axios from 'axios';

const api = 'https://9l7ugl14e3.execute-api.us-east-1.amazonaws.com/prod';
const apiKey = process.env.REACT_APP_KEY_REMINDERS || '';

const ObtenerEventosCalendario = async (startDate = null, endDate = null) => {
  const path = `/reminders`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar actividades:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

// };

const crearEventoCalendario = async (registro) => {
  const path = `/reminders`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.post(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });

    return response.data; // Devolver los datos obtenidos from Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const eliminarEventoCalendario = async (id, registro = {}) => {
  const path = `/reminders/${id}`; // Ruta del evento específico
  const apiUrl = `${api}${path}`; // Asegúrate de definir `api` y `apiKey` adecuadamente

  try {
    const response = await axios.delete(apiUrl, {
      data: registro, // En Axios, el cuerpo de la solicitud DELETE se envía en la propiedad `data`
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey, // Asegúrate de definir `apiKey` adecuadamente
      },
    });

    return response.data; // Devolver los datos obtenidos de Axios response.data
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const remindersService = {
  crearEventoCalendario,
  ObtenerEventosCalendario,
  eliminarEventoCalendario,
};

export default remindersService;
