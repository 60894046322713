import axios from 'axios';

const api = 'https://fb7j0z6zof.execute-api.us-east-1.amazonaws.com/prod';
const apiKey = process.env.REACT_APP_KEY_ACTIVIDADES || '';

const consultarActividades = async () => {
  const path = `/activities`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar actividades:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const consultarActividadEspecifica = async (eventId, protocoloId) => {
  const path = `/activities?eventId=${eventId}&protocol_activity_subactivity=${protocoloId}`;

  let apiUrl = `${api}/activities?eventId=${eventId}`;

  if (protocoloId) {
    apiUrl = `${api}${path}`;
  } // Ruta del evento específico

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al consultar actividades:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const funcionActividad = async (registro) => {
  const path = `/activities`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json'); // Cambiado a JSON

  let raw = JSON.stringify(registro);

  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-api-key': apiKey,
    },
    body: raw,
    redirect: 'follow',
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json(); // Parsear la respuesta a JSON
    return data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const funcionCargarS3 = async (registro) => {
  const path = `/activities/evidences`; // Ruta del evento específico
  const apiUrl = `${api}${path}`;

  try {
    const response = await axios.post(apiUrl, registro, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al crear evento:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const consultardocumento = async (eventId = null) => {
  const path = '/activities/evidences'; // Ruta del evento específico
  const apiUrl = `${api}${path}`;
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'x-api-key': apiKey,
      },

      params: {
        eventId: eventId,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al consultar eventos:', error);
    throw error;
  }
};

const consultardocumentoPorProtocolo = async (
  eventId = null,
  protocolo = null
) => {
  const path = '/activities/evidences'; // Ruta del evento específico
  const apiUrl = `${api}${path}`;
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'x-api-key': apiKey,
      },

      params: {
        eventId: eventId,
        protocol_activity_subactivity_id: protocolo,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al consultar eventos:', error);
    throw error;
  }
};

// const eliminarArchivos = async (registro) => {
//   const path = `/activities/evidences`; // Ruta del evento específico
//   const apiUrl = `${api}${path}`;

//   let document = JSON.stringify(registro);

//   try {
//     const response = await axios.delete(apiUrl, document, {
//       headers: {
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//         'x-api-key': apiKey,
//       },
//     });
//     return response.data; // Devolver los datos obtenidos
//   } catch (error) {
//     console.error('Error al crear evento:', error);
//     throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
//   }
// };

const eliminarArchivos = async (registro) => {
  const path = '/activities/evidences'; // Ruta del evento específico
  const apiUrl = `https://fb7j0z6zof.execute-api.us-east-1.amazonaws.com/prod${path}`;

  const apiKey = process.env.REACT_APP_KEY_ACTIVIDADES || '';

  const data = registro;

  try {
    const response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': apiKey,
      },
      data: JSON.stringify(data),
    });
    return response.data; // Devolver los datos obtenidos
  } catch (error) {
    console.error('Error al eliminar evidencia:', error);
    throw error; // Relanzar el error para que se maneje en otro lugar si es necesario
  }
};

const activitiesService = {
  consultarActividades,
  consultarActividadEspecifica,
  funcionActividad,
  funcionCargarS3,
  consultardocumento,
  eliminarArchivos,
  consultardocumentoPorProtocolo,
};

export default activitiesService;
