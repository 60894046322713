import React, { useState, useEffect } from 'react';
import { Button, Card, message, Steps, Result, Typography } from 'antd';

import activitiesService from '../../services/activitiesService';
import useAuth from '../../hooks/useAuth';
import Loading from '../layouts/Loading';
import ModalComentario from '../modales/generales/ModalComentario';
import { formarLimipiar } from '../../utils/formarLimipiar';
import ModalEditarComentario from '../modales/generales/ModalEditarComentario';

import ModalIniciarActividad from './components/ModalIniciarActividad';
import dayjs from 'dayjs';
import PageHeader from '../headers/PageHeader';
import ModalListasubActividades from './components/ModalListasubActividades';
import TablaSubActividades from './components/TablaSubActividades';
import UploadButton from '../botones/UploadButton';
import TablaDocumentosActividades from './components/TablaDocumentosActividades';
const { Paragraph, Text } = Typography;

export default function SeguimientodeIntrusion({
  item,
  refreshEventos,
  consultaProtocolos,
  documentosActividades,
  protocol,
}) {
  function filtrarYAgruparPorOrder(arrayDeObjetos) {
    const objetosConEnteroOrder = [];

    arrayDeObjetos.forEach((objeto) => {
      const orderComoEntero = parseFloat(objeto.order);

      if (!isNaN(orderComoEntero) && orderComoEntero % 1 === 0) {
        // Entero
        objetosConEnteroOrder.push(objeto);
      }
    });

    const objetosAgrupadosPorDecimal = arrayDeObjetos.reduce(
      (agrupados, objeto) => {
        const orderComoEntero = parseInt(objeto.order);
        if (!isNaN(orderComoEntero)) {
          const orderDecimal =
            orderComoEntero % 1 !== 0 ? orderComoEntero % 1 : 0;
          const orderRedondeado = orderComoEntero - orderDecimal;

          if (!agrupados[orderRedondeado]) {
            agrupados[orderRedondeado] = [];
          }

          agrupados[orderRedondeado].push(objeto);
        }

        return agrupados;
      },
      {}
    );

    return [objetosConEnteroOrder, objetosAgrupadosPorDecimal];
  }

  const [objetosConEnteroOrder, objetosAgrupadosPorDecimal] =
    filtrarYAgruparPorOrder(item);

  const [currentStep, setCurrentStep] = useState(1);
  const [selector, SetSelector] = useState(
    objetosConEnteroOrder[currentStep - 1] || objetosConEnteroOrder[0]
  );

  const [, setKey] = useState('');

  const [loading, setLoading] = useState(false);
  const { session } = useAuth();

  const consultaEventos = (name = 'no') => {
    if (name === 'falsa alarma') {
      setCurrentStep(objetosConEnteroOrder.length);

      consultaProtocolos();
    } else {
      const contienePalabras =
        selector.protocol_activity_subactivity.includes('cerrar') &&
        selector.protocol_activity_subactivity.includes('evento');
      if (!contienePalabras) {
        setCurrentStep(currentStep + 1);
      } else {
        let objSelector = selector;
        objSelector.state = 'Terminated';
        SetSelector(objSelector);
        consultaProtocolos();
      }
    }
    refreshEventos();
  };

  const consultaEventosSubActicidades = (name = 'no') => {
    setCurrentStep(currentStep);

    consultaProtocolos();
    refreshEventos();
  };
  const consultaIniciar = () => {
    let objSelector = selector;
    objSelector.state = 'Started';
    SetSelector(objSelector);
  };

  useEffect(() => {
    SetSelector(objetosConEnteroOrder[currentStep - 1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const onChange = (value) => {
    // if (value > currentStep) {
    //   message.info('Debe completar primero esta Actividad');
    // } else {
    //   setCurrentStep(value + 1);

    //   SetSelector(objetosConEnteroOrder[value - 1]);
    // }
    setCurrentStep(value + 1);

    SetSelector(objetosConEnteroOrder[value - 1]);
  };

  const getColorBasedOnActivity = (activity) => {
    // Aquí puedes agregar tu lógica para determinar el color basado en las propiedades de 'activity'
    // Por ejemplo, supongamos que hay una propiedad 'status' que determina el color
    // Puedes ajustar esto según tus necesidades específicas

    if (activity.state === 'created') {
      return 'yellow'; // Color amarillo para actividades en estado 'created'
    } else {
      return 'green'; // Color verde por defecto
    }
  };

  const steps = objetosConEnteroOrder.map((activity, index) => {
    const splitResult = activity.protocol_activity_subactivity.split('_');
    const title = splitResult.length === 3 ? splitResult[1] : splitResult[2];

    return {
      title: formarLimipiar(title),
      activity: activity,
      order: parseInt(activity.order, 10),
      color: getColorBasedOnActivity(activity), // Agregamos la propiedad 'color' basada en la lógica
    };
  });

  const arrayEstados = {
    Created: 'warning',
    Started: 'info',
    Completed: 'success',
    Skipped: 'success',
    Terminated: 'success',
  };

  const ejecutarActividad = async (registro) => {
    setLoading(true);
    try {
      await activitiesService.funcionActividad(registro);
      message.success('Actividad Iniciada');
      consultaIniciar();
    } catch (error) {
      console.error(error);
      message.error('No se logro hacer la consulta');
    }
    setLoading(false);
  };

  const Iniciar = (item) => {
    const registro = {
      eventId: item.eventId,
      userId: session?.user?.id || session?.user?.sub,
      protocol_activity_subactivity: item.protocol_activity_subactivity,
      start: true,
      order: item.order,
    };
    ejecutarActividad(registro);
  };

  const activityAlarma = (item) => {
    const contienePalabras =
      item.protocol_activity_subactivity.includes('falsa') &&
      item.protocol_activity_subactivity.includes('alarma');
    let respuesta = null;
    if (contienePalabras) respuesta = 'Falsa Alarma';
    return respuesta;
  };

  const activityClose = (item) => {
    const contienePalabras =
      item.protocol_activity_subactivity.includes('cerrar') &&
      item.protocol_activity_subactivity.includes('evento');

    return contienePalabras;
  };

  function obtenerDatoMasReciente(arrayDeObjetos) {
    if (arrayDeObjetos.length === 0) {
      return { comment: 'sin registro' };
    }

    const arrayOrdenado = arrayDeObjetos
      .slice()
      .sort((a, b) => new Date(b.date) - new Date(a.date));

    return arrayOrdenado[0];
  }
  const formatDate = (date) => {
    return date ? dayjs(date).format('DD/MMM/YYYY') : 'Sin fecha';
  };

  return (
    <>
      {item.length === 0 ? (
        <Result
          status="404"
          title="Elemento no encontrado"
          subTitle="Lo sentimos, al parecer no encontramos tu solicitud. Por favor, refresca la página o ponte en contacto con soporte."
        />
      ) : (
        <div
          className="contenedortipoA"
          // style={{ maxWidth: '40rem', marginLeft: '10px' }}
        >
          <div className="columna">
            <h2>Lista de Actividades</h2>

            <Steps
              current={currentStep - 1}
              // status="process"
              //type="navigation"
              progressDot
              onChange={onChange}
              className="site-navigation-steps"
              direction="vertical"
              items={steps}
            />
          </div>
          <div className="columna">
            <PageHeader
              head=""
              title={objetosConEnteroOrder[0].eventId.replace(/\s/g, '') || ''}
              createby={`${objetosConEnteroOrder[0].protocol_activity_subactivity
                .split('_')[0]
                .replace(/\s/g, '')}`}
            ></PageHeader>
            <h2>Descripción de Actividad</h2>

            <div>
              <p>
                <span style={{ color: '#FAAD14' }}>Amarillo:</span>{' '}
                Pendiente&nbsp;&nbsp;&nbsp;
                <span style={{ color: 'blue' }}>Azul:</span>{' '}
                Iniciado&nbsp;&nbsp;&nbsp;
                <span style={{ color: 'green' }}>Verde:</span>{' '}
                Completado&nbsp;&nbsp;&nbsp;
              </p>
            </div>

            {selector ? (
              <>
                <Result
                  status={arrayEstados[selector.state] || 'error'}
                  title={formarLimipiar(
                    selector.protocol_activity_subactivity.split('_')[1] || ''
                  )}
                  subTitle={
                    selector?.description ||
                    'Esta Actividad no posee descripción'
                  }
                  extra={[
                    <>
                      {selector.comments.length === 0 ? (
                        <>
                          {session.roles.activities.isAllowedtoCreate && (
                            <>
                              {objetosAgrupadosPorDecimal[currentStep].length >
                              1 ? (
                                <>
                                  <p>secuencia de sub actividades</p>

                                  <ModalListasubActividades
                                    selector={selector}
                                    consultaEventos={
                                      consultaEventosSubActicidades
                                    }
                                    funcionCerra={consultaIniciar}
                                    subActivities={
                                      objetosAgrupadosPorDecimal[currentStep]
                                    }
                                    consultaIniciar={consultaEventos}
                                  />

                                  {selector.state === 'Started' && (
                                    <>
                                      {!activityClose(selector) && (
                                        <ModalComentario
                                          item={selector}
                                          consultaEventos={consultaEventos}
                                          label={
                                            activityAlarma(selector) ||
                                            'Completar'
                                          }
                                        />
                                      )}

                                      {selector.isMandatory === false && (
                                        <ModalComentario
                                          item={selector}
                                          consultaEventos={consultaEventos}
                                          label={'Saltar'}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {selector.state === 'Created' && (
                                    <>
                                      {activityAlarma(selector) ===
                                      'Falsa Alarma' ? (
                                        <Button
                                          type="primary"
                                          loading={loading}
                                          key="console"
                                          onClick={() => Iniciar(selector)}
                                          style={{
                                            marginBottom: '10px',
                                            width: '200px',
                                          }}
                                        >
                                          Iniciar
                                        </Button>
                                      ) : (
                                        <>
                                          {activityClose(selector) ? (
                                            <>
                                              {session.roles.activities
                                                .isAllowedtoClose && (
                                                <ModalIniciarActividad
                                                  selector={selector}
                                                  consultaIniciar={
                                                    consultaEventos
                                                  }
                                                  funcionCerra={consultaIniciar}
                                                  subActividades={
                                                    objetosAgrupadosPorDecimal[
                                                      currentStep
                                                    ]
                                                  }
                                                />
                                              )}
                                            </>
                                          ) : (
                                            <ModalIniciarActividad
                                              selector={selector}
                                              consultaIniciar={consultaEventos}
                                              funcionCerra={consultaIniciar}
                                              subActividades={
                                                objetosAgrupadosPorDecimal[
                                                  currentStep
                                                ]
                                              }
                                            />
                                          )}
                                        </>
                                      )}

                                      {selector.isMandatory === false && (
                                        <ModalComentario
                                          item={selector}
                                          consultaEventos={consultaEventos}
                                          label={'Saltar'}
                                        />
                                      )}
                                    </>
                                  )}

                                  {selector.state === 'Started' && (
                                    <>
                                      {!activityClose(selector) && (
                                        <ModalComentario
                                          item={selector}
                                          consultaEventos={consultaEventos}
                                          label={
                                            activityAlarma(selector) ||
                                            'Completar'
                                          }
                                        />
                                      )}

                                      {activityClose(selector) && (
                                        <>
                                          {session.roles.activities
                                            .isAllowedtoClose && (
                                            <ModalComentario
                                              item={selector}
                                              consultaEventos={consultaEventos}
                                              label={
                                                activityAlarma(selector) ||
                                                'Cerrar'
                                              }
                                            />
                                          )}
                                        </>
                                      )}

                                      {activityAlarma(selector) ===
                                        'Falsa Alarma' && (
                                        <ModalComentario
                                          item={selector}
                                          consultaEventos={consultaEventos}
                                          label={'No es falsa Alarma'}
                                        />
                                      )}
                                      {selector.isMandatory === false && (
                                        <ModalComentario
                                          item={selector}
                                          consultaEventos={consultaEventos}
                                          label={'Saltar'}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {selector.state === 'Started' && (
                            <>
                              {!activityClose(selector) && (
                                <ModalComentario
                                  item={selector}
                                  consultaEventos={consultaEventos}
                                  label={
                                    activityAlarma(selector) || 'Completar'
                                  }
                                />
                              )}

                              {activityClose(selector) && (
                                <>
                                  {session.roles.activities
                                    .isAllowedtoClose && (
                                    <ModalComentario
                                      item={selector}
                                      consultaEventos={consultaEventos}
                                      label={
                                        activityAlarma(selector) || 'Cerrar'
                                      }
                                    />
                                  )}
                                </>
                              )}

                              {activityAlarma(selector) === 'Falsa Alarma' && (
                                <ModalComentario
                                  item={selector}
                                  consultaEventos={consultaEventos}
                                  label={'No es falsa Alarma'}
                                />
                              )}
                              {selector.isMandatory === false && (
                                <ModalComentario
                                  item={selector}
                                  consultaEventos={consultaEventos}
                                  label={'Saltar'}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>,
                  ]}
                >
                  <>
                    {selector.state !== 'Created' && (
                      <>
                        <Card
                          type="inner"
                          title={
                            <Paragraph>
                              <Text
                                strong
                                style={{
                                  fontSize: 16,
                                }}
                              >
                                Comentario:
                              </Text>
                            </Paragraph>
                          }
                          extra={
                            <ModalEditarComentario
                              item={selector}
                              textactual={
                                obtenerDatoMasReciente(selector.comments)
                                  .comment || 'Sin descripción'
                              }
                              consultaActividades={consultaEventos}
                            />
                          }
                          actions={[
                            <span>
                              {`Última modificación: ${formatDate(
                                obtenerDatoMasReciente(selector.comments).date
                              )}`}
                            </span>,
                          ]}
                        >
                          <Paragraph>
                            {obtenerDatoMasReciente(selector.comments)
                              .comment || 'Sin descripción'}
                          </Paragraph>
                        </Card>
                        {objetosAgrupadosPorDecimal[currentStep].length > 1 && (
                          <TablaSubActividades
                            data={objetosAgrupadosPorDecimal[currentStep]}
                            consultaEventos={consultaEventosSubActicidades}
                          />
                        )}
                      </>
                    )}
                  </>

                  <UploadButton
                    eventId={selector.eventId}
                    setKey={setKey}
                    protocol={
                      selector.protocol_activity_subactivity.split('_')[0]
                    }
                    activity={selector.protocol_activity_subactivity}
                    extras={false}
                  />

                  <TablaDocumentosActividades
                    dataProtocol={
                      documentosActividades[
                        selector.protocol_activity_subactivity
                      ] || []
                    }
                    setRefresh={refreshEventos}
                    refresh={true}
                  />
                </Result>
              </>
            ) : (
              <Loading></Loading>
            )}
          </div>
        </div>
      )}
    </>
  );
}
