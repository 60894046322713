import { Button } from 'antd';

import { PtzControlIcon, PtzHomeIcon, ZoomInIcon, ZoomOutIcon } from './icons';
import styles from './jsonStyles.json'

export default function PtzControls({setLive}){

  const handlePtzControl = (action) => {
    setLive(prevState => {return {...prevState, ptzCommand: action}})
  }

  return (<div style={styles.ptzPanel}>
    <Button onClick={() => handlePtzControl("Right")} style={styles.right} icon={<PtzControlIcon/>}/>
    <Button onClick={() => handlePtzControl("DownRight")} style={styles.bottomRight} icon={<PtzControlIcon/>}/>
    <Button onClick={() => handlePtzControl("Down")} style={styles.bottom} icon={<PtzControlIcon/>}/>
    <Button onClick={() => handlePtzControl("DownLeft")} style={styles.bottomLeft} icon={<PtzControlIcon/>}/>
    <Button onClick={() => handlePtzControl("Left")} style={styles.left} icon={<PtzControlIcon/>}/>
    <Button onClick={() => handlePtzControl("UpLeft")} style={styles.topLeft} icon={<PtzControlIcon/>}/>
    <Button onClick={() => handlePtzControl("Up")} style={styles.top} icon={<PtzControlIcon/>}/>
    <Button onClick={() => handlePtzControl("UpRight")} style={styles.topRight} icon={<PtzControlIcon/>}/>
    <Button onClick={() => handlePtzControl("Home")} style={styles.center} icon={<PtzHomeIcon/>}/>
    <Button onClick={() => handlePtzControl("ZoomIn")} style={styles.bottomRightZoomIn} icon={<ZoomInIcon/>}/>
    <Button onClick={() => handlePtzControl("ZoomOut")} style={styles.bottomRightZoomOut} icon={<ZoomOutIcon/>}/>
  </div>);
}