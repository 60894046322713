/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import PrincipalGrafana from './components/PrincipalGrafana';

import ModalEventos from '../../components/modales/eventos/ModalEventos';
import ModalNuevoEvento from '../../components/modales/eventos/ModalNuevoEvento';
import ModalActividades from '../../components/modales/Actividades/ModalActividades';
import ModalReportes from '../../components/modales/Reportes/ModalReportes';
import ModalAlarmasPreventivas from '../../components/modales/Alarms/ModalAlarmasPreventivas';
import userService from '../../services/UserService';
import useAuth from '../../hooks/useAuth';
// import Contadores from "../../components/Grafana/Contadores";
// import MapaMetro from '../../components/map/MapaMetro';

export default function Dashboard({
  show,
  setShow,
  showNewEvent,
  setShowNewEvent,
  setShowActividades,
  showActividades,

  setShowReportes,
  showReportes,
  showAlertas,
  setAlertas,
  datosSeleccionados,
}) {
  const { setUsuariosList } = useAuth();
  const fetchData = async () => {
    try {
      const usuariosData = await userService.consultarUsuarios();

      setUsuariosList(usuariosData.response.Items);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
      // Manejar el error, mostrar un mensaje de error al usuario, etc.
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <PrincipalGrafana datosSeleccionados={datosSeleccionados} />

      <ModalEventos setOpen={setShow} open={show} />
      <ModalNuevoEvento setOpen={setShowNewEvent} open={showNewEvent} />
      <ModalActividades setOpen={setShowActividades} open={showActividades} />

      <ModalReportes setOpen={setShowReportes} open={showReportes} />
      <ModalAlarmasPreventivas setOpen={setAlertas} open={showAlertas} />
    </div>
  );
}
