import React, { useState, useEffect } from 'react';
import {
  Form,
  Select,
  Input,
  Modal,
  message,
  Button,
  Card,
  Row,
  Col,
  Radio,
} from 'antd';

import { WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import userService from '../../../services/UserService';

import 'dayjs/locale/es'; // Asegúrate de importar el idioma español

import PageHeader from '../../headers/PageHeader';
import notificacionesService from '../../../services/notificacionesService';

const { Option } = Select;

const SendWoT = ({ open, setOpen, record }) => {
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState('1');

  const fetchData = async () => {
    setLoading(true);
    try {
      const usuariosData = await userService.consultarUsuarios();

      const mappedRolesData = usuariosData.response.Items.filter(
        (user) => user.roles !== 'CISEAdministrador' && user.name !== '<empty>'
      ).map((user) => ({
        label: `${user.name} ${user.lastName}`,
        value: `${user.phone}/${user.name} ${user.lastName}`,
      }));
      setUsers(mappedRolesData);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
      // Manejar el error, mostrar un mensaje de error al usuario, etc.
    }
    setLoading(false);
  };

  const handleCardSelect = (card) => {
    setSelectedCard(card);
  };

  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const formatDate = (date) => {
    return date ? dayjs(date).format('DD/MM/YYYY hh:mm') : 'Sin fecha';
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);

    let arregloP = record.protocols.join(',');

    if (value === '1') {
      // const registro = {
      //   phone: numero,
      //   role: '',
      //   que: 'El evento: ' + record.id,
      //   quien: `Protocolos asociados: ${arregloP}`,
      //   cuando: formatDate(record.stateHistory.Open.date),
      //   donde: `${record.location.specific}-${record.location.name} Zona:${record.location.zone}`,
      //   como: record.title,
      //   observacion: values.messageContentAditional,
      // };
      // CreaNotifiacionWA(registro);

      // itera por todos los numeros seleccioandos y envia uno a uno los mensajes

      const promesasNotificaciones = values.phoneNumber.map((roleValue) => {
        const registro = {
          phone: roleValue.split('/')[0],
          que: 'El evento: ' + record.id,
          quien: `Protocolos asociados: ${arregloP}`,
          cuando: formatDate(record.stateHistory.Open.date),
          donde: `${record.location.specific}-${record.location.name} Zona:${record.location.zone}`,
          como: record.title.replace(/\n/g, ''),
          nombre: roleValue.split('/')[1],
          observacion: values?.messageContentAditional || ' ',
        };

        return CreaNotifiacionWA(registro);
      });

      try {
        // Espera a que se resuelvan todas las promesas
        await Promise.all(promesasNotificaciones);
        setOpen(false);
        message.success('Envío Exitoso');
      } catch (error) {
        console.error('Error al crear datos:', error);
        message.error('No se logró hacer la solicitud.');
      } finally {
        setLoading(false);
      }
    } else {
      const promesasNotificaciones = values.roles.map((roleValue) => {
        const registro = {
          phone: '',
          role: roleValue,
          que: 'El evento: ' + record.id,
          quien: `Protocolos asociados: ${arregloP}`,
          cuando: formatDate(record.stateHistory.Open.date),
          donde: `${record.location.specific}-${record.location.name} Zona:${record.location.zone}`,
          como: record.title.replace(/\n/g, ''),

          observacion: values?.messageContentAditional || ' ',
        };

        return CreaNotifiacionWA(registro);
      });

      try {
        // Espera a que se resuelvan todas las promesas
        await Promise.all(promesasNotificaciones);
        setOpen(false);
        message.success('Envío Exitoso');
      } catch (error) {
        console.error('Error al crear datos:', error);
        message.error('No se logró hacer la solicitud.');
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const CreaNotifiacionWA = async (registro) => {
    try {
      await notificacionesService.EnviarNotificacionWP(registro);
      // Puedes hacer algo con la respuesta si es necesario
    } catch (error) {
      console.error('Error al crear datos:', error);
      // Puedes decidir cómo manejar errores individuales
      throw error; // Propaga el error para que Promise.all lo capture
    }
  };

  const [roles] = useState([
    { label: 'Inspector', value: 'CISEInspector' },
    { label: 'Analista', value: 'CISEAnalyts' },
    { label: 'Administrador', value: 'CISEAdministrador' },
    { label: 'Coordinador', value: 'Coordinator' },
  ]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (open) {
      fetchData(); // Llama a la función para obtener usuarios cuando el modal se abre
    }
  }, [open]);
  return (
    <Modal
      title=""
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={'90%'}
      footer={false}
    >
      <PageHeader
        title="Enviar Notificación"
        head="Notificaciones"
      ></PageHeader>
      <div>
        <div style={{ padding: '10px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Card
                hoverable
                onClick={() => handleCardSelect('heart')}
                style={{
                  marginBottom: '20px',
                  border:
                    selectedCard === 'heart' ? '2px solid #1890ff' : 'none',
                }}
              >
                <WhatsAppOutlined
                  style={{
                    fontSize: '48px',
                    color: 'green',
                    textAlign: 'center',
                    lineHeight: '64px',
                  }}
                />
                <span
                  className="textAjust"
                  style={{ fontSize: '40px', marginLeft: '10PX' }}
                >
                  WhatsApp
                </span>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                hoverable
                onClick={() => handleCardSelect('star')}
                style={{
                  marginBottom: '20px',
                  border:
                    selectedCard === 'star' ? '2px solid #1890ff' : 'none',
                }}
              >
                <MailOutlined
                  style={{
                    fontSize: '48px',
                    color: 'gold',
                    textAlign: 'center',
                    lineHeight: '64px',
                  }}
                />
                <span
                  className="textAjust"
                  style={{ fontSize: '40px', marginLeft: '10PX' }}
                >
                  Correo
                </span>
              </Card>
            </Col>
          </Row>
          <div style={{ marginTop: '20px' }}>
            {selectedCard !== null && (
              <>
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={'1'}>Personalizado</Radio>
                  <Radio value={'2'}>Rol</Radio>
                </Radio.Group>

                <Form onFinish={handleFormSubmit} layout="vertical">
                  {value === '2' && (
                    <Form.Item
                      // extra={
                      //   <Button
                      //     type="text"
                      //     onClick={() => setEnvioTelefono(!envioTelefono)}
                      //   >
                      //     {envioTelefono
                      //       ? 'Enviar solo por Rol'
                      //       : ' Enviar a un número específico.'}
                      //   </Button>
                      // }
                      label="Grupo de usuarios"
                      name="roles"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor selecciona un grupo de contactos',
                        },
                      ]}
                    >
                      <Select mode="multiple">
                        {roles.map((role) => (
                          <Option key={role.value} value={role.value}>
                            {role.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {value === '1' && (
                    <Form.Item label="Número de Teléfono" name="phoneNumber">
                      <Select mode="multiple">
                        {users.map((role) => (
                          <Option key={role.value} value={role.value}>
                            {role.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {selectedCard === 'heart' && (
                    <Form.Item label="Mensaje principal" name="messageContent">
                      <Input.TextArea
                        readOnly
                        rows={14}
                        placeholder={`
                  ✅ ¿Qué? 
                  ${'El evento: ' + record.id}
                  ✅ ¿Quién? 
                  Protocolos asociados
                  ✅ ¿Cuándo?
                  ${dayjs(record.createdAt)
                    .locale('es')
                    .format(
                      'dddd, D [de] MMMM [de] YYYY [a las] HH:mm [horas]'
                    )}
                  ✅ ¿Dónde?
                  ${record.location.specific}-${record.location.name} Zona:${
                          record.location.zone
                        }
               
                 ✅Acciones realizadas desde el CISE.
                 -------`}
                        value={`
                  ✅ ¿Qué? 
                  ${'El evento: ' + record.id}
                  ✅ ¿Quién? 
                  Protocolos asociados
                  ✅ ¿Cuándo?
                  ${dayjs(record.createdAt)
                    .locale('es')
                    .format(
                      'dddd, D [de] MMMM [de] YYYY [a las] HH:mm [horas]'
                    )}
                  ✅ ¿Dónde?
                  ${record.location.specific}-${record.location.name} Zona:${
                          record.location.zone
                        }
               
                 ✅Acciones realizadas desde el CISE.
                 -------`}
                      />
                    </Form.Item>
                  )}

                  {selectedCard === 'star' && (
                    <Form.Item label="Mensaje principal" name="messageContent">
                      <Input.TextArea
                        rows={14}
                        readOnly
                        placeholder="     
                  Asunto: Actualización Importante de la Aplicación - Caso CISE-00058-2023

                  Hola [Nombre del Analista],
                  
                  Queremos informarte sobre una actualización relevante en la aplicación:
                  
                  ✅ Evento: CISE-00058-2023
                  ✅ Protocolos: Clave-alfa, Amit
                  ✅ Fecha y Hora: 01/12/2023 a las 09:27
                  ✅ Ubicación: BIT-BICENTENARIO Zona: 4
                  ✅ Acción Cise: Ajuste
                  
                  Te pedimos que realices el seguimiento correspondiente en la plataforma CISE.
                  
                  ¡Gracias por tu atención!}"
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    label="Observación Adicional"
                    name="messageContentAditional"
                  >
                    <Input.TextArea
                      rows={2}
                      placeholder="Ingresa el contenido del mensaje"
                    />
                  </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loading}
                  >
                    Enviar
                  </Button>
                </Form>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default SendWoT;
