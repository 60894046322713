import React from 'react';
import { Row, Col, Image } from 'antd';
import imgGeneral from '../../../assets/general/construction.png';

const GeneralImage = () => {
  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: '70vh', overflow: 'hidden' }}
    >
      <Col>
        <Image
          width="100%"
          height="100%"
          src={imgGeneral} // Reemplaza con la URL o ruta de tu imagen
          alt="Descripción de la imagen"
          preview={false} // Desactiva la funcionalidad de vista previa
        />
      </Col>
    </Row>
  );
};

export default GeneralImage;
