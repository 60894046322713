import React from 'react';
import getOptions from '../../../utils/getOptions';
import { Space, Tooltip, Tag, Button } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import CostModal from '../../modales/components/CostModal';
import BotonMultiproposito from '../components/BotonMultiproposito';
import days from 'dayjs';
import causas from '../../modales/eventos/components/Causas.json';

const columsActividades = (items, consultaProtocolos, session) => {
  const estadoFiltros = {
    Created: 'Creado',
    Started: 'Iniciado',
    Completed: 'Completado',
    FalseAlarm: 'Falsa alarma',
  };

  const formatDate = (date) => {
    return date ? days(date).format('DD/MMM/YYYY') : 'Sin fecha';
  };

  const formatDateHour = (date) => {
    return date ? days(date).format('hh:mm A') : 'Sin HORA';
  };

  function obtenerObjetosConEtiquetas(objeto) {
    return Object.entries(objeto).flatMap(([key, values]) =>
      values.map((value) => ({ text: value.split('_')[1], value: value }))
    );
  }

  const resultado = obtenerObjetosConEtiquetas(causas);

  return [
    {
      title: 'Protocol Id',
      dataIndex: 'protocol',
      key: 'protocol',
      width: 150,
      filterSearch: true,
      filters: getOptions(items, 'protocol'),
      onFilter: (v, record) => record.protocol.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          <div>
            <h4>{text}</h4>
          </div>
        </Space>
      ),
    },
    {
      title: 'Evento Id',
      dataIndex: 'eventId',
      key: 'eventId',
      width: 150,
      filterSearch: true,
      filters: getOptions(items, 'eventId'),
      onFilter: (v, record) => record.eventId.indexOf(v) === 0,
    },

    {
      title: 'Causas',
      dataIndex: 'causesProtocol',
      key: 'causesProtocol',
      width: 150,
      filterSearch: true,
      filters: resultado,
      onFilter: (value, record) => record.causesProtocol.includes(value),

      render: (text) => (
        <Space>
          {text.map((dato) => (
            <Tag>{dato.split('_')[1]}</Tag>
          ))}
        </Space>
      ),
    },

    {
      title: 'Fecha de Creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      // width: 200,
      width: 100,
      align: 'center',
      render: (text) => (
        <Space>
          <>{formatDate(text)}</>

          <>{formatDateHour(text)}</>
        </Space>
      ),
    },

    {
      title: 'Estado de protocolo',
      dataIndex: 'state',
      key: 'state',
      width: 100,
      filters: Object.keys(estadoFiltros).map((key) => ({
        text: estadoFiltros[key],
        value: key,
      })),
      onFilter: (v, record) => record.state.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {text === 'Created' && (
            <Tag key={text} color="yellow">
              {estadoFiltros[text]}
            </Tag>
          )}
          {text === 'FalseAlarm' && (
            <Tag key={text} color="red">
              {estadoFiltros[text]}
            </Tag>
          )}
          {text === 'Completed' && (
            <Tag key={text} color="green">
              {estadoFiltros[text]}
            </Tag>
          )}
          {text === 'Started' && (
            <Tag key={text} color="blue">
              {estadoFiltros[text]}
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: 'Acciones',

      width: 100,
      filterSearch: true,
      aling: 'center',
      render: (text, record) => (
        <Space>
          {session.roles.activities.isAllowedtoCreate && (
            <>
              {record.state === 'Created' && (
                <BotonMultiproposito
                  record={record}
                  type={'iniciar'}
                  fun={consultaProtocolos}
                />
              )}
              {record.state === 'Completado' && (
                <>
                  <CostModal />
                  <Tooltip title="Descargar Informe">
                    <Button type="text">
                      <FileDoneOutlined />
                    </Button>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </Space>
      ),
    },
  ];
};
export default columsActividades;
