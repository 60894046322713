import React from 'react';
import { Button, Modal } from 'antd';

export default function ModalConfirmacion({
  open,
  setOpen,
  title = '¿Está seguro de que desea iniciar los PARP?',
  message = '',
  funtion,
  LoadingButon,
}) {
  return (
    <Modal
      title={title}
      visible={open}
      onOk={() => funtion()}
      confirmLoading={LoadingButon}
      onCancel={() => setOpen(false)}
      footer={false}
    >
      <p>{message}</p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          // Establece el ancho máximo al 100% del contenedor principal
          overflow: 'hidden', // Oculta el contenido que se desborda
        }}
      >
        <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
        </div>

        <div
          style={{
            flex: 1,
            textAlign: 'right',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Button
            type="primary"
            style={{ margin: '10px' }}
            onClick={() => funtion()}
            loading={LoadingButon}
          >
            Aceptar
          </Button>
        </div>
      </div>
    </Modal>
  );
}
