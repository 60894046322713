import { useEffect, useRef, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { configureAmplify } from '../graphql/vmsAlarmsAmplifyConfig';
//import GetVmsAlarmListQuery from "../graphql/queries/GetVmsAlarmListQuery";
import OnCreateVmsAlarmSubscription from '../graphql/subscriptions/OnCreateVmsAlarmSubscription';
import GetVmsAlarmListQueryPaginated from '../graphql/queries/GetVmsAlarmListQueryStateDate';
import { useCallback } from 'react';

export default function useAlarms(user) {
  const [alarms, setAlarms] = useState([]);
  const [lastItem, setLastItem] = useState(0);
  const isLastPage = useRef(false);
  const nextToken = useRef(null);
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState(null);
  const onCreateAlarmsSub = useRef(null);

  const executeGraphQlOperation = useCallback(
    async (operation, params) => {
      return await client.graphql({ query: operation, variables: params });
    },
    [client]
  );

  const executeGetAlarmsPaginatedOperation = useCallback(
    async (nextToken) => {
      const queryDate = new Date();
      queryDate.setHours(queryDate.getHours() - 24);
      return await executeGraphQlOperation(GetVmsAlarmListQueryPaginated, {
        nextToken,
        limit: 10,
        state: 'Created',
        createdAt: queryDate.toISOString(),
      });
    },
    [executeGraphQlOperation]
  );

  const getAlarms = useCallback(async () => {
    if (isLastPage.current) return;
    setLoading(true);
    const response = await executeGetAlarmsPaginatedOperation(
      nextToken.current
    );
    setAlarms((prevState) => {
      let responseAlarms = response.data.listAlarmsByStateAndDate.alarms.map(
        (alarm) => {
          return {
            ...alarm,
            item: JSON.parse(alarm.item),
          };
        }
      );
      let tempState = [...prevState, ...responseAlarms];
      tempState.sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
      return tempState;
    });
    isLastPage.current = !!!response.data.listAlarmsByStateAndDate.nextToken;
    nextToken.current = response.data.listAlarmsByStateAndDate.nextToken;
    setLoading(false);
  }, [executeGetAlarmsPaginatedOperation]);

  useEffect(() => {
    if (user) {
      configureAmplify();
      if (!!client) return;
      setClient(generateClient());
    } else {
      setAlarms([]);
      if (!client) return;
      setClient(null);
      if (!onCreateAlarmsSub.current) return;
      onCreateAlarmsSub.current.unsubscribe();
      onCreateAlarmsSub.current = null;
    }
  }, [user, client]);

  useEffect(() => {
    if (!client) return;
    onCreateAlarmsSub.current = client
      .graphql({ query: OnCreateVmsAlarmSubscription })
      .subscribe({
        next: ({ data }) =>
          setAlarms((prevState) => {
            const newData = {
              ...data.onCreateAlarm,
              item: JSON.parse(data.onCreateAlarm.item),
            };
            return [newData, ...prevState];
          }),
        error: (error) => console.warn(error),
      });
  }, [client]);

  useEffect(() => {
    // console.log(alarms.length)
    if (lastItem < alarms.length - 1 || !client) return;
    getAlarms().catch((e) => {
      console.warn(e);
    });
  }, [lastItem, getAlarms, alarms.length, client]);

  return {
    alarmsSession: {
      alarms,
      loading,
      executeGraphQlOperation,
      setLastItem,
    },
  };
}
