import React, {useEffect,useState}from 'react';
import { Row, Col, Statistic, Card } from 'antd';
import { LikeOutlined, SyncOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const Contadores = () => {
    // Estados para los contadores
  const [totalEventos, setTotalEventos] = useState(0);
  const [eventosAbiertos, setEventosAbiertos] = useState(0);
  const [eventosEjecutando, setEventosEjecutando] = useState(0);
  const [falsaAlarma, setFalsaAlarma] = useState(0);
  const [eventosCerrados, setEventosCerrados] = useState(0);

  // Función para obtener datos de la API
  const fetchData = async () => {
    try {
      const response = await fetch('https://lcqea68lvi.execute-api.us-east-1.amazonaws.com/prod/events', {
        method: 'GET',
        headers: {
          'x-api-key': 'A0mDYHhECYauV96AWjuuMHknT25VRnJ84KUjaO6c', // Tu API Key
          'Content-Type': 'application/json',
        },
      });
      
      if (response.ok) {
        const data = await response.json();
   
        setTotalEventos(data.Count);
        const abiertos = data.Items.filter(item => item.state === 'Open').length || 0;
        const ejecutando = data.Items.filter(item => item.state === 'InProgress').length || 0;
        const falsaAlarma = data.Items.filter(item => item.state === 'FalseAlarm').length || 0;
        const cerrados = data.Items.filter(item => item.state === 'Closed').length || 0;

        setEventosAbiertos(abiertos);    // Número de eventos con state "InProgress"
        setEventosEjecutando(ejecutando); // Número de eventos con state "Running"
        setFalsaAlarma(falsaAlarma);     // Número de eventos con state "Failed"
        setEventosCerrados(cerrados);    // Número de eventos con state "Completed"
     
      } else {
        console.error('Error en la respuesta de la API', response.status);
      }
    } catch (error) {
      console.error('Error al obtener datos de la API', error);
    }
  };


  // useEffect para ejecutar la consulta a la API cada 5 minutos (300,000 ms)
  useEffect(() => {
    fetchData(); // Llamada inicial

    const interval = setInterval(() => {
      fetchData(); // Llamada periódica cada 5 minutos
    }, 300000); // 300000ms = 5 minutos

    // Limpieza del intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, []);



  return (
    <div style={{ padding: '20px' }}>
      {/* Fila para la cantidad total de eventos */}
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={24}>
          <Card>
            <Statistic
              title="Cantidad total de eventos"
              value={totalEventos}
              valueStyle={{ fontSize: '30px' }}
              prefix={<LikeOutlined />}
            />
          </Card>
        </Col>
      </Row>

      {/* Fila para los diferentes tipos de eventos, todas las cartas tienen la misma altura */}
      <Row gutter={16}>
        <Col span={6}>
          <Card style={{ minHeight: '100px' }}>  {/* Altura fija */}
            <Statistic
              title="Eventos Abiertos"
              value={eventosAbiertos}
              valueStyle={{ color: '#faad14', fontSize: '24px' }} // Color amarillo
              prefix={<SyncOutlined />}
            />
          </Card>
        </Col>

        <Col span={6}>
          <Card style={{ minHeight: '100px' }}>  {/* Altura fija */}
            <Statistic
              title="Eventos Ejecutando"
              value={eventosEjecutando}
              valueStyle={{ color: '#1890ff', fontSize: '24px' }} // Color azul
              prefix={<SyncOutlined spin />}
            />
          </Card>
        </Col>

        <Col span={6}>
          <Card style={{ minHeight: '130px' }}>  {/* Altura fija */}
            <Statistic
              title="Falsa    Alarma"
              value={falsaAlarma}
              valueStyle={{ color: '#ff4d4f', fontSize: '24px' }} // Color rojo
              prefix={<CloseCircleOutlined />}
            />
          </Card>
        </Col>

        <Col span={6}>
          <Card style={{ minHeight: '100px' }}>  {/* Altura fija */}
            <Statistic
              title="Eventos Cerrados"
              value={eventosCerrados}
              valueStyle={{ color: '#52c41a', fontSize: '24px' }} // Color verde
              prefix={<CheckCircleOutlined />}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Contadores;
