import React from 'react';
import TablaEventos from './components/TablaEventos';
import { Table } from 'antd';
import CardMovilEvents from './components/CardMovilEvents';

export default function TablaEventosPrincipal({
  eventTable,
  fetchData,
  loadingTable,
}) {
  return (
    <>
      <div className="componentWeb">
        <CardMovilEvents dataSource={eventTable} fetchData={fetchData} />
      </div>
      <Table
        className="textAjust"
        rowKey="id"
        style={{ marginTop: '10px' }}
        dataSource={eventTable}
        columns={TablaEventos(eventTable, fetchData)}
        scroll={{ x: 'max-content' }}
        locale={{
          filterTitle: 'Filtrar',
          filterConfirm: 'Filtrar',
          filterReset: 'Limpiar',
          emptyText: 'Sin datos',
        }}
        // Aplicar los filtros almacenados
      />
    </>
  );
}
